import React, { useRef, useState } from "react";
import { Upload, Mail, Lock, User, Loader2 } from "lucide-react";
import { useProfileForm } from "./hooks/useProfileForm";
import Message from "./Message";

import { updateProfile } from "firebase/auth";
import { uploadProfilePicture } from "../../utils/mediaUpload";

const ProfileSection = ({ title, children }) => (
  <div className="space-y-3">
    <h3 className="text-sm font-medium text-gray-700">{title}</h3>
    <div className="bg-white border border-gray-200 rounded-xl p-4">
      {children}
    </div>
  </div>
);

const InputField = ({ icon: Icon, label, type = "text", ...props }) => (
  <div>
    <label className="block text-xs font-medium text-gray-700 mb-1.5">
      {label}
    </label>
    <div className="relative">
      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
        <Icon size={16} />
      </div>
      <input
        type={type}
        className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-black focus:border-black transition-colors"
        {...props}
      />
    </div>
  </div>
);

const ProfileTab = ({ user }) => {
  const {
    formData,
    loading,
    message,
    setMessage,
    updateFormField,
    handleProfileUpdate,
  } = useProfileForm(user);

  const [imageLoading, setImageLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setImageLoading(true);
      const { url } = await uploadProfilePicture(file);
      await updateProfile(user, { photoURL: url });

      setMessage({
        type: "success",
        text: "Profile picture updated successfully!",
      });
    } catch (error) {
      setMessage({ type: "error", text: "Failed to update profile picture" });
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <div className="space-y-6 max-w-2xl">
      {message.text && <Message type={message.type} text={message.text} />}

      <ProfileSection title="Profile Picture">
        <div className="flex items-center space-x-6">
          <div className="relative group">
            <div
              className={`w-20 h-20 rounded-xl overflow-hidden ring-2 ring-gray-100 ${
                imageLoading ? "opacity-50" : ""
              }`}
            >
              <img
                src={user?.photoURL || "/images/nouser-square.svg"}
                alt="Profile"
                className={`w-full h-full ${
                  user?.photoURL ? "object-cover" : "object-fit"
                }`}
              />
              {imageLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                  <Loader2 className="w-5 h-5 text-white animate-spin" />
                </div>
              )}
            </div>
            <button
              onClick={() => fileInputRef.current?.click()}
              className="absolute bottom-1 right-1 p-1.5 bg-black text-white rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-200 hover:bg-gray-900"
            >
              <Upload size={14} />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              accept="image/*"
              className="hidden"
            />
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-900">
              Profile Picture
            </h4>
            <p className="text-xs text-gray-500 mt-0.5">
              Recommended size: 400x400px
            </p>
          </div>
        </div>
      </ProfileSection>

      <ProfileSection title="Account Information">
        <div className="grid grid-cols-2 gap-4">
          <InputField
            icon={User}
            label="Display Name"
            value={formData.displayName}
            onChange={(e) => updateFormField("displayName", e.target.value)}
            placeholder="Enter your name"
          />
          <InputField
            icon={Mail}
            label="Email Address"
            type="email"
            value={formData.email}
            onChange={(e) => updateFormField("email", e.target.value)}
            placeholder="Enter your email"
          />
        </div>
      </ProfileSection>

      <ProfileSection title="Security">
        <div className="space-y-4">
          <InputField
            icon={Lock}
            label="Current Password"
            type="password"
            value={formData.currentPassword}
            onChange={(e) => updateFormField("currentPassword", e.target.value)}
            placeholder="Enter current password"
          />
          <div className="grid grid-cols-2 gap-4">
            <InputField
              icon={Lock}
              label="New Password"
              type="password"
              value={formData.newPassword}
              onChange={(e) => updateFormField("newPassword", e.target.value)}
              placeholder="Enter new password"
            />
            <InputField
              icon={Lock}
              label="Confirm Password"
              type="password"
              value={formData.confirmPassword}
              onChange={(e) =>
                updateFormField("confirmPassword", e.target.value)
              }
              placeholder="Confirm new password"
            />
          </div>
        </div>
      </ProfileSection>

      <div className="flex justify-end">
        <button
          onClick={handleProfileUpdate}
          disabled={loading}
          className="px-4 py-2 bg-black text-white text-sm rounded-lg hover:bg-gray-900 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <div className="flex items-center">
              <Loader2 className="w-4 h-4 animate-spin mr-2" />
              Saving...
            </div>
          ) : (
            "Save Changes"
          )}
        </button>
      </div>
    </div>
  );
};

export default ProfileTab;
