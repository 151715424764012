import React, { useState } from "react";
import { importAiQuestions } from "../../serverComm";
import Button from "../Button/Button";

const ImportQuestionForm = ({ appendAiGeneratedQuestions }) => {
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [isImporting, setIsImporting] = useState(false);

  const importQuestionWithAi = async (description) => {
    const result = await importAiQuestions({ description });
    if (appendAiGeneratedQuestions) {
      appendAiGeneratedQuestions(result.questions);
    }
    setIsImporting(false);
  };

  const handleImport = async (e) => {
    e.preventDefault();

    // Validate description length
    if (!description || description.length < 10) {
      setError("Please inset atleast 1 question.");
      return;
    }

    // Clear any previous errors
    setError("");
    setIsImporting(true);
    importQuestionWithAi(description);
  };

  return (
    <div className="space-y-4 p-6 pt-0">
      <div
        id="main-form"
        className="p-8 bg-white rounded-2xl max-h-[80vh] overflow-y-auto"
      >
        {isImporting ? (
          <div className="flex flex-col items-center justify-center space-y-3 h-[61vh]">
            <span className="text-mg text-gray-700 animate-pulse">
              Importing...
            </span>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="font-medium">Form questions</h2>
              {/* <button className="px-4 py-2 text-sm border border-gray-200 rounded-lg hover:bg-gray-50">
                Import Google Form
              </button> */}
            </div>

            <textarea
              className="w-full p-4 border bg-[#F9FAFB] border-gray-200 rounded-2xl min-h-[300px] resize-none"
              placeholder="Insert your questions by typing or pasting them, then press Enter after each one."
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setError("");
              }}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </>
        )}
      </div>
      {!isImporting && (
        <div className="flex justify-end">
          <Button onClick={handleImport}>Import</Button>
        </div>
      )}
    </div>
  );
};

export default ImportQuestionForm;
