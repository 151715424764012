import React from 'react';

const IntegrationDecorativeSide = ({
    bgColor = 'bg-[#E8F3EF]',
    bottomIcon,
    showGoogleIcon = true
}) => {
    return (
        <div className={`w-1/2 relative ${bgColor} flex items-center justify-center`}>
            {/* Background dotted line */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-40">
                <div className="h-full border-r-2 border-dashed border-app-white"></div>
            </div>

            {/* Decorative elements */}
            <div className="relative flex flex-col items-center gap-y-8">
                {/* Lightning icon */}
                <div className="w-36 h-36">
                    <img
                        src="/images/pollBoltIconYellow.svg"
                        alt="Poll-Bolt"
                        className="w-full h-full object-contain"
                    />
                </div>

                {/* Google icon - conditionally rendered */}

                <div className='w-14 h-14'>
                    {showGoogleIcon && (
                        <img
                            src="/images/google.svg"
                            alt="Google"
                            className="w-full h-full object-contain"
                        />
                    )}
                </div>


                {/* Integration specific icon */}
                <div className="w-36 h-36 bg-white rounded-xl p-6">
                    <img
                        src={bottomIcon}
                        alt="Integration Icon"
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>
        </div>
    );
};

export default IntegrationDecorativeSide; 