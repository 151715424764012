import { AlignLeft, CheckSquare, Hash, ListChecks, Star, TextCursor } from "lucide-react";

const inputTypes = {
    "short text":{
        label: "Short Text",
        icon: TextCursor,
        description: "Let respondents enter a brief answer in an open text field.",
        bgColor: "bg-[#FFD2D5]",
        iconColor: "text-app-black",
      },
      "multiple choice":{
        label: "Multiple Choice",
        icon: ListChecks,
        description: "Allow respondents to select one answer from a predefined list.",
        bgColor: "bg-[#DCE1FF]",
        iconColor: "text-app-black",
      },
      "long text":{
        label: "Long Text",
        icon: AlignLeft,
        description: "Gather more detailed responses to open-ended questions.",
        bgColor: "bg-[#FFE8CE]",
        iconColor: "text-app-black",
      },
      "checkbox list":{
        label: "Checkbox List",
        icon: CheckSquare,
        description: "Allow respondents to select multiple answers from a range of options.",
        bgColor: "bg-[#D3FBF1]",
        iconColor: "text-app-black",
      },
      "numeric":{
        label: "Numeric",
        icon: Hash,
        description: "Collect precise numerical data from respondents.",
        bgColor: "bg-[#FFE8CE]",
        iconColor: "text-app-black",
      },
      "star rating":{
        label: "Star Rating",
        icon: Star,
        description: "Provide a quick way for respondents to give feedback on a star scale.",
        bgColor: "bg-[#FFFFCE]",
        iconColor: "text-app-black",
      },
};

export default inputTypes;