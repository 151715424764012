// ui/src/SurveyDesigner.js
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import QuestionRenderer from "./QuestionRenderer";
import {
  Zap,
  Check,
  Share2,
  Copy,
  X,
  Smartphone,
  Monitor,
  ChevronRight,
  Plus,
  ListChecks,
  TextCursor,
  AlignLeft,
  Hash,
  Star,
  MoreVertical,
  Trash2,
  Eye,
  Image,
  AlignCenter,
  AlignRight,
  CheckSquare,
  Layout,
  PanelLeft,
  PanelRight,
  Upload,
  ImagePlus,
  Library,
  FileStack,
  Calculator,
  Sparkles,
} from "lucide-react";
import { saveSurvey, getSurvey, getMediaFiles } from "./serverComm";
import debounce from "lodash/debounce";
import { ChromePicker } from "react-color";
import {
  uploadLogo,
  getPreviousLogos,
  getStockPhotos,
  deleteMediaFile,
} from "./utils/mediaUpload";
import SurveyDesignerSkeleton from "./components/SurveyDesignerSkeleton";
import { createPortal } from "react-dom";
import FormSelectorDialog from "./components/NewSurveyComponents/FormSelectorDialog";
import { ModalContainer } from "./components/ModalContainer";
import { Popover } from "@headlessui/react";
import OnboardingCompleted from "./components/OnboardingSurveyComponents/OnboardingCompleted";
import { setActiveSurvey, setHasChanges } from "../../store/appSlice";
import { useDispatch } from "react-redux";
import inputTypes from "./assets/inputTypes";
import Button from "./components/Button/Button";
import ShareSurveyModal from "./components/SurveyDesignerComponents/ShareSurveyModal";
import {
  defaultAiGeneratedSurveyBackground,
  defaultAiGeneratedSurveyBackgroundColor,
} from "./assets/defaults";
import ConfirmationModal from "./components/ui/ConfirmationModal";

// Add this new component for variable selection popup
const VariableSelector = ({ position, onSelect, onClose }) => {
  const variables = [
    {
      id: "score",
      label: "Score",
      description: "Final calculated score",
    },
  ];

  return (
    <Popover className="relative">
      <Popover.Panel
        static
        anchor="bottom-start"
        className="w-64 bg-white rounded-lg shadow-lg border border-gray-200 mt-1"
      >
        <div className="p-2">
          <div className="text-sm font-medium text-gray-700 px-2 py-1.5 border-b">
            Insert variable
          </div>
          <div className="max-h-[300px] overflow-y-auto">
            {variables.map((variable) => (
              <button
                key={variable.id}
                onClick={() => onSelect(variable)}
                className="w-full px-3 py-2 text-left hover:bg-gray-50 rounded-md group flex items-start gap-2"
              >
                <div className="flex-shrink-0 w-8 h-8 bg-blue-50 rounded-md flex items-center justify-center">
                  <span className="text-blue-600 font-medium">@</span>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {variable.label}
                  </div>
                  <div className="text-xs text-gray-500">
                    {variable.description}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const SurveyDesigner = () => {
  const defaultStyles = {
    fontFamily: "Inter",
    submitButtonColor: "#4F46E5",
    backgroundColor: "#FFFFFF",
    questionTextColor: "#000000",
    customSubmitText: "",
    useCustomSubmitText: false,
    backgroundAlignment: "right",
    backgroundImage: "",
    logo: {
      url: "",
      size: "md",
      alignment: "left",
    },
  };

  const { surveyId } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);
  const optionFromQuery = queryParams.get("option");
  const onboardingFromQuery = queryParams.get("onboarding");
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [saveStatus, setSaveStatus] = useState("idle"); // 'idle', 'saving', 'saved'
  const [surveyTitle, setSurveyTitle] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("idle"); // 'idle' | 'copied'
  const [previewMode, setPreviewMode] = useState("desktop"); // 'desktop' | 'mobile'
  const [surveyStyles, setSurveyStyles] = useState(defaultStyles);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState(0);
  const [selectedQuestionForEdit, setSelectedQuestionForEdit] = useState(null);
  const [showLogicConfig, setShowLogicConfig] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [isFontDropdownOpen, setIsFontDropdownOpen] = useState(false);
  const [activeEditorTab, setActiveEditorTab] = useState("edit"); // 'edit' | 'style'
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logoSize, setLogoSize] = useState("md");
  const [logoAlignment, setLogoAlignment] = useState("left");
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previousLogos, setPreviousLogos] = useState([]);
  const [isLoadingLogos, setIsLoadingLogos] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showBackgroundImageModal, setShowBackgroundImageModal] =
    useState(false);
  const [showBgChangerOnMainScreen, setShowBgChangerOnMainScreen] =
    useState(false);
  const [activeBackgroundTab, setActiveBackgroundTab] = useState("stock"); // 'stock' | 'upload' | 'library'
  const [stockPhotos, setStockPhotos] = useState([]);
  const [showFormSelector, setShowFormSelector] = useState(false);
  const [showFormSelectorAi, setShowFormSelectorAi] = useState(false);
  const [endingScreens, setEndingScreens] = useState([]);
  const [selectedEndingScreen, setSelectedEndingScreen] = useState(null);
  const [welcomeScreens, setWelcomeScreens] = useState([]);
  const [selectedWelcomeScreen, setSelectedWelcomeScreen] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showWelcomeDeleteConfirm, setShowWelcomeDeleteConfirm] =
    useState(false);
  // Add state for tracking changes
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  // Add these new states
  const [showVariableSelector, setShowVariableSelector] = useState(false);
  const [variableSelectorPosition, setVariableSelectorPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const questionTypeIcons = {
    "multiple choice": ListChecks,
    "checkbox list": CheckSquare,
    "short text": TextCursor,
    "long text": AlignLeft,
    numeric: Hash,
    "star rating": Star,
  };

  const questionTypes = [
    {
      value: "multiple choice",
      label: "Multiple Choice",
      icon: ListChecks,
      description: "Let respondents choose from predefined options",
    },
    {
      value: "checkbox list",
      label: "Checkbox List",
      icon: CheckSquare,
      description: "Allow respondents to select multiple options",
    },
    {
      value: "short text",
      label: "Short Text",
      icon: TextCursor,
      description: "Collect brief text responses",
    },
    {
      value: "long text",
      label: "Long Text",
      icon: AlignLeft,
      description: "Gather detailed text responses",
    },
    {
      value: "numeric",
      label: "Numeric",
      icon: Hash,
      description: "Collect numerical data",
    },
    {
      value: "star rating",
      label: "Star Rating",
      icon: Star,
      description: "Get feedback on a rating scale",
    },
  ];

  const fonts = [
    { name: "Inter", value: "Inter" },
    { name: "Roboto", value: "Roboto" },
    { name: "Open Sans", value: "Open Sans" },
    { name: "Lato", value: "Lato" },
    { name: "Poppins", value: "Poppins" },
    { name: "Montserrat", value: "Montserrat" },
    { name: "Playfair Display", value: "Playfair Display" },
    { name: "DM Sans", value: "DM Sans" },
    { name: "Source Sans Pro", value: "Source Sans Pro" },
    { name: "Manrope", value: "Manrope" },
    { name: "Work Sans", value: "Work Sans" },
    { name: "Space Grotesk", value: "Space Grotesk" },
    { name: "Plus Jakarta Sans", value: "Plus Jakarta Sans" },
    { name: "Outfit", value: "Outfit" },
    { name: "Sora", value: "Sora" },
  ];

  // Define variables array
  const variables = [
    {
      id: "score",
      label: "Score",
      description: "Final calculated score",
    },
  ];

  const loadSurveyData = async () => {
    const surveyData = await getSurvey(surveyId);
    if (
      surveyData?.styles?.backgroundImage === defaultAiGeneratedSurveyBackground
    ) {
      setShowBgChangerOnMainScreen(true);
    }
    return surveyData;
  };

  useEffect(() => {
    async function loadExistingSurvey() {
      if (!surveyId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const surveyData = await loadSurveyData();
        dispatch(setActiveSurvey(surveyData));
        setSurveyTitle(surveyData.title);
        setQuestions(surveyData.questions);
        setEndingScreens(surveyData.endingScreens || []); // Add ending screens
        setWelcomeScreens(surveyData.welcomeScreens || []); // Add welcome screens

        if (
          optionFromQuery &&
          ["ai", "select", "import"].includes(optionFromQuery)
        ) {
          setShowFormSelector(true);
        }

        // Ensure all style properties are properly initialized
        const initialStyles = {
          ...defaultStyles,
          ...surveyData.styles,
          backgroundAlignment:
            surveyData.styles?.backgroundAlignment || "right",
          backgroundImage: surveyData.styles?.backgroundImage || "",
          logo: {
            url: surveyData.styles?.logo?.url || "",
            size: surveyData.styles?.logo?.size || "md",
            alignment: surveyData.styles?.logo?.alignment || "left",
          },
        };

        setSurveyStyles(initialStyles);

        if (surveyData.questions && surveyData.questions.length > 0) {
          setSelectedQuestionForEdit(surveyData.questions[0]);
          setCurrentPreviewQuestion(0);
          setActiveEditorTab("edit");
        }

        if (onboardingFromQuery && onboardingFromQuery === "completed") {
          setIsOnboardingCompleted(true);
          setShowBgChangerOnMainScreen(true);
        }
      } catch (error) {
        console.error("Error loading survey:", error);
      } finally {
        setIsLoading(false);
      }
    }

    loadExistingSurvey();
  }, [surveyId, optionFromQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isTypeDropdownOpen &&
        !event.target.closest(".question-type-dropdown")
      ) {
        setIsTypeDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isTypeDropdownOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showColorPicker && !event.target.closest(".button-color-picker")) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showColorPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showBgColorPicker && !event.target.closest(".button-color-picker")) {
        setShowBgColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showBgColorPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest(".group")) {
        setShowMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isFontDropdownOpen && !event.target.closest(".font-dropdown")) {
        setIsFontDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isFontDropdownOpen]);

  useEffect(() => {
    const handleOptionPaste = (e) => {
      // Check if the paste target is a radio input option field
      if (e.target.classList.contains("radio-option-input")) {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text");

        // Split the pasted text into array by newlines or commas
        const pastedOptions = pastedText
          .split(/[\n,]/) // Split by newline or comma
          .map((opt) => opt.trim())
          .filter((opt) => opt.length > 0);

        // For debugging

        if (pastedOptions.length > 1 && selectedQuestionForEdit) {
          // Update the question with new options
          const updatedQuestions = questions.map((q) => {
            if (q.id === selectedQuestionForEdit.id) {
              return {
                ...q,
                options: pastedOptions,
              };
            }
            return q;
          });

          // Update states
          setQuestions(updatedQuestions);
          const updatedQuestion = updatedQuestions.find(
            (q) => q.id === selectedQuestionForEdit.id
          );
          setSelectedQuestionForEdit(updatedQuestion);

          // Save changes
          handleSaveSurvey({
            title: surveyTitle,
            questions: updatedQuestions,
            styles: surveyStyles,
          });
        }
      }
    };

    // Add paste event listener to document
    document.addEventListener("paste", handleOptionPaste);

    // Cleanup
    return () => {
      document.removeEventListener("paste", handleOptionPaste);
    };
  }, [selectedQuestionForEdit, questions]); // Add any other dependencies you need

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showTextColorPicker && !event.target.closest(".text-color-picker")) {
        setShowTextColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showTextColorPicker]);

  useEffect(() => {
    const loadStockPhotos = async () => {
      const photos = await getStockPhotos();
      setStockPhotos(photos);
    };

    if (showBackgroundImageModal) {
      loadStockPhotos();
    }
  }, [showBackgroundImageModal]);

  const deleteQuestion = (id) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.filter((q) => q.id !== id);

      // If the deleted question was selected, select another question
      if (selectedQuestionForEdit?.id === id) {
        // If there are remaining questions, select the previous one or the first one
        if (updatedQuestions.length > 0) {
          const currentIndex = prevQuestions.findIndex((q) => q.id === id);
          const newIndex = Math.max(0, currentIndex - 1);
          setSelectedQuestionForEdit(updatedQuestions[newIndex]);
          setCurrentPreviewQuestion(newIndex);
        } else {
          // If no questions remain, clear the selection
          setSelectedQuestionForEdit(null);
          setCurrentPreviewQuestion(0);
        }
      }

      // Save the survey after deleting the question
      handleSaveSurvey({
        title: surveyTitle,
        questions: updatedQuestions,
        styles: surveyStyles,
      });

      return updatedQuestions;
    });
  };

  const handleSaveSurvey = async (updates = {}) => {
    setSaveStatus("saving");
    try {
      const surveyData = {
        id: surveyId,
        surveyId: surveyId,
        title: updates.title || surveyTitle,
        questions: updates.questions || questions,
        endingScreens: updates.endingScreens || endingScreens,
        welcomeScreens: updates.welcomeScreens || welcomeScreens,
        styles: {
          ...surveyStyles,
          ...updates.styles,
          fontFamily: updates.styles?.fontFamily || surveyStyles.fontFamily,
          submitButtonColor:
            updates.styles?.submitButtonColor || surveyStyles.submitButtonColor,
          backgroundColor:
            updates.styles?.backgroundColor || surveyStyles.backgroundColor,
          backgroundImage: updates.styles?.backgroundImage,
          backgroundAlignment:
            updates.styles?.backgroundAlignment ||
            surveyStyles.backgroundAlignment,
          logo: {
            ...surveyStyles.logo,
            ...(updates.styles?.logo || {}),
          },
        },
      };

      await saveSurvey(surveyData);
      dispatch(setActiveSurvey(surveyData));
      dispatch(setHasChanges(true));

      // Update local state if save was successful
      if (updates.title) setSurveyTitle(updates.title);
      if (updates.questions) setQuestions(updates.questions);
      if (updates.styles) setSurveyStyles(surveyData.styles);
      if (updates.endingScreens) setEndingScreens(updates.endingScreens);
      if (updates.welcomeScreens) setWelcomeScreens(updates.welcomeScreens);

      setSaveStatus("saved");
      setTimeout(() => setSaveStatus("idle"), 2000);
    } catch (error) {
      console.error("Error saving survey:", error);
      setSaveStatus("error");
    }
  };

  const handleViewAnalytics = () => {
    navigate(`/survey/${surveyId}/analytics`);
  };

  const getSaveButtonContent = () => {
    switch (saveStatus) {
      case "saving":
        return (
          <>
            Saving...{" "}
            <Zap
              size={20}
              className="ml-2 animate-spin text-black fill-black"
              strokeWidth={2}
            />
          </>
        );
      case "saved":
        return (
          <>
            Saved! <Check size={20} className="ml-2" strokeWidth={2} />
          </>
        );
      default:
        return (
          <>
            Publish{" "}
            <Zap
              size={20}
              className="ml-2 text-black fill-black"
              strokeWidth={2}
            />
          </>
        );
    }
  };

  const handleQuestionUpdate = (updatedQuestion) => {
    const updatedQuestions = questions.map((q) =>
      q.id === updatedQuestion.id ? updatedQuestion : q
    );
    setQuestions(updatedQuestions);
    setSelectedQuestionForEdit(updatedQuestion);

    // Save the updated questions
    handleSaveSurvey({
      title: surveyTitle,
      questions: updatedQuestions,
      styles: surveyStyles,
    });
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleCopyLink = async () => {
    const surveyUrl = `${window.location.origin}/s/${surveyId}`;
    try {
      await navigator.clipboard.writeText(surveyUrl);
      setCopyStatus("copied");
      setTimeout(() => setCopyStatus("idle"), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleStyleChange = async (type, value) => {
    try {
      let updatedStyles;

      if (type === "logo") {
        // For logo updates, preserve the URL while updating size or alignment
        updatedStyles = {
          ...surveyStyles,
          logo: {
            ...surveyStyles.logo, // Keep existing logo properties
            ...value, // Update with new properties
          },
        };
      } else {
        // Handle other style changes
        updatedStyles = {
          ...surveyStyles,
          [type]: value,
        };
      }

      // Update local state immediately
      setSurveyStyles(updatedStyles);

      // Save to server
      const saveData = {
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles,
      };

      await handleSaveSurvey(saveData);

      // Optional: Fetch the latest data to confirm changes
      const updatedSurvey = await loadSurveyData();
      setSurveyStyles(updatedSurvey.styles);
    } catch (error) {
      console.error("Error updating styles:", error);
      // Revert local state if save failed
      const currentSurvey = await loadSurveyData();
      setSurveyStyles(currentSurvey.styles);
      alert("Failed to update style. Please try again.");
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    const newQuestions = [...questions];
    const draggedQuestion = newQuestions[draggedIndex];

    // Remove dragged item
    newQuestions.splice(draggedIndex, 1);
    // Add it at new position
    newQuestions.splice(index, 0, draggedQuestion);

    // Update states
    setQuestions(newQuestions);
    setDraggedIndex(null);
    setDragOverIndex(null);
    setCurrentPreviewQuestion(index);
    setSelectedQuestionForEdit(draggedQuestion);

    // Save the new order
    handleSaveSurvey({
      title: surveyTitle,
      questions: newQuestions,
      styles: surveyStyles,
    });
  };

  // Handler for selecting a question to edit
  const handleQuestionSelect = (question, index) => {
    setSelectedQuestionForEdit(question);
    setCurrentPreviewQuestion(index);
    setSelectedEndingScreen(null); // Clear selected ending screen when selecting a question
  };

  const handleAddQuestion = (type) => {
    handleFornSelectorClose();
    const newQuestion = {
      id: uuidv4(),
      text: "New Question",
      type: type,
      options: type === "checkbox list" ? ["Option 1", "Option 2"] : [], // Ensure options array exists
      answer: type === "checkbox list" ? [] : "",
    };

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      setCurrentPreviewQuestion(updatedQuestions.length - 1);

      handleSaveSurvey({
        title: surveyTitle,
        questions: updatedQuestions,
        styles: surveyStyles,
      });

      return updatedQuestions;
    });

    setSelectedQuestionForEdit(newQuestion);
    setShowQuestionModal(false);
  };

  const handleDuplicateQuestion = (questionToDuplicate) => {
    const duplicatedQuestion = {
      ...questionToDuplicate,
      id: uuidv4(), // Generate new ID for duplicate
    };

    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      // Find index of original question and insert duplicate after it
      const index = newQuestions.findIndex(
        (q) => q.id === questionToDuplicate.id
      );
      newQuestions.splice(index + 1, 0, duplicatedQuestion);

      // Save the survey with the duplicated question
      handleSaveSurvey({
        title: surveyTitle,
        questions: newQuestions,
        styles: surveyStyles,
      });

      return newQuestions;
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const downloadURL = await uploadLogo(file, surveyId);

      // Create new styles object with all existing styles plus the logo
      const updatedStyles = {
        ...surveyStyles,
        logo: {
          url: downloadURL,
          size: "md",
          alignment: "left",
        },
      };

      // Update local state
      setSurveyStyles(updatedStyles);

      // Save to server
      const saveData = {
        id: surveyId,
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles,
      };

      await handleSaveSurvey(saveData);
      setShowMediaModal(false);
    } catch (error) {
      console.error("Error uploading logo:", error);
      alert("Failed to upload logo. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const loadPreviousLogos = async () => {
    setIsLoadingLogos(true);
    try {
      const logos = await getPreviousLogos(surveyId);
      setPreviousLogos(logos);
    } catch (error) {
      console.error("Error loading previous logos:", error);
    } finally {
      setIsLoadingLogos(false);
    }
  };

  useEffect(() => {
    if (showMediaModal) {
      loadPreviousLogos();
    }
  }, [showMediaModal]);

  const handleLogoSelect = async (logo) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        logo: {
          url: logo.url,
          size: "md",
          alignment: "left",
        },
      };

      setSurveyStyles(updatedStyles);

      const saveData = {
        id: surveyId,
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles,
      };

      await handleSaveSurvey(saveData);
      setShowMediaModal(false);
    } catch (error) {
      console.error("Error selecting logo:", error);
      alert("Failed to select logo. Please try again.");
    }
  };

  // Add a debounced save function for logo updates
  const debouncedLogoUpdate = useCallback(
    debounce(async (updatedStyles) => {
      try {
        await handleSaveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles,
        });
      } catch (error) {
        console.error("Error saving logo updates:", error);
      }
    }, 500),
    [surveyId, surveyTitle, questions]
  );

  // Update the logo handling functions
  const handleLogoSizeChange = (newSize) => {
    const updatedStyles = {
      ...surveyStyles,
      logo: {
        ...surveyStyles.logo,
        size: newSize,
      },
    };

    // Update local state immediately
    setSurveyStyles(updatedStyles);

    // Debounce the server update
    debouncedLogoUpdate(updatedStyles);
  };

  const handleLogoAlignmentChange = (newAlignment) => {
    const updatedStyles = {
      ...surveyStyles,
      logo: {
        ...surveyStyles.logo,
        alignment: newAlignment,
      },
    };

    // Update local state immediately
    setSurveyStyles(updatedStyles);

    // Debounce the server update
    debouncedLogoUpdate(updatedStyles);
  };

  const handleCloseBgChanger = () => {
    setShowBackgroundImageModal(false);
  };

  // Update the handleBackgroundImageSelect function
  const handleBackgroundImageSelect = async (image) => {
    try {
      const updatedStyles = {
        ...surveyStyles,
        backgroundImage: image.url,
        backgroundAlignment: "right", // Set default alignment to 'right'
      };

      // Update local state
      setSurveyStyles(updatedStyles);

      // Save to server
      await handleSaveSurvey({
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles,
      });
      setShowBgChangerOnMainScreen(false);
      handleCloseBgChanger();
    } catch (error) {
      console.error("Error selecting background image:", error);
      alert("Failed to select background image. Please try again.");
    }
  };

  // Update the handleBackgroundImageUpload function
  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      // Upload the file and get the result
      const uploadResult = await uploadLogo(file, surveyId);

      // Use the url from the upload result
      const updatedStyles = {
        ...surveyStyles,
        backgroundImage: uploadResult.url,
        backgroundAlignment: "right", // Set default alignment to 'right'
      };

      // Update local state first
      setSurveyStyles(updatedStyles);

      try {
        // Save to server
        await handleSaveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles,
        });

        // Reload the images
        await loadMediaFiles();
        setShowBgChangerOnMainScreen(false);
        handleCloseBgChanger();
      } catch (saveError) {
        console.error("Error saving survey:", saveError);
        // If save fails, revert the styles
        setSurveyStyles((prevStyles) => ({ ...prevStyles }));
        alert("Failed to update survey with new background. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading background image:", error);
      alert("Failed to upload image. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  // Add this function to load media files
  const loadMediaFiles = async () => {
    setIsLoadingLogos(true);
    try {
      const files = await getMediaFiles();
      setPreviousLogos(files);
    } catch (error) {
      console.error("Error loading media files:", error);
    } finally {
      setIsLoadingLogos(false);
    }
  };

  // Update the useEffect for background image modal
  useEffect(() => {
    if (showBackgroundImageModal || showMediaModal) {
      loadMediaFiles();
    }
  }, [showBackgroundImageModal, showMediaModal]);

  // Update the handleBackgroundAlignmentChange function
  const handleBackgroundAlignmentChange = async (alignment) => {
    try {
      // Update local state immediately
      const updatedStyles = {
        ...surveyStyles,
        backgroundAlignment: alignment,
      };

      setSurveyStyles(updatedStyles);

      // Save to server with complete data
      const saveData = {
        surveyId: surveyId,
        title: surveyTitle,
        questions: questions,
        styles: updatedStyles,
      };

      await handleSaveSurvey(saveData);

      // Verify the save by fetching updated data
      const updatedSurvey = await loadSurveyData();
      setSurveyStyles(updatedSurvey.styles);
    } catch (error) {
      console.error("Error updating background alignment:", error);
      // Revert on error
      setSurveyStyles((prevStyles) => ({ ...prevStyles }));
    }
  };

  // Import the new function
  const handleDeleteMedia = async (fileUrl) => {
    try {
      // First update UI and database to remove references
      if (
        surveyStyles.backgroundImage === fileUrl ||
        surveyStyles.logo?.url === fileUrl
      ) {
        const updatedStyles = {
          ...surveyStyles,
          backgroundImage:
            surveyStyles.backgroundImage === fileUrl
              ? ""
              : surveyStyles.backgroundImage,
          logo:
            surveyStyles.logo?.url === fileUrl
              ? { url: "", size: "md", alignment: "left" }
              : surveyStyles.logo,
        };

        setSurveyStyles(updatedStyles);
        await handleSaveSurvey({
          surveyId: surveyId,
          title: surveyTitle,
          questions: questions,
          styles: updatedStyles,
        });
      }

      // Delete the file
      await deleteMediaFile(fileUrl);

      // Force a complete refresh using getMediaFiles
      const freshFiles = await getMediaFiles();
      setPreviousLogos(freshFiles);
    } catch (error) {
      console.error("Error updating survey:", error);
      // Still try to get a fresh list
      try {
        const freshFiles = await getMediaFiles();
        setPreviousLogos(freshFiles);
      } catch (refreshError) {
        console.error("Failed to refresh media list:", refreshError);
      }
    }
  };

  const appendAiGeneratedQuestions = async (questionsFromAi = [], title) => {
    handleFornSelectorClose();

    const newQuestions = questionsFromAi.map((ques) => ({
      id: uuidv4(),
      ...ques,
    }));

    const updatedQuestions = [...questions, ...newQuestions];

    const updatedStyles = {
      ...surveyStyles,
      backgroundAlignment: "right",
      backgroundImage: defaultAiGeneratedSurveyBackground,
      backgroundColor: defaultAiGeneratedSurveyBackgroundColor,
    };
    setShowBgChangerOnMainScreen(true);

    await handleSaveSurvey({
      title: title || surveyTitle,
      questions: updatedQuestions,
      styles: updatedStyles,
    });

    setQuestions(updatedQuestions);

    setSelectedQuestionForEdit(newQuestions[newQuestions.length - 1]);
    setShowQuestionModal(false);
    if (title) {
      setSurveyTitle(title);
      navigate(`/survey/${surveyId}`, { replace: true });
    }
  };

  // Add handler for new ending screen
  const handleAddEndingScreen = () => {
    const newEndingScreen = {
      id: uuidv4(),
      title: "Ending Screen",
      type: "ending",
      scoreRange: {
        min: 0,
        max: 100,
      },
    };
    const currentEndingScreens = endingScreens;
    setEndingScreens((prev) => [...prev, newEndingScreen]);
    handleSaveSurvey({
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
      endingScreens: [...currentEndingScreens, newEndingScreen],
    });
  };

  // Add handler for new welcome screen
  const handleAddWelcomeScreen = () => {
    const newWelcomeScreen = {
      id: uuidv4(),
      title: "Welcome Screen",
      type: "welcome",
    };
    const currentWelcomeScreens = welcomeScreens;
    setWelcomeScreens((prev) => [...prev, newWelcomeScreen]);
    handleSaveSurvey({
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
      welcomeScreens: [...currentWelcomeScreens, newWelcomeScreen],
    });
  };

  // Update the ending screen selection handler
  const handleEndingScreenSelect = (screen) => {
    setSelectedEndingScreen(screen);
    setSelectedWelcomeScreen(null);
    setSelectedQuestionForEdit(null); // Clear selected question when selecting an ending screen
    setCurrentPreviewQuestion(0);
  };

  // Update the welcome screen selection handler
  const handleWelcomeScreenSelect = (screen) => {
    setSelectedWelcomeScreen(screen);
    setSelectedEndingScreen(null);
    setSelectedQuestionForEdit(null); // Clear selected question when selecting an ending screen
    setCurrentPreviewQuestion(0);
  };

  // Update the delete handler
  const handleDeleteEndingScreen = () => {
    const newEndingScreens = endingScreens.filter(
      (s) => s.id !== selectedEndingScreen.id
    );
    setEndingScreens(newEndingScreens);
    setSelectedEndingScreen(null);
    setShowDeleteConfirm(false);
    handleSaveSurvey({
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
      endingScreens: newEndingScreens,
    });
  };

  const handleDeleteWelcomeScreen = () => {
    const newWelcomeScreens = welcomeScreens.filter(
      (s) => s.id !== selectedWelcomeScreen.id
    );
    setWelcomeScreens(newWelcomeScreens);
    setSelectedWelcomeScreen(null);
    setShowWelcomeDeleteConfirm(false);
    handleSaveSurvey({
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
      welcomeScreens: newWelcomeScreens,
    });
  };

  // Update the ending screen save handler
  const handleSaveEndingScreen = () => {
    if (!selectedEndingScreen) return;

    const updatedEndingScreens = endingScreens.map((screen) =>
      screen.id === selectedEndingScreen.id
        ? {
            ...selectedEndingScreen,
            scoreRange: {
              min: selectedEndingScreen.scoreRange?.min || 0,
              max: selectedEndingScreen.scoreRange?.max || 100,
            },
          }
        : screen
    );

    // Save to both local state and server
    setEndingScreens(updatedEndingScreens);
    setHasUnsavedChanges(false);
    handleSaveSurvey({
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
      endingScreens: updatedEndingScreens,
    });
  };

  // Update the ending screen save handler
  const handleSaveWelcomeScreen = () => {
    if (!selectedWelcomeScreen) return;

    const updatedWelcomeScreens = welcomeScreens.map((screen) =>
      screen.id === selectedWelcomeScreen.id
        ? {
            ...selectedWelcomeScreen,
          }
        : screen
    );

    // Save to both local state and server
    setWelcomeScreens(updatedWelcomeScreens);
    setHasUnsavedChanges(false);
    handleSaveSurvey({
      welcomeScreens: updatedWelcomeScreens,
      title: surveyTitle,
      questions: questions,
      styles: surveyStyles,
    });
  };

  // Update the onChange handlers to track changes
  const handleEndingScreenFieldChange = (field, value) => {
    if (!selectedEndingScreen) return;

    // Show variable selector when @ is typed
    if (field === "title") {
      const lastChar = value.slice(-1);
      if (lastChar === "@") {
        setShowVariableSelector(true);
      } else if (showVariableSelector && lastChar !== "@") {
        // Hide selector if user types something else after @
        setShowVariableSelector(false);
      }
    }

    // Update the ending screen
    setSelectedEndingScreen((prev) => ({
      ...prev,
      [field]: value,
    }));

    setEndingScreens((prev) =>
      prev.map((screen) =>
        screen.id === selectedEndingScreen.id
          ? { ...screen, [field]: value }
          : screen
      )
    );

    setHasUnsavedChanges(true);
  };

  const handleWelcomeScreenFieldChange = (field, value) => {
    if (!selectedWelcomeScreen) return;

    // Update the welcome screen
    setSelectedWelcomeScreen((prev) => ({
      ...prev,
      [field]: value,
    }));

    setWelcomeScreens((prev) =>
      prev.map((screen) =>
        screen.id === selectedWelcomeScreen.id
          ? { ...screen, [field]: value }
          : screen
      )
    );

    setHasUnsavedChanges(true);
  };

  // Update the variable selection handler
  const handleVariableSelect = (variable) => {
    if (!selectedEndingScreen) return;

    const input = document.getElementById("ending-screen-title");
    const cursorPosition = input.selectionStart;
    const currentTitle = selectedEndingScreen.title || "";

    // Create new title by inserting variable at cursor position
    const newTitle =
      currentTitle.slice(0, cursorPosition - 1) + // Remove the @ that triggered the selector
      `@${variable.id}` +
      currentTitle.slice(cursorPosition);

    handleEndingScreenFieldChange("title", newTitle);
    setShowVariableSelector(false);
  };

  if (isLoading) {
    return <SurveyDesignerSkeleton />;
  }

  const replaceWithAiGeneratedQuestions = async (
    questionsFromAi = [],
    title
  ) => {
    setShowFormSelectorAi(false);

    const newQuestions = questionsFromAi.map((ques) => ({
      id: uuidv4(),
      ...ques,
    }));

    const updatedStyles = {
      ...surveyStyles,
      backgroundAlignment: "right",
      backgroundImage: defaultAiGeneratedSurveyBackground,
      backgroundColor: defaultAiGeneratedSurveyBackgroundColor,
    };
    setShowBgChangerOnMainScreen(true);

    await handleSaveSurvey({
      title: title || surveyTitle,
      questions: newQuestions,
      styles: updatedStyles,
    });

    setQuestions(newQuestions);

    setSelectedQuestionForEdit(newQuestions[newQuestions.length - 1]);
    setShowQuestionModal(false);
    if (title) {
      setSurveyTitle(title);
      navigate(`/survey/${surveyId}`, { replace: true });
    }
  };

  const handleOnboardingClose = () => {
    setIsOnboardingCompleted(false);
    navigate(`/survey/${surveyId}`, { replace: true });
  };

  const handleFornSelectorClose = () => {
    setShowFormSelector(false);
    navigate(`/survey/${surveyId}`, { replace: true });
  };

  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="h-full">
        {/* Main Content - 3-column layout */}
        <div className="flex h-full bg-gray-50 pt-12">
          {/* Left Panel - Questions List (14%) */}
          <div className="w-[14%] min-w-[260px] border border-gray-200 bg-white rounded-xl shadow-sm m-4 flex flex-col">
            {/* Questions section */}
            <div className="flex-grow overflow-y-auto p-4">
              <div className="space-y-2">
                {questions.map((q, index) => (
                  <div
                    key={q.id}
                    draggable
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDragEnd={() => {
                      setDraggedIndex(null);
                      setDragOverIndex(null);
                    }}
                    onDrop={(e) => handleDrop(e, index)}
                    onClick={() => handleQuestionSelect(q, index)}
                    className={`
                    group relative rounded-lg p-4 transition-all duration-200 border border-gray-200
                    ${
                      dragOverIndex === index
                        ? "before:border-t-2 before:border-blue-500"
                        : ""
                    }
                    ${
                      selectedQuestionForEdit?.id === q.id
                        ? "bg-blue-50 ring-2 ring-blue-500 border-transparent"
                        : "hover:border-blue-200 hover:bg-blue-50/50"
                    }
                    }
                    cursor-move
                  `}
                  >
                    {/* Question number badge */}
                    <div
                      className={`
                    absolute -left-2 -top-2 w-6 h-6 rounded-full 
                    flex items-center justify-center text-sm font-medium
                    ${
                      selectedQuestionForEdit?.id === q.id
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-500"
                    }
                  `}
                    >
                      {index + 1}
                    </div>

                    {/* Question type icon */}
                    <div className="flex items-center gap-3 mb-2">
                      <div
                        className={`w-6 h-6 ${
                          inputTypes[q.type]?.bgColor
                        } rounded-lg flex items-center justify-center`}
                      >
                        {React.createElement(inputTypes[q.type]?.icon, {
                          size: 16,
                          className: `w-3 h-3 ${inputTypes[q.type]?.iconColor}`,
                        })}
                      </div>
                      <span
                        className={`text-sm font-medium ${
                          selectedQuestionForEdit?.id === q.id
                            ? "text-blue-700"
                            : "text-gray-600"
                        }`}
                      >
                        {q.type.charAt(0).toUpperCase() + q.type.slice(1)}
                      </span>
                    </div>

                    {/* Question text */}
                    <div
                      className={`
                    text-sm pl-1
                    ${
                      selectedQuestionForEdit?.id === q.id
                        ? "text-blue-700"
                        : "text-gray-500"
                    }
                  `}
                    >
                      {q.text || "Click to edit question"}
                    </div>

                    {/* Quick Menu Button - Show on hover */}
                    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMenu(showMenu === q.id ? null : q.id);
                        }}
                        className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                      >
                        <MoreVertical size={16} className="text-gray-500" />
                      </button>

                      {/* Quick Menu Dropdown */}
                      {showMenu === q.id && (
                        <div
                          className="absolute right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={() => {
                              handleDuplicateQuestion(q);
                              setShowMenu(null);
                            }}
                            className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
                          >
                            <Copy size={16} />
                            Duplicate Question
                          </button>
                          <button
                            onClick={() => {
                              deleteQuestion(q.id);
                              setShowMenu(null);
                            }}
                            className="w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
                          >
                            <Trash2 size={16} />
                            Delete Question
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Divider */}
            <div className="border-t border-gray-200"></div>

            {/* Welcome section */}
            <div className="h-48">
              {/* Fixed header */}
              <div className="px-4 py-2 border-b border-gray-100">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">
                    Welcome Screen
                  </h3>
                  {welcomeScreens.length <= 0 && (
                    <button
                      onClick={handleAddWelcomeScreen}
                      className="p-1 text-gray-400 hover:text-gray-500 rounded-md"
                    >
                      <Plus className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>

              {/* Scrollable list */}
              <div
                className="overflow-y-auto p-4"
                style={{ height: "calc(100% - 41px)" }}
              >
                {welcomeScreens.map((screen) => (
                  <div
                    key={screen.id}
                    className={`group flex items-center gap-2 px-3 py-2 text-sm rounded-md cursor-pointer ${
                      selectedWelcomeScreen?.id === screen.id
                        ? "bg-blue-50 text-blue-600"
                        : "hover:bg-gray-50"
                    }`}
                  >
                    <div
                      onClick={() => handleWelcomeScreenSelect(screen)}
                      className="flex items-center gap-2 flex-1"
                    >
                      <FileStack className="w-4 h-4 text-gray-400" />
                      <span className="flex-1 truncate">
                        {screen.title || "Untitled Welcome"}
                      </span>
                    </div>
                    <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                      {/* Delete Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowWelcomeDeleteConfirm(true);
                          setSelectedWelcomeScreen(screen);
                        }}
                        className="p-1 text-gray-400 hover:text-red-500 rounded-md"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Divider */}
            <div className="border-t border-gray-200"></div>

            {/* Endings section */}
            <div className="h-48">
              {/* Fixed header */}
              <div className="px-4 py-2 border-b border-gray-100">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Endings</h3>
                  <button
                    onClick={handleAddEndingScreen}
                    className="p-1 text-gray-400 hover:text-gray-500 rounded-md"
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
              </div>

              {/* Scrollable list */}
              <div
                className="overflow-y-auto p-4"
                style={{ height: "calc(100% - 41px)" }}
              >
                {endingScreens.map((screen) => (
                  <div
                    key={screen.id}
                    className={`group flex items-center gap-2 px-3 py-2 text-sm rounded-md cursor-pointer ${
                      selectedEndingScreen?.id === screen.id
                        ? "bg-blue-50 text-blue-600"
                        : "hover:bg-gray-50"
                    }`}
                  >
                    <div
                      onClick={() => handleEndingScreenSelect(screen)}
                      className="flex items-center gap-2 flex-1"
                    >
                      <FileStack className="w-4 h-4 text-gray-400" />
                      <span className="flex-1 truncate">
                        {screen.title || "Untitled Ending"}
                      </span>
                    </div>
                    <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                      {/* Score Range Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowScoreModal(true);
                          setSelectedEndingScreen(screen);
                        }}
                        className="p-1 text-gray-400 hover:text-blue-500 rounded-md"
                        title="Set Score Range"
                      >
                        <Calculator className="w-4 h-4" />
                      </button>
                      {/* Delete Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteConfirm(true);
                          setSelectedEndingScreen(screen);
                        }}
                        className="p-1 text-gray-400 hover:text-red-500 rounded-md"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Middle Panel - Preview (72%) */}
          <div className="w-[72%] bg-gray-50 overflow-hidden px-4 pt-4">
            <div className="h-full">
              {/* Preview Controls */}
              <div className="sticky top-0 bg-gray-50 z-10">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200">
                  <div className="flex items-center justify-between px-3 py-2">
                    {selectedEndingScreen ? (
                      <div className="text-sm font-medium text-gray-600">
                        Editing Ending Screen{" "}
                        {endingScreens.findIndex(
                          (s) => s.id === selectedEndingScreen.id
                        ) + 1}
                      </div>
                    ) : selectedWelcomeScreen ? (
                      <div className="text-sm font-medium text-gray-600">
                        Editing Welcome Screen{" "}
                        {welcomeScreens.findIndex(
                          (s) => s.id === selectedWelcomeScreen.id
                        ) + 1}
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setShowFormSelector(true)}
                          className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-blue-600 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors"
                        >
                          <Plus size={16} />
                          Add Question
                        </button>
                        <button
                          onClick={() => setShowFormSelectorAi(true)}
                          className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-app-purple-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors"
                        >
                          <Sparkles size={16} />
                          Create With Ai
                        </button>
                      </div>
                    )}

                    <div className="flex items-center gap-2">
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <button
                          onClick={() => setPreviewMode("desktop")}
                          className={`p-1.5 rounded ${
                            previewMode === "desktop"
                              ? "bg-white shadow-sm"
                              : "text-gray-500 hover:text-gray-700"
                          }`}
                        >
                          <Monitor size={16} />
                        </button>
                        <button
                          onClick={() => setPreviewMode("mobile")}
                          className={`p-1.5 rounded ${
                            previewMode === "mobile"
                              ? "bg-white shadow-sm"
                              : "text-gray-500 hover:text-gray-700"
                          }`}
                        >
                          <Smartphone size={16} />
                        </button>
                      </div>

                      <button
                        onClick={handleShare}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                      >
                        <Share2 size={16} />
                        Share
                      </button>

                      <button
                        onClick={() => window.open(`/s/${surveyId}`, "_blank")}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                      >
                        <Eye size={16} />
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Preview Container */}
              <div className="w-full relative flex items-start justify-center overflow-hidden pb-10" style={{aspectRatio: "147/100"}}>
                <div
                  className={`w-full overflow-hidden border border-grey-200 flex flex-col relative ${
                    previewMode === "mobile"
                      ? "w-[375px]"
                      : "w-full max-w-[900px] lg:max-w-[1200px] xl:max-w-[1400px] 2xl:max-w-[1600px] mx-auto"
                  } max-h-[calc(100vh-220px)] bg-white shadow-sm mt-6`}
                  style={{
                    backgroundColor: surveyStyles?.backgroundColor || "#FFFFFF",
                    fontFamily: surveyStyles?.fontFamily || "Inter",
                    ...(previewMode === "mobile" ? {} : {aspectRatio: "147/100"})
                  }}
                >
                  {/* Background Image Container */}
                  {surveyStyles?.backgroundImage &&
                    surveyStyles?.backgroundAlignment !== "cover" && (
                      <>
                        {previewMode === "mobile" && (
                          <div className="absolute inset-0">
                            <img
                              src={surveyStyles.backgroundImage}
                              alt="Background"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}

                        {previewMode === "desktop" && (
                          <div
                            className={`absolute top-0 h-full w-1/2 overflow-hidden ${
                              surveyStyles?.backgroundAlignment === "left"
                                ? "left-0"
                                : "right-0"
                            }`}
                          >
                            <img
                              src={surveyStyles.backgroundImage}
                              alt="Background"
                              className="w-full h-full object-cover"
                            />
                            {showBgChangerOnMainScreen && (
                              <>
                                <div className="absolute top-0 h-full w-full bg-app-black opacity-30"></div>
                                <img
                                  src="/images/clicktoupload.svg"
                                  alt="credit"
                                  className="absolute top-1/2 left-1/2 w-[50%] -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                                  style={{ zIndex: 1000 }}
                                  onClick={() => {
                                    setActiveEditorTab("style");
                                    setShowBackgroundImageModal(true);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}

                  {/* Full Cover Background */}
                  {surveyStyles?.backgroundImage &&
                    surveyStyles?.backgroundAlignment === "cover" && (
                      <div
                        className="absolute inset-0"
                        style={{
                          backgroundImage: `url(${surveyStyles.backgroundImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    )}

                  {/* Logo */}
                  {surveyStyles?.logo?.url && (
                    <div className="absolute top-12 w-full z-20">
                      <img
                        src={surveyStyles.logo.url}
                        alt="Survey Logo"
                        className={`
                          ${
                            surveyStyles.logo.size === "sm"
                              ? "max-h-[30px]"
                              : surveyStyles.logo.size === "md"
                              ? "max-h-[50px]"
                              : "max-h-[70px]"
                          }
                          ${
                            surveyStyles.logo.alignment === "left"
                              ? "ml-4 mr-auto"
                              : surveyStyles.logo.alignment === "center"
                              ? "mx-auto"
                              : "ml-auto mr-4"
                          }
                          object-contain
                        `}
                      />
                    </div>
                  )}
                  <img
                    src="/images/powerBy.svg"
                    alt="credit"
                    className="absolute bottom-4 right-4 w-[20%]"
                    style={{ zIndex: 1000 }}
                  />

                  {/* Content Container */}
                  <div className="relative z-10 flex-1 flex flex-col">
                    {!selectedEndingScreen && !selectedWelcomeScreen && (
                      /* Progress Bar - Only show for questions */
                      <div className="w-full">
                        <div className="w-full bg-gray-200 h-2">
                          <div
                            className="bg-blue-500 h-2 transition-all duration-300"
                            style={{
                              width: `${
                                ((currentPreviewQuestion + 1) /
                                  questions.length) *
                                100
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {selectedEndingScreen ? (
                      // Ending Screen Content
                      <div className="flex-1 flex flex-col items-center justify-center p-8">
                        <div className="w-full max-w-2xl mx-auto text-center space-y-6">
                          <h2 className="text-2xl font-semibold text-gray-900">
                            {selectedEndingScreen.title ||
                              "Say bye! Recall information with @"}
                          </h2>
                          <p className="text-gray-600">
                            {selectedEndingScreen.description ||
                              "Description (optional)"}
                          </p>
                          <button
                            className="px-4 py-2 text-white rounded-md transition-colors mt-4"
                            style={{
                              backgroundColor:
                                surveyStyles?.submitButtonColor || "#3B82F6",
                            }}
                          >
                            {selectedEndingScreen.buttonText || "PollBolt"}
                          </button>
                        </div>
                      </div>
                    ) : selectedWelcomeScreen ? (
                      // Welcome Screen Content
                      <div className="flex-1 flex flex-col items-center justify-center p-8">
                        <div className="w-full max-w-2xl mx-auto text-center space-y-6">
                          <h2 className="text-2xl font-semibold text-gray-900">
                            {selectedWelcomeScreen.title ||
                              "Welcome to the survey"}
                          </h2>
                          <p className="text-gray-600">
                            {selectedWelcomeScreen.description ||
                              "Description (optional)"}
                          </p>
                          <button
                            className="px-4 py-2 text-white rounded-md transition-colors mt-4"
                            style={{
                              backgroundColor:
                                surveyStyles?.submitButtonColor || "#3B82F6",
                            }}
                          >
                            {selectedWelcomeScreen.buttonText || "Start"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Question Content
                      <div
                        className={`relative flex-1 flex ${
                          surveyStyles?.backgroundImage &&
                          surveyStyles?.backgroundAlignment !== "cover" &&
                          previewMode !== "mobile"
                            ? "w-1/2 " +
                              (surveyStyles?.backgroundAlignment === "right"
                                ? "mr-auto"
                                : "ml-auto")
                            : "w-full"
                        }`}
                      >
                        <div
                          className={`flex-1 flex flex-col items-center justify-start w-full max-w-2xl mx-auto ${
                            previewMode === "mobile"
                              ? "mt-32"
                              : "mt-24 lg:mt-32 xl:mt-20"
                          }`}
                        >
                          <div
                            className={`w-full px-4 ${
                              previewMode === "mobile"
                                ? "max-w-[375px]"
                                : "px-6 md:px-8 lg:px-12"
                            } ${
                              surveyStyles?.backgroundImage &&
                              surveyStyles?.backgroundAlignment !== "cover" &&
                              previewMode !== "mobile"
                                ? "items-start"
                                : "items-center"
                            }`}
                          >
                            <QuestionRenderer
                              question={questions[currentPreviewQuestion]}
                              isDesignerPreview={true}
                              onQuestionUpdate={handleQuestionUpdate}
                              onNavigate={(direction) => {
                                if (
                                  direction === "next" &&
                                  currentPreviewQuestion < questions.length - 1
                                ) {
                                  const nextIndex = currentPreviewQuestion + 1;
                                  setCurrentPreviewQuestion(nextIndex);
                                  setSelectedQuestionForEdit(
                                    questions[nextIndex]
                                  );
                                } else if (
                                  direction === "previous" &&
                                  currentPreviewQuestion > 0
                                ) {
                                  const prevIndex = currentPreviewQuestion - 1;
                                  setCurrentPreviewQuestion(prevIndex);
                                  setSelectedQuestionForEdit(
                                    questions[prevIndex]
                                  );
                                }
                              }}
                              isLastQuestion={
                                currentPreviewQuestion === questions.length - 1
                              }
                              currentQuestionIndex={currentPreviewQuestion}
                              totalQuestions={questions.length}
                              showProgress={false}
                              styles={{
                                ...surveyStyles,
                                alignment:
                                  previewMode === "mobile"
                                    ? "center"
                                    : surveyStyles?.backgroundImage &&
                                      surveyStyles?.backgroundAlignment !==
                                        "cover"
                                    ? "left"
                                    : "center",
                                maxWidth:
                                  previewMode === "mobile" ? "100%" : "100%",
                                containerWidth:
                                  previewMode === "mobile"
                                    ? "w-full"
                                    : "max-w-xl",
                                questionSpacing: "mb-5",
                                answerFontSize: "text-sm",
                                answerPadding: "py-1.5 px-3",
                                answerSpacing: "space-y-1",
                                radioSize: "w-5 h-5",
                                checkboxSize: "w-5 h-5",
                                questionFontSize: "text-lg",
                                inputHeight: "h-8",
                                textareaHeight: "h-24",
                                answerContainerPadding: "py-2",
                                answerLineHeight: "leading-snug",
                                answerMargin: "my-0.5",
                                compact: true,
                              }}
                              questionNumber={currentPreviewQuestion + 1}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Panel - Question Editor (14%) */}
          <div className="w-[14%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
            {/* Tabs - Sticky */}
            <div className="sticky top-0 bg-white z-20">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px px-2 pt-2">
                  {["Edit", "Style"].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveEditorTab(tab.toLowerCase())}
                      className={`px-3 py-2 text-xs font-medium transition-colors
                        ${
                          activeEditorTab === tab.toLowerCase()
                            ? "border-b-2 border-blue-500 text-blue-600"
                            : "border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        }
                      `}
                    >
                      {tab}
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            {/* Tab Content */}
            <div className="p-4">
              {activeEditorTab === "edit" ? (
                selectedQuestionForEdit ? (
                  // Question Editor
                  <div className="space-y-3">
                    {/* Question Type */}
                    <div className="question-type-dropdown">
                      <label className="block text-xs font-medium text-gray-600 mb-1">
                        Question Type
                      </label>
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() =>
                            setIsTypeDropdownOpen(!isTypeDropdownOpen)
                          }
                          className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-white flex items-center justify-between"
                        >
                          <div className="flex items-center gap-2">
                            <div className="absolute left-2.5">
                              {React.createElement(
                                questionTypes.find(
                                  (t) =>
                                    t.value === selectedQuestionForEdit.type
                                )?.icon || ListChecks,
                                { size: 16, className: "text-gray-500" }
                              )}
                            </div>
                            <span className="ml-7">
                              {
                                questionTypes.find(
                                  (t) =>
                                    t.value === selectedQuestionForEdit.type
                                )?.label
                              }
                            </span>
                          </div>
                          <ChevronRight
                            size={16}
                            className={`text-gray-400 transition-transform duration-200 ${
                              isTypeDropdownOpen
                                ? "rotate-[270deg]"
                                : "rotate-90"
                            }`}
                          />
                        </button>

                        {/* Dropdown menu */}
                        {isTypeDropdownOpen && (
                          <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg py-1">
                            {questionTypes.map(
                              ({ value, label, icon: Icon, description }) => (
                                <button
                                  key={value}
                                  onClick={() => {
                                    const updatedQuestion = {
                                      ...selectedQuestionForEdit,
                                      type: value,
                                      options:
                                        value === "multiple choice"
                                          ? ["Option 1", "Option 2"]
                                          : [],
                                    };

                                    const updatedQuestions = questions.map(
                                      (q) =>
                                        q.id === selectedQuestionForEdit.id
                                          ? updatedQuestion
                                          : q
                                    );

                                    setQuestions(updatedQuestions);
                                    setSelectedQuestionForEdit(updatedQuestion);
                                    setIsTypeDropdownOpen(false);

                                    handleSaveSurvey({
                                      title: surveyTitle,
                                      questions: updatedQuestions,
                                      styles: surveyStyles,
                                    });
                                  }}
                                  className={`w-full px-3 py-2 text-left flex items-center gap-3 hover:bg-gray-50 ${
                                    selectedQuestionForEdit.type === value
                                      ? "bg-blue-50"
                                      : ""
                                  }`}
                                >
                                  <Icon
                                    size={16}
                                    className={`flex-shrink-0 ${
                                      selectedQuestionForEdit.type === value
                                        ? "text-blue-500"
                                        : "text-gray-500"
                                    }`}
                                  />
                                  <div>
                                    <div
                                      className={`text-sm ${
                                        selectedQuestionForEdit.type === value
                                          ? "text-blue-600 font-medium"
                                          : "text-gray-700"
                                      }`}
                                    >
                                      {label}
                                    </div>
                                    <div className="text-xs text-gray-500">
                                      {description}
                                    </div>
                                  </div>
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Question-specific style options */}
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      {/* Your existing question-specific style options */}
                    </div>
                  </div>
                ) : selectedEndingScreen ? (
                  // Ending Screen Editor
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveEndingScreen();
                    }}
                  >
                    <div className="space-y-6">
                      {/* Title */}
                      <div className="space-y-4">
                        <div className="relative">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Title
                          </label>
                          <input
                            id="ending-screen-title"
                            type="text"
                            value={selectedEndingScreen?.title || ""}
                            onChange={(e) =>
                              handleEndingScreenFieldChange(
                                "title",
                                e.target.value
                              )
                            }
                            className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter title..."
                          />

                          {showVariableSelector && (
                            <div className="absolute left-0 right-0 mt-1">
                              <div className="w-64 bg-white rounded-lg shadow-lg border border-gray-200">
                                <div className="p-2">
                                  <div className="text-sm font-medium text-gray-700 px-2 py-1.5 border-b">
                                    Insert variable
                                  </div>
                                  <div className="max-h-[300px] overflow-y-auto">
                                    {variables.map((variable) => (
                                      <button
                                        key={variable.id}
                                        onClick={() =>
                                          handleVariableSelect(variable)
                                        }
                                        className="w-full px-3 py-2 text-left hover:bg-gray-50 rounded-md group flex items-start gap-2"
                                      >
                                        <div className="flex-shrink-0 w-8 h-8 bg-blue-50 rounded-md flex items-center justify-center">
                                          <span className="text-blue-600 font-medium">
                                            @
                                          </span>
                                        </div>
                                        <div>
                                          <div className="text-sm font-medium text-gray-900">
                                            {variable.label}
                                          </div>
                                          <div className="text-xs text-gray-500">
                                            {variable.description}
                                          </div>
                                        </div>
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Description */}
                      <div>
                        <label className="block text-xs font-medium text-gray-600 mb-1">
                          Description
                        </label>
                        <textarea
                          value={selectedEndingScreen.description || ""}
                          onChange={(e) =>
                            handleEndingScreenFieldChange(
                              "description",
                              e.target.value
                            )
                          }
                          className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          rows={3}
                          placeholder="Enter description"
                        />
                      </div>

                      {/* Button Settings */}
                      <div className="space-y-4">
                        <h3 className="text-xs font-medium text-gray-600">
                          Button Settings
                        </h3>

                        {/* Button Text */}
                        <div>
                          <label className="block text-xs font-medium text-gray-600 mb-1">
                            Button Text
                          </label>
                          <input
                            type="text"
                            value={selectedEndingScreen.buttonText || ""}
                            onChange={(e) =>
                              handleEndingScreenFieldChange(
                                "buttonText",
                                e.target.value
                              )
                            }
                            className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter button text"
                          />
                        </div>

                        {/* Button URL */}
                        <div>
                          <label className="block text-xs font-medium text-gray-600 mb-1">
                            Button URL
                          </label>
                          <input
                            type="url"
                            value={selectedEndingScreen.buttonUrl || ""}
                            onChange={(e) =>
                              handleEndingScreenFieldChange(
                                "buttonUrl",
                                e.target.value
                              )
                            }
                            className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="https://..."
                          />
                        </div>
                      </div>

                      {/* Save Button */}
                      <div className="pt-4 border-t">
                        <button
                          type="submit"
                          disabled={!hasUnsavedChanges}
                          className={`w-full px-3 py-2 text-sm font-medium text-white rounded-md transition-colors ${
                            hasUnsavedChanges
                              ? "bg-blue-600 hover:bg-blue-700"
                              : "bg-gray-300 cursor-not-allowed"
                          }`}
                        >
                          {saveStatus === "saving"
                            ? "Saving..."
                            : "Save Changes"}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : selectedWelcomeScreen ? (
                  // Welcome Screen Editor
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveWelcomeScreen();
                    }}
                  >
                    <div className="space-y-6">
                      {/* Title */}
                      <div className="space-y-4">
                        <div className="relative">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Title
                          </label>
                          <input
                            id="welcome-screen-title"
                            type="text"
                            value={selectedWelcomeScreen?.title || ""}
                            onChange={(e) =>
                              handleWelcomeScreenFieldChange(
                                "title",
                                e.target.value
                              )
                            }
                            className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter title..."
                          />
                        </div>
                      </div>

                      {/* Description */}
                      <div>
                        <label className="block text-xs font-medium text-gray-600 mb-1">
                          Description
                        </label>
                        <textarea
                          value={selectedWelcomeScreen.description || ""}
                          onChange={(e) =>
                            handleWelcomeScreenFieldChange(
                              "description",
                              e.target.value
                            )
                          }
                          className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          rows={3}
                          placeholder="Enter description"
                        />
                      </div>

                      {/* Button Settings */}
                      <div className="space-y-4">
                        <h3 className="text-xs font-medium text-gray-600">
                          Button Settings
                        </h3>

                        {/* Button Text */}
                        <div>
                          <label className="block text-xs font-medium text-gray-600 mb-1">
                            Button Text
                          </label>
                          <input
                            type="text"
                            value={selectedWelcomeScreen.buttonText || ""}
                            onChange={(e) =>
                              handleWelcomeScreenFieldChange(
                                "buttonText",
                                e.target.value
                              )
                            }
                            className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter button text"
                          />
                        </div>
                      </div>

                      {/* Save Button */}
                      <div className="pt-4 border-t">
                        <button
                          type="submit"
                          disabled={!hasUnsavedChanges}
                          className={`w-full px-3 py-2 text-sm font-medium text-white rounded-md transition-colors ${
                            hasUnsavedChanges
                              ? "bg-blue-600 hover:bg-blue-700"
                              : "bg-gray-300 cursor-not-allowed"
                          }`}
                        >
                          {saveStatus === "saving"
                            ? "Saving..."
                            : "Save Changes"}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  // No selection state
                  <div className="text-center text-gray-500 py-8">
                    Select a question or welcome/ending screen to edit
                  </div>
                )
              ) : (
                // Style tab content
                <div className="space-y-3">
                  {/* Global Style Settings */}
                  <div>
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Font Family
                    </label>
                    <div className="relative font-dropdown">
                      <button
                        onClick={() =>
                          setIsFontDropdownOpen(!isFontDropdownOpen)
                        }
                        className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-white flex items-center justify-between"
                        style={{ fontFamily: surveyStyles.fontFamily }}
                      >
                        <span>{surveyStyles.fontFamily}</span>
                        <ChevronRight
                          size={16}
                          className={`text-gray-400 transition-transform duration-200 ${
                            isFontDropdownOpen ? "rotate-[270deg]" : "rotate-90"
                          }`}
                        />
                      </button>

                      {isFontDropdownOpen && (
                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg py-1 max-h-[280px] overflow-y-auto">
                          {fonts.map((font) => (
                            <button
                              key={font.value}
                              onClick={() => {
                                handleStyleChange("fontFamily", font.value);
                                setIsFontDropdownOpen(false);
                              }}
                              className={`w-full px-3 py-2 text-left hover:bg-gray-50 ${
                                surveyStyles.fontFamily === font.value
                                  ? "bg-blue-50"
                                  : ""
                              }`}
                            >
                              <span
                                className="text-sm font-medium"
                                style={{ fontFamily: font.value }}
                              >
                                {font.name}
                              </span>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Background Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Background Color
                      </label>
                      <button
                        onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{
                            backgroundColor: surveyStyles.backgroundColor,
                          }}
                        />
                        <ChevronRight
                          size={14}
                          className={`text-gray-400 transition-transform duration-200 ${
                            showBgColorPicker ? "-rotate-90" : "rotate-90"
                          }`}
                        />
                      </button>
                    </div>
                    {showBgColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="button-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.backgroundColor}
                            onChange={(color) =>
                              handleStyleChange("backgroundColor", color.hex)
                            }
                          />
                          <button
                            onClick={() => setShowBgColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Question Text Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Question Text Color
                      </label>
                      <button
                        onClick={() =>
                          setShowTextColorPicker(!showTextColorPicker)
                        }
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{
                            backgroundColor: surveyStyles.questionTextColor,
                          }}
                        />
                        <ChevronRight
                          size={14}
                          className={`text-gray-400 transition-transform duration-200 ${
                            showTextColorPicker ? "-rotate-90" : "rotate-90"
                          }`}
                        />
                      </button>
                    </div>
                    {showTextColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="text-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.questionTextColor}
                            onChange={(color) =>
                              handleStyleChange("questionTextColor", color.hex)
                            }
                          />
                          <button
                            onClick={() => setShowTextColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Button Color */}
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-600">
                        Button Color
                      </label>
                      <button
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        className="flex items-center gap-1 bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300"
                      >
                        <div
                          className="w-5 h-5 rounded border border-gray-200"
                          style={{
                            backgroundColor: surveyStyles.submitButtonColor,
                          }}
                        />
                        <ChevronRight
                          size={14}
                          className={`text-gray-400 transition-transform duration-200 ${
                            showColorPicker ? "-rotate-90" : "rotate-90"
                          }`}
                        />
                      </button>
                    </div>
                    {showColorPicker && (
                      <div className="absolute right-0 mt-2 z-10">
                        <div className="button-color-picker p-2 bg-white rounded-lg shadow-lg">
                          <ChromePicker
                            color={surveyStyles.submitButtonColor}
                            onChange={(color) =>
                              handleStyleChange("submitButtonColor", color.hex)
                            }
                          />
                          <button
                            onClick={() => setShowColorPicker(false)}
                            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium flex items-center justify-center gap-2"
                          >
                            <Check size={16} />
                            Done
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Logo Section */}
                  <div>
                    <div className="space-y-3">
                      <div className="flex items-center justify-between mt-8">
                        <span className="text-xs font-medium text-gray-600">
                          Brand Logo
                        </span>
                        <div className="flex items-center gap-1">
                          <button
                            onClick={() => setShowMediaModal(true)}
                            className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                            title="Change Logo"
                          >
                            <Image size={14} className="text-gray-500" />
                          </button>
                          <button
                            onClick={async () => {
                              try {
                                const updatedStyles = {
                                  ...surveyStyles,
                                  logo: {
                                    url: "",
                                    size: "md",
                                    alignment: "left",
                                  },
                                };
                                setSurveyStyles(updatedStyles);
                                await handleSaveSurvey({
                                  id: surveyId,
                                  surveyId: surveyId,
                                  title: surveyTitle,
                                  questions: questions,
                                  styles: updatedStyles,
                                });
                                setLogoUrl("");
                                setLogoSize("md");
                                setLogoAlignment("left");
                              } catch (error) {
                                console.error("Error removing logo:", error);
                                alert(
                                  "Failed to remove logo. Please try again."
                                );
                              }
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                            title="Remove Logo"
                          >
                            <Trash2 size={14} className="text-gray-500" />
                          </button>
                        </div>
                      </div>

                      {/* Logo Preview Box */}
                      <div
                        className="border rounded-lg p-3 bg-gray-50 cursor-pointer hover:border-blue-500 transition-colors"
                        onClick={() => setShowMediaModal(true)}
                      >
                        {surveyStyles?.logo?.url ? (
                          <div className="relative aspect-video flex items-center justify-center bg-white rounded-md overflow-hidden group">
                            <img
                              src={surveyStyles.logo.url}
                              alt="Logo Preview"
                              className="max-w-full max-h-full object-contain p-2"
                            />
                            <div className="absolute inset-0 bg-black/25 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <div className="px-4 py-2 bg-white/90 rounded-md shadow-sm">
                                <span className="text-gray-700 text-sm font-medium">
                                  Change Logo
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="aspect-video flex items-center justify-center bg-white rounded-md border border-dashed border-gray-200">
                            <div className="text-xs text-gray-400 text-center">
                              <Image
                                size={20}
                                className="mx-auto mb-1 opacity-40"
                              />
                              Click to add logo
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Logo Size */}
                      <div className="mt-3">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Logo Size
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {["sm", "md", "lg"].map((size) => (
                            <button
                              key={size}
                              onClick={() => handleLogoSizeChange(size)}
                              className={`flex-1 p-2 rounded transition-all ${
                                surveyStyles.logo?.size === size
                                  ? "bg-white shadow-sm text-blue-600"
                                  : "text-gray-500 hover:text-gray-700"
                              }`}
                              title={size.toUpperCase()}
                            >
                              <Image
                                size={
                                  size === "sm" ? 14 : size === "md" ? 18 : 22
                                }
                                className="mx-auto"
                              />
                            </button>
                          ))}
                        </div>
                      </div>

                      {/* Logo Alignment */}
                      <div className="space-y-2">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Logo Alignment
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {[
                            { value: "left", Icon: AlignLeft },
                            { value: "center", Icon: AlignCenter },
                            { value: "right", Icon: AlignRight },
                          ].map(({ value, Icon }) => (
                            <button
                              key={value}
                              onClick={() => handleLogoAlignmentChange(value)}
                              className={`flex-1 p-2 rounded transition-all ${
                                surveyStyles.logo?.alignment === value
                                  ? "bg-white shadow-sm text-blue-600"
                                  : "text-gray-500 hover:text-gray-700"
                              }`}
                              title={`Align ${value}`}
                            >
                              <Icon size={14} className="mx-auto" />
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Background Image Section */}
                  <div className="mt-8 pt-8 border-t border-gray-200">
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-xs font-medium text-gray-600">
                        Background Image
                      </span>
                      {surveyStyles.backgroundImage && (
                        <button
                          onClick={() => {
                            const updatedStyles = {
                              ...surveyStyles,
                              backgroundImage: null,
                            };
                            setSurveyStyles(updatedStyles);
                            handleStyleChange("backgroundImage", null);
                          }}
                          className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                          title="Remove Background"
                        >
                          <Trash2 size={14} className="text-gray-500" />
                        </button>
                      )}
                    </div>

                    {/* Background Image Preview/Upload Box */}
                    <div
                      className="border rounded-lg p-3 bg-gray-50 cursor-pointer hover:border-blue-500 transition-colors"
                      onClick={() => setShowBackgroundImageModal(true)}
                    >
                      {surveyStyles.backgroundImage ? (
                        <div className="relative aspect-video flex items-center justify-center bg-white rounded-md overflow-hidden group">
                          <img
                            src={surveyStyles.backgroundImage}
                            alt="Background Preview"
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-black/25 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-lg">
                            <button className="text-white">
                              <Check size={20} />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="aspect-video flex items-center justify-center bg-white rounded-md border border-dashed border-gray-200">
                          <div className="text-xs text-gray-400 text-center">
                            <Image
                              size={20}
                              className="mx-auto mb-1 opacity-40"
                            />
                            Click to add background image
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Background Image Layout */}
                    {surveyStyles.backgroundImage && (
                      <div className="mt-3">
                        <label className="block text-xs font-medium text-gray-600 mb-2">
                          Background Layout
                        </label>
                        <div className="flex gap-1 p-1 bg-gray-50 rounded-lg">
                          {[
                            {
                              value: "cover",
                              Icon: Layout,
                              label: "Full Cover",
                            },
                            {
                              value: "left",
                              Icon: PanelLeft,
                              label: "Image Left",
                            },
                            {
                              value: "right",
                              Icon: PanelRight,
                              label: "Image Right",
                            },
                          ].map(({ value, Icon, label }) => {
                            const currentAlignment =
                              surveyStyles.backgroundAlignment || "cover";
                            const isSelected = currentAlignment === value;

                            return (
                              <button
                                key={value}
                                onClick={() =>
                                  handleBackgroundAlignmentChange(value)
                                }
                                className={`flex-1 p-2 rounded transition-all ${
                                  isSelected
                                    ? "bg-white shadow-sm text-blue-600"
                                    : "text-gray-500 hover:text-gray-700"
                                }`}
                                title={label}
                              >
                                <Icon size={16} className="mx-auto" />
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      {showLogicConfig && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Question Logic
              </h3>
              <button
                onClick={() => setShowLogicConfig(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4">
              {/* Logic configuration content */}
              <p className="text-sm text-gray-600">
                Configure conditional logic for this question.
              </p>
              {/* Add your logic configuration UI here */}
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowLogicConfig(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showQuestionModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center"
            style={{ zIndex: 99999 }}
          >
            <div className="bg-white rounded-2xl shadow-xl max-w-md w-full m-4 overflow-hidden">
              {/* Header */}
              <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  Choose Question Type
                </h3>
                <button
                  onClick={() => setShowQuestionModal(false)}
                  className="text-gray-400 hover:text-gray-500 p-1 hover:bg-gray-50 rounded-full transition-colors"
                >
                  <X size={20} />
                </button>
              </div>

              {/* Question Types Grid */}
              <div className="p-6">
                <div className="grid grid-cols-1 gap-3">
                  {[
                    {
                      type: "multiple choice",
                      label: "Multiple Choice",
                      icon: ListChecks,
                      description:
                        "Let respondents choose from predefined options",
                    },
                    {
                      type: "checkbox list",
                      label: "Checkbox List",
                      icon: CheckSquare,
                      description:
                        "Allow respondents to select multiple options",
                    },
                    {
                      type: "short text",
                      label: "Short Text",
                      icon: TextCursor,
                      description: "Collect brief text responses",
                    },
                    {
                      type: "long text",
                      label: "Long Text",
                      icon: AlignLeft,
                      description: "Gather detailed text responses",
                    },
                    {
                      type: "numeric",
                      label: "Numeric",
                      icon: Hash,
                      description: "Collect numerical data",
                    },
                    {
                      type: "star rating",
                      label: "Star Rating",
                      icon: Star,
                      description: "Get feedback on a rating scale",
                    },
                  ].map(({ type, label, icon: Icon, description }) => (
                    <button
                      key={type}
                      onClick={() => handleAddQuestion(type)}
                      className="flex items-center gap-4 p-4 text-left border border-gray-200 rounded-xl hover:border-blue-500 hover:bg-blue-50 transition-all group"
                    >
                      <div className="flex-shrink-0">
                        <div className="w-10 h-10 rounded-lg bg-blue-100 text-blue-600 flex items-center justify-center group-hover:bg-blue-600 group-hover:text-white transition-colors">
                          <Icon size={20} />
                        </div>
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                          {label}
                        </div>
                        <div className="text-sm text-gray-500">
                          {description}
                        </div>
                      </div>
                      <ChevronRight
                        size={16}
                        className="text-gray-400 group-hover:text-blue-500"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}

      {showMediaModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center"
            style={{ zIndex: 50 }}
          >
            <div className="bg-white rounded-2xl shadow-xl max-w-4xl w-full m-4">
              <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  Media Library
                </h3>
                <button
                  onClick={() => setShowMediaModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="p-6">
                {/* Upload Section */}
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="logo-upload"
                  onChange={handleFileUpload}
                />

                <div
                  className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                  onClick={() => document.getElementById("logo-upload").click()}
                >
                  <div className="flex flex-col items-center gap-2">
                    {isUploading ? (
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                    ) : (
                      <>
                        <Image size={24} className="text-gray-400" />
                        <div className="text-sm font-medium text-gray-700">
                          Click to upload or drag and drop
                        </div>
                        <div className="text-xs text-gray-500">
                          SVG, PNG, JPG (max. 2MB)
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Previous Logos Section */}
                <div className="mt-8">
                  <h4 className="text-sm font-medium text-gray-700 mb-4">
                    Previously Uploaded Logos
                  </h4>
                  {isLoadingLogos ? (
                    <div className="flex justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                    </div>
                  ) : (
                    <div className="grid grid-cols-4 gap-4">
                      {previousLogos.map((logo, index) => (
                        <div
                          key={index}
                          className="relative group border rounded-lg p-2 hover:border-blue-500 cursor-pointer"
                          onClick={() => handleLogoSelect(logo)}
                        >
                          <img
                            src={logo.url}
                            alt={`Logo ${index + 1}`}
                            className="w-full h-24 object-contain"
                          />
                          <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2 rounded-lg">
                            <button
                              className="text-white bg-white/20 p-2 rounded-full hover:bg-white/30"
                              onClick={() => handleLogoSelect(logo)}
                            >
                              <Check size={20} />
                            </button>
                            <button
                              className="text-white bg-red-500/20 p-2 rounded-full hover:bg-red-500/30"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteMedia(logo.url);
                              }}
                            >
                              <Trash2 size={20} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}

      {showBackgroundImageModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center"
            style={{ zIndex: 50 }}
          >
            <div className="bg-white rounded-2xl shadow-xl w-[800px] max-h-[80vh] m-4">
              {" "}
              {/* Reduced width and added max height */}
              <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  Choose Background Image
                </h3>
                <button
                  onClick={handleCloseBgChanger}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>
              {/* Tabs */}
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px px-6">
                  {[
                    { id: "stock", label: "Stock Photos", icon: ImagePlus },
                    { id: "upload", label: "Upload", icon: Upload },
                    { id: "library", label: "My Library", icon: Library },
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveBackgroundTab(tab.id)}
                      className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
                        activeBackgroundTab === tab.id
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                      }`}
                    >
                      {React.createElement(tab.icon, { size: 16 })}
                      {tab.label}
                    </button>
                  ))}
                </nav>
              </div>
              {/* Scrollable content area */}
              <div
                className="p-6 overflow-y-auto"
                style={{ maxHeight: "calc(80vh - 140px)" }}
              >
                {" "}
                {/* Added overflow and max height */}
                {activeBackgroundTab === "stock" && (
                  <div className="grid grid-cols-2 gap-4">
                    {stockPhotos.map((photo) => (
                      <div
                        key={photo.id}
                        className="relative group cursor-pointer rounded-lg overflow-hidden aspect-video" // Added aspect-video
                        onClick={() =>
                          handleBackgroundImageSelect({ url: photo.url })
                        }
                      >
                        <img
                          src={photo.url}
                          alt="Background option"
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                          <button className="text-white bg-white/20 p-2 rounded-full">
                            <Check size={20} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {activeBackgroundTab === "upload" && (
                  <div
                    className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                    onClick={() =>
                      document.getElementById("background-upload").click()
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="background-upload"
                      onChange={handleBackgroundImageUpload}
                    />
                    <div className="flex flex-col items-center gap-2">
                      {isUploading ? (
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                      ) : (
                        <>
                          <Image size={24} className="text-gray-400" />
                          <div className="text-sm font-medium text-gray-700">
                            Click to upload or drag and drop
                          </div>
                          <div className="text-xs text-gray-500">
                            SVG, PNG, JPG (max. 2MB)
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {activeBackgroundTab === "library" && (
                  <div className="grid grid-cols-3 gap-4">
                    {" "}
                    {/* Changed to 3 columns */}
                    {previousLogos.map((image, index) => (
                      <div
                        key={index}
                        className="relative group border rounded-lg p-2 hover:border-blue-500 cursor-pointer aspect-video" // Added aspect-video
                        onClick={() => handleBackgroundImageSelect(image)}
                      >
                        <img
                          src={image.url}
                          alt={`Image ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2 rounded-lg">
                          <button
                            className="text-white bg-white/20 p-2 rounded-full hover:bg-white/30"
                            onClick={() => handleBackgroundImageSelect(image)}
                          >
                            <Check size={20} />
                          </button>
                          <button
                            className="text-white bg-red-500/20 p-2 rounded-full hover:bg-red-500/30"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteMedia(image.url);
                            }}
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>,
          document.body
        )}

      <ConfirmationModal
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDeleteEndingScreen}
        title="Delete Ending Screen"
        message="Are you sure you want to delete this ending screen? This action cannot be undone."
      />

      <ConfirmationModal
        isOpen={showWelcomeDeleteConfirm}
        onClose={() => setShowWelcomeDeleteConfirm(false)}
        onConfirm={handleDeleteWelcomeScreen}
        title="Delete Welcome Screen"
        message="Are you sure you want to delete this welcome screen? This action cannot be undone."
      />

      <FormSelectorDialog
        isShown={showFormSelector}
        onClose={handleFornSelectorClose}
        handleAddQuestion={handleAddQuestion}
        appendAiGeneratedQuestions={appendAiGeneratedQuestions}
        generateTitleWithAi={optionFromQuery === "ai"}
        preSelectedTab={
          ["ai", "select", "import"].includes(optionFromQuery)
            ? optionFromQuery
            : "select"
        }
      />
      <FormSelectorDialog
        isShown={showFormSelectorAi}
        onClose={() => setShowFormSelectorAi(false)}
        handleAddQuestion={handleAddQuestion}
        appendAiGeneratedQuestions={replaceWithAiGeneratedQuestions}
        generateTitleWithAi={optionFromQuery === "ai"}
        preSelectedTab="ai"
      />

      {/* Share modal */}
      <ModalContainer
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        title="Share options"
        size="lg"
      >
        <ShareSurveyModal
          surveyId={surveyId}
          surveyLink={`${window.location.origin}/s/${surveyId}`}
          handleCopyLink={handleCopyLink}
          onClose={handleOnboardingClose}
        />
      </ModalContainer>

      {/* Score Range Modal */}
      <ModalContainer
        isOpen={showScoreModal}
        onClose={() => setShowScoreModal(false)}
        title={`Set Score Range for "${
          selectedEndingScreen?.title || "Untitled Ending"
        }"`}
        size="sm"
      >
        <div className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Score Range
            </label>
            <div className="flex items-center gap-3">
              <div>
                <label className="block text-xs text-gray-500 mb-1">
                  Min Score
                </label>
                <input
                  type="number"
                  value={selectedEndingScreen?.scoreRange?.min ?? 0}
                  onChange={(e) => {
                    const min = parseInt(e.target.value);
                    handleEndingScreenFieldChange("scoreRange", {
                      ...selectedEndingScreen?.scoreRange,
                      min: min,
                    });
                  }}
                  className="w-24 px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  min="0"
                />
              </div>
              <div className="text-gray-400">to</div>
              <div>
                <label className="block text-xs text-gray-500 mb-1">
                  Max Score
                </label>
                <input
                  type="number"
                  value={selectedEndingScreen?.scoreRange?.max ?? 50}
                  onChange={(e) => {
                    const max = parseInt(e.target.value);
                    handleEndingScreenFieldChange("scoreRange", {
                      ...selectedEndingScreen?.scoreRange,
                      max: max,
                    });
                  }}
                  className="w-24 px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  min="0"
                />
              </div>
            </div>
          </div>

          {/* Add a helper text to show the range */}
          <p className="text-xs text-gray-500">
            This ending screen will be shown when the total score is between{" "}
            {selectedEndingScreen?.scoreRange?.min ?? 0} and{" "}
            {selectedEndingScreen?.scoreRange?.max ?? 50}
          </p>

          <div className="flex justify-end gap-3 pt-4 border-t">
            <button
              onClick={() => setShowScoreModal(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSaveEndingScreen();
                setShowScoreModal(false);
              }}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </div>
      </ModalContainer>

      {/* onboarding completed modal */}
      <ModalContainer
        isOpen={isOnboardingCompleted}
        onClose={handleOnboardingClose}
        title=""
        size="lg"
        hideHeaderBorder={true}
      >
        <OnboardingCompleted
          surveyId={surveyId}
          surveyLink={`${window.location.origin}/s/${surveyId}`}
          handleCopyLink={handleCopyLink}
          onClose={handleOnboardingClose}
        />
      </ModalContainer>

      {showVariableSelector && (
        <VariableSelector
          position={variableSelectorPosition}
          onSelect={handleVariableSelect}
          onClose={() => setShowVariableSelector(false)}
        />
      )}
    </div>
  );
};

export default SurveyDesigner;
