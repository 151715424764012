import {
  CheckIcon,
  DotIcon,
  RefreshCcw,
  SparklesIcon,
  ArrowRightIcon,
  Sparkles,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../Button/Button";
import inputTypes from "../../assets/inputTypes";
import { generateAiQuestions, generateAiSuggestions } from "../../serverComm";
import { debounce } from "lodash";
import { defaultSuggestions } from "../../assets/defaults";

const DescribeProject = ({
  description,
  setDescription,
  onSubmit,
  setCurrentStep,
}) => {
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState(defaultSuggestions);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);

  const handleGenerate = async () => {
    setError("");
    if (!description || description.length < 10) {
      setError("Please provide a description of at least 10 characters");
      return;
    }
    setIsGenerating(true);
    const result = await generateAiQuestions({ description });
    setGeneratedQuestions(result.questions);
    setCurrentStep(3);
    setIsGenerating(false);
  };

  const handleGenerateAiSuggestions = useCallback(
    debounce(async (text) => {
      try {
        setIsSuggestionsLoading(true);
        const result = await generateAiSuggestions({ description: text });
        setSuggestions(result.suggestions);
        setIsSuggestionsLoading(false);
      } catch (error) {
        setIsSuggestionsLoading(false);
      }
    }, 2000),
    []
  );

  const handleChangeDescription = (e) => {
    setError("");
    setDescription(e.target.value);
    handleGenerateAiSuggestions(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(generatedQuestions);
  };

  useEffect(() => {
    if (description) {
      handleGenerateAiSuggestions(description);
    }
  }, [description]);

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/2 h-screen overflow-y-auto flex flex-col">
        <div className="h-10"></div>
        <div className="w-full flex-1 flex items-center">
          <div className="p-24 w-full">
            <h1 className="text-[43.64px] mb-6">Describe your form</h1>

            <textarea
              placeholder="For example, a feedback survey for the company's annual training session in San Francisco."
              value={description}
              onChange={handleChangeDescription}
              className="w-full max-w-[700px] h-[281px] p-4 mb-8 border bg-app-gray-50 border-app-gray-200 rounded-xl resize-none text-[16px] focus:outline-none focus:border-app-gray-400"
            />

            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

            <div className="space-y-4">
              <h2 className="text-[16px] font-medium">Suggestions for you</h2>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 cursor-pointer"
                  // Do not use "handleChangeDescription" here, because we dont want to trigger the debounce here.
                  onClick={() => setDescription(suggestion)}
                >
                  {isSuggestionsLoading ? (
                    <Sparkles className="w-4 h-4 animate-pulse [filter:drop-shadow(0_0_10px_rgba(124,58,237,0.5))] text-purple-500" />
                  ) : (
                    <Sparkles className="w-4 h-4" />
                  )}
                  <span className="text-[14px] text-app-gray-600">
                    {suggestion}
                  </span>
                </div>
              ))}
            </div>
            {generatedQuestions.length > 0 ? (
              <div className="flex justify-start gap-4">
                <Button
                  className="mt-20 px-14"
                  size="lg"
                  endIcon={<ArrowRightIcon size={20} />}
                  onClick={handleSubmit}
                  disabled={isGenerating}
                >
                  Continue
                </Button>
                <Button
                  className="mt-20 border-app-black"
                  size="lg"
                  endIcon={<RefreshCcw size={20} />}
                  variant="outline"
                  onClick={handleGenerate}
                  disabled={isGenerating}
                >
                  Regenerate
                </Button>
              </div>
            ) : (
              <Button
                className="mt-20"
                size="lg"
                endIcon={<SparklesIcon size={20} className="text-app-indigo-50 font-bold"/>}
                onClick={handleGenerate}
                disabled={isGenerating}
              >
                Generate With AI
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Right Section */}
      {isGenerating ? (
        <div
          style={{ zIndex: 100 }}
          className="w-full h-screen md:w-1/2 bg-app-blue-100 flex items-center justify-center z-10 overflow-hidden"
        >
          <div className="flex flex-col items-center justify-center space-y-3 h-[61vh]">
            <Sparkles className="w-12 h-12 animate-pulse [filter:drop-shadow(0_0_10px_rgba(124,58,237,0.5))] text-purple-500" />
            <span className="text-mg text-gray-700 animate-pulse">
              Generating...
            </span>
          </div>
        </div>
      ) : (
        <>
          {generatedQuestions.length > 0 ? (
            <div
              style={{ zIndex: 100 }}
              className="w-1/2 bg-app-blue-100 flex items-center justify-center z-10 h-screen overflow-hidden"
            >
              <div className="w-full px-36 pt-20 h-full py-8 overflow-y-auto">
                <div className="space-y-4">
                  {generatedQuestions.map((question, index) => {
                    const iconObject = inputTypes[question.type];
                    const Icon = iconObject.icon;
                    return (
                      <div
                        key={index}
                        className="bg-white p-8 rounded-3xl shadow-sm"
                      >
                        <div className="flex items-center gap-2 mb-2">
                          <div
                            className={`w-8 h-8 ${iconObject.bgColor} rounded-lg flex items-center justify-center`}
                          >
                            <Icon
                              className={`w-4 h-4 ${iconObject.iconColor}`}
                            />
                          </div>
                          <p className="text-app-black text-[14px]">
                            {iconObject.label}
                          </p>
                        </div>

                        <p className="text-app-black text-[22px] font-medium mb-4">
                          {question.text}
                        </p>

                        {question.options && (
                          <div className="space-y-2 ml-4">
                            {question.options.map((option, idx) => (
                              <div
                                key={idx}
                                className="flex items-center gap-1"
                              >
                                <DotIcon />
                                <span className="text-app-black text-[18px]">
                                  {option}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ zIndex: 100 }}
              // className="w-1/2 bg-app-yellow-100 flex items-center justify-center z-10"
              className="w-1/2 bg-app-yellow-100 z-10 h-screen relative overflow-hidden"
            >
              <img
                src="/images/describe-full.png"
                alt="Yoga"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              {/* <div className="w-[78%] h-[45%] bg-app-white  relative rounded-3xl  flex">
                <div className="w-[50%] h-[100%] relative overflow-hidden rounded-l-3xl">
                  <img
                    src="/images/twohand.jpeg"
                    alt="hands"
                    className="h-[150%] absolute -top-[40%]  object-cover"
                  />
                </div>
                <div
                  id="skeleton"
                  className="w-[50%] h-[100%] flex flex-col items-center gap-20 pt-20 justify-start overflow-hidden"
                >
                  <div className="w-[70%] space-y-4">
                    <div className="h-4 bg-app-pink-100 rounded-lg w-[100%]"></div>
                    <div className="h-4 bg-app-pink-100 rounded-lg w-[75%]"></div>
                  </div>
                  <div className="w-[70%] space-y-14">
                    <div className="h-2 bg-app-pink-10 rounded-lg w-[75%]"></div>
                    <div className="h-2 bg-app-pink-10 rounded-lg w-[75%]"></div>
                    <div className="h-2 bg-app-pink-10 rounded-lg w-[75%]"></div>
                    <div className="h-2 bg-app-pink-10 rounded-lg w-[75%]"></div>
                  </div>
                </div>
                <div className="absolute w-[20%] -top-[20%] left-[8%]">
                  <div className="relative w-full">
                    <img
                      src="/images/avatar1.jpg"
                      alt="Avatar"
                      className="w-full rounded-full border-4 border-app-yellow-100 object-cover"
                    />
                    <div className="absolute right-1 top-1 bg-app-green-500 rounded-full p-1 border-4 border-app-yellow-100">
                      <CheckIcon className="text-white" size={24} />
                    </div>
                  </div>
                </div>
                <div className="absolute w-[20%] -bottom-[20%] right-[8%]">
                  <div className="relative w-full">
                    <img
                      src="/images/avatar3.jpg"
                      alt="Avatar"
                      className="w-full rounded-full border-4 border-app-yellow-100 object-cover"
                    />
                    <div className="absolute right-1 top-1 bg-app-green-500 rounded-full p-1 border-4 border-app-yellow-100">
                      <CheckIcon className="text-white" size={24} />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DescribeProject;
