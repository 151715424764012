import React from "react";
const ResponseLimitModal = ({
  isOpen,
  onClose,
  subscription,
  setIsProfileSettingsOpen,
}) => {
  if (!isOpen) return null;

  const getPlanLimit = () => {
    if (!subscription) return "2";

    const { limit, name } = subscription;

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        return `${limit.toLocaleString()} per survey`;
      case "Growth Plan":
        return "unlimited";
      case "Free Trial":
      default:
        return limit.toLocaleString();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4">
        <div className="text-center mb-8">
          <h3 className="text-3xl font-medium mb-4 font-['HK_Nova']">
            Response Limit Reached
          </h3>
          <p className="text-gray-600 mb-4">
            You have reached the maximum response limit for your current plan (
            {getPlanLimit()} responses).
          </p>
          <p className="text-gray-600">
            To continue collecting responses, please upgrade your plan.
          </p>
        </div>
        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="w-1/2 h-14 text-lg rounded-xl border border-gray-200 hover:bg-gray-50 transition-all font-medium"
          >
            Close
          </button>
          <button
            onClick={() => {
              onClose();
              setIsProfileSettingsOpen(true);
            }}
            className="w-1/2 h-14 text-lg rounded-xl bg-black text-white hover:bg-black/90 transition-all font-medium"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseLimitModal;
