import React from 'react';
import { Dialog, DialogPanel, DialogTitle, Description, DialogBackdrop, Transition } from '@headlessui/react'
import { X } from 'lucide-react'
import { Fragment } from 'react';

export function ModalContainer({
    isOpen,
    onClose,
    children,
    title,
    description,
    headerLeft,
    headerRight,
    size = 'md', // sm, md, lg, xl, full
    showClose = true,
    hideHeaderBorder = false,
    className = ''
}) {
    const sizeClasses = {
        sm: 'max-w-md',
        md: 'max-w-lg',
        lg: 'max-w-2xl',
        xl: 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '7xl': 'max-w-7xl',
        full: 'max-w-[95%]'
    }

    return (
        <Dialog
            as="div"
            transition
            className="relative max-w- z-50 transition duration-300 ease-out data-[closed]:opacity-0"
            open={isOpen}
            onClose={onClose}
        >
            {/* Background overlay */}
            <DialogBackdrop className="fixed inset-0 bg-black/30" />
            {/* Modal position */}
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">

                    {/* Modal panel */}
                    <DialogPanel
                        className={`w-full ${sizeClasses[size]} transform overflow-hidden rounded-xl 
                        bg-white p-4 text-left align-middle shadow-xl transition-all ${className}`}
                    >
                        {/* Header */}
                        {(headerLeft || title || showClose || headerRight) && (
                            <div className={`flex justify-between items-start ${hideHeaderBorder ? '' : 'border-b border-gray-100 mb-4 pb-2'}`}>
                                {/* Left side */}
                                {headerLeft || (
                                    <div>
                                        {title && (
                                            <DialogTitle
                                                as="h3"
                                                className="text-lg font-bold leading-6 text-app-black"
                                            >
                                                {title}
                                            </DialogTitle>
                                        )}
                                        {description && (
                                            <Description className="mt-1 text-sm text-gray-500">
                                                {description}
                                            </Description>
                                        )}
                                    </div>
                                )}

                                {/* Right side */}
                                <div className="flex items-center gap-4">
                                    {headerRight}
                                    {showClose && (
                                        <button
                                            onClick={onClose}
                                            className="text-app-gray-400 hover:text-app-gray-500 focus:outline-none"
                                        >
                                            <X className="h-6 w-6" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* flex-1 overflow-hidden */}
                        {/* Content */}
                        <div className="mt-2 flex-1 overflow-hidden">
                            {children}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export function ModalContainerFull({
    isOpen,
    onClose,
    children,
    showClose = true,
    className = '',
    size = 'lg'
}) {
    const sizeClasses = {
        sm: 'max-w-md',
        md: 'max-w-lg',
        lg: 'max-w-2xl',
        xl: 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '7xl': 'max-w-7xl',
        full: 'max-w-[95%]'
    }

    return (
        <Dialog
            as="div"
            transition
            className="relative z-50 transition duration-300 ease-out data-[closed]:opacity-0"
            open={isOpen}
            onClose={onClose}
        >
            <DialogBackdrop className="fixed inset-0 bg-black/30" />

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        className={`w-full h-full ${sizeClasses[size]} transform overflow-hidden rounded-xl 
                        bg-white text-left align-middle shadow-xl transition-all ${className}`}
                    >
                        {showClose && (
                            <button
                                onClick={onClose}
                                className="absolute z-40 top-4 right-4 p-1.5 bg-app-white rounded-full text-app-gray-400 hover:text-app-gray-500 focus:outline-none"
                            >
                                <X className="h-6 w-6" />
                            </button>
                        )}
                        {children}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
} 