import React from "react";
import inputTypes from "../../assets/inputTypes";

const SelectElementForm = ({ handleAddQuestion }) => {
  return (
    <div className="space-y-4 p-6 pt-0">
      <div
        id="main-form"
        className="p-8 bg-white rounded-2xl max-h-[80vh] overflow-y-auto"
      >
        {Object.keys(inputTypes).map((type) => {
          const iconObject = inputTypes[type];
          const Icon = iconObject.icon;
          return (
            <button
              key={type}
              className="w-full p-4 flex items-center space-x-4 mb-2 hover:bg-gray-50 rounded-lg transition-colors border border-gray-100"
              onClick={() => handleAddQuestion(type)}
            >
              <div
                className={`w-10 h-10 ${iconObject.bgColor} rounded-lg flex items-center justify-center`}
              >
                <Icon className={`w-5 h-5 ${iconObject.iconColor}`} />
              </div>
              <div className="flex-1 text-left">
                <h3 className="font-medium">{iconObject.label}</h3>
                <p className="text-sm text-gray-500">
                  {iconObject.description}
                </p>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SelectElementForm;
