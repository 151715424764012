import React from "react";
const ResponsesCounter = ({ used, subscription }) => {
  const limit = subscription?.limit;
  const isUnlimited = limit === null || limit === undefined;

  // Calculate the percentage for the progress bar
  const percentage = isUnlimited ? 0 : Math.min((used / limit) * 100, 100);

  // Determine if we're approaching the limit (e.g., 80% or more)
  const isApproachingLimit = percentage >= 80;

  return (
    <div className="px-4 py-2 bg-app-yellow-100 rounded-lg">
      <div className="flex flex-col gap-1">
        <span className="text-sm font-sm text-app-black-700">
          Responses collected
        </span>
        <div className="flex items-baseline">
          <span className="text-2xl font-bold text-app-black-900">{used}</span>
          <span className="text-sm font-medium text-app-gray-700 ml-1">
            / {limit || "∞"}
          </span>
        </div>
      </div>
      {!isUnlimited && (
        <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full rounded-full transition-all duration-300 ${
              used >= limit
                ? "bg-red-500"
                : isApproachingLimit
                ? "bg-yellow-500"
                : "bg-black"
            }`}
            style={{ width: `${percentage}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default ResponsesCounter;
