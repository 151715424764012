import React from 'react';
import { User, CreditCard, Bell, Image } from 'lucide-react';

const tabs = [
  { id: 'profile', label: 'Profile', icon: User },
  { id: 'billing', label: 'Plans and Billing', icon: CreditCard },
  { id: 'notifications', label: 'Notifications', icon: Bell },
  { id: 'media', label: 'Media Library', icon: Image },
];

const TabNavigation = ({ activeTab, onTabChange }) => {
  return (
    <nav className="p-4 space-y-1">
      {tabs.map((tab) => {
        const Icon = tab.icon;
        return (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors text-left ${activeTab === tab.id
                ? 'bg-app-gray-100 text-app-black-800'
                : 'text-app-gray-600 hover:bg-gray-100'
              }`}
          >
            <Icon size={20} />
            <span className="font-medium">{tab.label}</span>
          </button>
        );
      })}
    </nav>
  );
};

export default TabNavigation; 