import React, { useState } from "react";
import Button from "../Button/Button";
import { CheckIcon } from "lucide-react";

const ShareSurveyModal = ({
  surveyId,
  surveyLink,
  handleCopyLink,
  onClose,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isEmbedCopied, setIsEmbedCopied] = useState(false);
  const handleCopy = () => {
    setIsCopied(true);
    handleCopyLink();
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleEmbedCopy = () => {
    setIsEmbedCopied(true);
    navigator.clipboard.writeText(
      '<div data-tf-live="01JGKX6RA0G0ACFYBZP2BF77A0"></div><script src="//pollbolt.com/embed.js"></script>'
    );
    setTimeout(() => {
      setIsEmbedCopied(false);
    }, 3000);
  };
  return (
    <div className="px-16 mb-12">
      <label className="block text-[16px] text-black mb-2 mt-4">
        Form Link
      </label>
      <div className="flex w-full items-center justify-between p-2 pl-3 gap-2 mb-8 border rounded-lg">
        <div className="truncate w-[70%] text-[16px]">{surveyLink}</div>
        <Button
          variant={isCopied ? "secondary" : "default"}
          // size="sm"
          onClick={handleCopy}
          disabled={isCopied}
        >
          {isCopied ? <CheckIcon className="w-4 h-4" /> : "Copy link"}
        </Button>
      </div>

      {/* Share Options */}
      <div>
        <label className="block text-[16px] text-black mb-2 mt-4">
          Share on social media
        </label>
        <div className="flex gap-3">
          {[
            { icon: "fb", name: "Facebook" },
            { icon: "in", name: "Instagram" },
            { icon: "x", name: "X" },
            { icon: "li", name: "LinkedIn" },
          ].map((social) => (
            <button
              key={social.icon}
              className="p-3 rounded-lg border border-app-gray-200 hover:bg-gray-50"
            >
              <img
                src={`/images/${social.icon}.svg`}
                alt={social.name}
                className="w-8 h-8"
              />
            </button>
          ))}
        </div>
      </div>

      {/* Embed */}
      <div>
        <label className="block text-[16px] text-black mb-2 mt-8">
          Embed in a web page
        </label>
        <div className="w-full bg-app-gray-70 rounded-lg relative">
          <div className="text-sm text-app-gray-700 break-all p-4 py-6 w-[80%]">
            &lt;div
            data-tf-live="01JGKX6RA0G0ACFYBZP2BF77A0"&gt;&lt;/div&gt;&lt;script
            src="//pollbolt.com/embed.js"&gt;&lt;/script&gt;
          </div>
          <Button
            variant="ghost"
            className="absolute right-3 top-3 p-3 rounded-lg bg-white hover:bg-gray-50"
            onClick={handleEmbedCopy}
          >
            {isEmbedCopied ? (
              <CheckIcon className="w-4 h-4" />
            ) : (
              <img src={`/images/copy.svg`} alt="copy" className="w-4 h-4" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShareSurveyModal;
