import React, { useState } from "react";
import { PencilLine, FileInput, Sparkles, Loader } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { checkSurveyCreationEligibiliy, createSurvey } from "./serverComm";

const NewSurvey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const workspaceId = new URLSearchParams(location.search).get("workspaceId") || null
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async (tab) => {
    const isEligible = await checkEligibility();
    if (!isEligible) {
      return;
    }
    const response = await createSurvey({ workspaceId: workspaceId });
    navigate(`/survey/${response.surveyId}?option=${tab}`);
  };

  const checkEligibility = async () => {
    setLoading(true);
    const response = await checkSurveyCreationEligibiliy();
    if (!response.status) {
      toast(response.message, {
        icon: "⚠️",
        style: {
          border: "1px solid #FFA500",
          padding: "10px",
          color: "#FFA500",
        },
      });
      setLoading(false);
      return false;
    }
    return true;
  };

  return (
    <div className="w-full h-[100vh] bg-app-gray-50 p-4 pt-16">
      <div className="w-full h-full rounded-3xl mx-auto  bg-white py-24 sm:py-24 px-4 sm:px-0">
        <div className="mx-auto">
          {/* Header */}
          <h1 className="text-xl font-['HK_Nova'] font-medium leading-tight tracking-[-0.93px] text-black mb-8 text-center">
            How do you want to build your form?
          </h1>

          {/* Options Grid */}
          <div className="flex gap-8 justify-center">
            {/* Start from Scratch */}
            <button
              onClick={() => handleButtonClick("select")}
              className="group w-[340px] h-[250px] flex flex-col justify-center items-center p-8 bg-white border border-[#e4e4e4] rounded-[15px] transition-all duration-200 hover:shadow-lg"
            >
              <div className="w-20 h-20 mb-4 flex items-center justify-center bg-[#FFFBBA] rounded-2xl">
                <PencilLine className="w-6 h-6 text-[#2F2F2F]" />
              </div>
              <h1 className="text-md font-['HK_Nova'] text-black mb-2">
                Start From Scratch
              </h1>
            </button>

            {/* Import Questions */}
            <button
              onClick={() => handleButtonClick("import")}
              className="group w-[340px] h-[250px] flex flex-col justify-center items-center p-8 bg-white border border-[#e4e4e4] rounded-[15px] transition-all duration-200 hover:shadow-lg"
            >
              <div className="w-20 h-20 mb-4 flex items-center justify-center bg-[#D3FBF1] rounded-2xl">
                <FileInput className="w-6 h-6 text-[#2F2F2F]" />
              </div>
              <h3 className="text-md font-['HK_Nova'] text-black mb-2">
                Import Questions
              </h3>
            </button>

            {/* Create with AI */}
            <button
              onClick={() => handleButtonClick("ai")}
              className="group w-[340px] h-[250px] flex flex-col justify-center items-center p-8 bg-white border border-[#e4e4e4] rounded-[15px] transition-all duration-200 hover:shadow-lg"
            >
              <div className="w-20 h-20 mb-4 flex items-center justify-center bg-[#DCE1FF] rounded-2xl">
                <Sparkles className="w-6 h-6 text-[#2F2F2F]" />
              </div>
              <h3 className="text-md font-['HK_Nova'] text-black mb-2">
                Create With AI
              </h3>
            </button>
          </div>
        </div>
        {loading && (
          <div className="flex items-center justify-center w-full h-full mt-24">
            <Loader className="w-6 h-6 animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSurvey;
