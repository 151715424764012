import { getAuthInstance } from "./firebase";
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";
import {
  getStorageInstance,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from "./firebase";

const getApiBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3001";
  }
  return "https://surv-server.vercel.app";
};

const API_BASE_URL = getApiBaseUrl();

const auth = getAuthInstance();

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

const getAuthToken = async () => {
  let user = await getCurrentUser();

  if (!user) {
    await signInAnonymously(auth);
    user = await getCurrentUser();
  }

  try {
    return await user.getIdToken();
  } catch (error) {
    console.error("Error getting auth token:", error);
    throw new Error("Failed to get authentication token");
  }
};

const getAuthHeader = async () => {
  const token = await getAuthToken();
  return { Authorization: `Bearer ${token}` };
};

const apiCall = async (method, endpoint, data = null) => {
  try {
    const headers = await getAuthHeader();
    headers["Content-Type"] = "application/json";

    const apiEndpoint = endpoint.startsWith("/api/")
      ? endpoint
      : `/api${endpoint}`;
    const url = `${API_BASE_URL}${apiEndpoint}`;

    const options = {
      method,
      headers,
      mode: "cors",
      credentials:
        process.env.NODE_ENV === "development" ? "include" : "same-origin",
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", {
        status: response.status,
        text: errorText,
        headers: Object.fromEntries(response.headers.entries()),
      });
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const get = (endpoint) => apiCall("GET", endpoint);
export const post = (endpoint, data) => apiCall("POST", endpoint, data);
export const del = (endpoint) => apiCall("DELETE", endpoint);

export const checkSurveyCreationEligibiliy = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/createSurvey-eligibility`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking eligibility:", error);
    throw error;
  }
};

export const createSurvey = async ({ workspaceId = null }) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/createSurvey`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: "Untitled Survey",
        questions: [],
        styles: {
          fontFamily: "Inter",
          submitButtonColor: "#4F46E5",
          backgroundColor: "#FFFFFF",
          useCustomSubmitText: false,
          customSubmitText: "",
        },
        workspaceId,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating survey:", error);
    throw error;
  }
};

export const createOnboardingSurvey = async ({ questions, styles }) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/createSurvey`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: "Untitled Survey",
        questions: questions || [],
        styles: styles || {
          fontFamily: "Inter",
          submitButtonColor: "#4F46E5",
          backgroundColor: "#FFFFFF",
          useCustomSubmitText: false,
          customSubmitText: "",
        },
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating survey:", error);
    throw error;
  }
};

export const saveSurvey = async (surveyData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const surveyId = surveyData.surveyId || surveyData._id;
    if (!surveyId) {
      throw new Error("No valid surveyId found in survey data");
    }

    // Ensure complete styles object with backgroundAlignment
    const styles = {
      ...surveyData.styles,
      fontFamily: surveyData.styles?.fontFamily || "Inter",
      submitButtonColor: surveyData.styles?.submitButtonColor || "#4F46E5",
      backgroundColor: surveyData.styles?.backgroundColor || "#FFFFFF",
      questionTextColor: surveyData.styles?.questionTextColor || "#000000",
      optionTextColor: surveyData.styles?.optionTextColor || "#4B5563",
      useCustomSubmitText: surveyData.styles?.useCustomSubmitText || false,
      customSubmitText: surveyData.styles?.customSubmitText || "",
      backgroundImage: surveyData.styles?.backgroundImage || "",
      backgroundAlignment: surveyData.styles?.backgroundAlignment || "cover",
      logo: {
        url: surveyData.styles?.logo?.url || "",
        size: surveyData.styles?.logo?.size || "md",
        alignment: surveyData.styles?.logo?.alignment || "left",
      },
    };

    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId,
        title: surveyData.title,
        rules: surveyData?.rules,
        questions: surveyData.questions,
        styles,
        endingScreens: surveyData.endingScreens || [],
        welcomeScreens: surveyData.welcomeScreens || [],
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error saving survey:", error);
    throw error;
  }
};

export const generateAiSuggestions = async (surveyData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/ai/generate-ai-suggestion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          description: surveyData.description,
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error generating ai suggestions:", error);
    throw error;
  }
};

export const generateAiQuestions = async (surveyData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/ai/generate-ai-questions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          description: surveyData.description,
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error generating ai questions:", error);
    throw error;
  }
};

export const importAiQuestions = async (surveyData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/ai/import-ai-questions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        description: surveyData.description,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error importing questions:", error);
    throw error;
  }
};

export const getSurveys = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) {
      console.warn("No authenticated user found");
      return [];
    }

    const token = await user.getIdToken();
    if (!token) {
      throw new Error("Failed to get authentication token");
    }

    const apiEndpoint = "/api/surveys";
    const url = `${API_BASE_URL}${apiEndpoint}`;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const options = {
      method: "GET",
      headers,
      mode: "cors",
      credentials:
        process.env.NODE_ENV === "development" ? "include" : "same-origin",
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response error:", {
        status: response.status,
        text: errorText,
        headers: Object.fromEntries(response.headers.entries()),
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error("Error fetching surveys:", error);
    throw error;
  }
};

export const getSurvey = async (surveyId) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching survey:", error);
    throw error;
  }
};
export const getAllWorkspaces = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");
    const response = await fetch(`${API_BASE_URL}/api/workspaces`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch survey");
    }
    const data = await response.json();

    // Ensure styles object exists and backgroundImage is never nulls

    return data;
  } catch (error) {
    console.error("Error in fetchSurvey:", error);
    throw error;
  }
};
export const createWorkspace = async (name = "") => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");
    const response = await fetch(`${API_BASE_URL}/api/workspaces`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`, // Set the Authorization header with the token
        "Content-Type": "application/json", // Set the content type
      },
      body: JSON.stringify({ name }), // Pass the name as payload
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const workspace = await response.json();
    return workspace;
  } catch (error) {
    console.error("Failed to create workspace:", error);
    throw error;
  }
};
export const editWorkspace = async (name = "", id) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");
    const response = await fetch(`${API_BASE_URL}/api/workspaces/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`, // Set the Authorization header with the token
        "Content-Type": "application/json", // Set the content type
      },
      body: JSON.stringify({ name, workspaceId: id }), // Pass the name as payload
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const workspace = await response.json();
    return workspace;
  } catch (error) {
    console.error("Failed to create workspace:", error);
    throw error;
  }
};

export const moveSurvey = async (workspaceId, surveyId, sourceWorkspaceId) => {
  try {
    const token = await getAuthToken(); // Get the authentication token

    const response = await fetch(
      `${API_BASE_URL}/api/workspaces/${workspaceId}/moveSurvey`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ surveyId, sourceWorkspaceId }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Move survey response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to move survey: ${response.status}`);
    }

    return await response.json(); // Return the updated workspace data
  } catch (error) {
    console.error("Error moving survey:", error);
    throw error;
  }
};

export const getSurveyByWorkSpace = async (workspaceId) => {
  try {
    const token = await getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/api/workspaces/${workspaceId}/surveys`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const errorText = await response.text();
      console.error("delet workspace response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to delete workspace: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error while delete workspace:", error);
    throw error;
  }
};

export const deleteWorkspace = async (workspaceId) => {
  try {
    const token = await getAuthToken(); // Get the authentication token

    const response = await fetch(
      `${API_BASE_URL}/api/workspaces/${workspaceId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }, // Pass the surveyId in the request body
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("delet workspace response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to delete workspace: ${response.status}`);
    }

    return await response.json(); // Return the updated workspace data
  } catch (error) {
    console.error("Error while delete workspace:", error);
    throw error;
  }
};

export const checkUserResponse = (surveyId) =>
  get(`/api/checkResponse/${surveyId}`);
export const submitSurveyResponse = (surveyId, answers) =>
  post("/api/submitResponse", { surveyId, answers });

export const getSurveyWithResponses = async (surveyId, filters = {}) => {
  try {
    const { searchText, dateRange, activeFilters, paginate, page, limit } =
      filters;

    // Build query params
    const params = new URLSearchParams();

    // Add search text if present
    if (searchText) {
      params.append("searchText", searchText);
    }

    // Add date range if present
    if (dateRange?.start) {
      params.append("startDate", dateRange.start.format("YYYY-MM-DD"));
    }
    if (dateRange?.end) {
      params.append("endDate", dateRange.end.format("YYYY-MM-DD"));
    }

    // Add any active filters
    if (activeFilters?.length > 0) {
      params.append("filters", JSON.stringify(activeFilters));
    }

    if (paginate) {
      params.append("paginate", "true");
    }

    if (page) {
      params.append("page", page);
    }

    if (limit) {
      params.append("limit", limit);
    }

    // Build URL with query params
    const queryString = params.toString();
    return get(
      `/api/surveys/${surveyId}/with-responses${
        queryString ? `?${queryString}` : ""
      }`
    );
  } catch (error) {
    console.error("Error in getSurveyWithResponses:", error);
    throw error;
  }
};
export const deleteSurvey = async (surveyId) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Delete response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to delete survey: ${response.status}`);
    }

    return { success: true };
  } catch (error) {
    console.error("Error in deleteSurvey:", error);
    throw error;
  }
};

// Aliases for consistency with existing code
export const fetchSurvey = async (surveyId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/surveys/${surveyId}/public`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch survey");
    }
    const data = await response.json();

    // Ensure styles object exists and backgroundImage is never null
    if (data.styles) {
      data.styles.backgroundImage = data.styles.backgroundImage || "";
    } else {
      data.styles = {
        ...data.styles,
        backgroundImage: "",
      };
    }

    return data;
  } catch (error) {
    console.error("Error in fetchSurvey:", error);
    throw error;
  }
};

export const checkResponse = async (surveyId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/checkResponse/${surveyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error checking response:", error);
    return { hasResponded: false };
  }
};

export const submitResponse = async (data) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/submitResponse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error submitting response:", error);
    throw error;
  }
};

export const deleteResponses = async (responseIds) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/deleteResponses`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ responseIds }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Delete response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to delete response ${response.status}`);
    }

    return { success: true };
  } catch (error) {
    console.error("Error in deleteResponses:", error);
    throw error;
  }
};

export const duplicateSurvey = async (surveyId) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/duplicateSurvey/${surveyId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Duplicate response error:", {
        status: response.status,
        text: errorText,
      });
      throw new Error(`Failed to duplicate survey: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in duplicateSurvey:", error);
    throw error;
  }
};

export const updateSurveyLogo = async (surveyId, logoData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    // First get the current survey data
    const currentSurvey = await getSurvey(surveyId);

    // Merge the new logo data with existing styles
    const updatedStyles = {
      ...currentSurvey.styles,
      logo: {
        url: logoData.url,
        size: logoData.size,
        alignment: logoData.alignment,
      },
    };

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId: surveyId,
        title: currentSurvey.title,
        questions: currentSurvey.questions,
        styles: updatedStyles,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating survey logo:", error);
    throw error;
  }
};

export const uploadMediaFile = async (file) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const storage = getStorageInstance();

    // Create file reference with timestamp
    const timestamp = Date.now();
    const fileName = `logos/${user.uid}/${timestamp}_${file.name}`;
    const storageRef = ref(storage, fileName);

    // Upload file
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);

    return {
      fileId: fileName,
      name: file.name,
      url: url,
      type: "image",
      createdAt: new Date(timestamp).toISOString(),
      isLogo: true,
    };
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};

export const getMediaFiles = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const storage = getStorageInstance();
    const mediaRef = ref(storage, `logos/${user.uid}`);

    try {
      const mediaList = await listAll(mediaRef);
      const validFiles = await Promise.all(
        mediaList.items.map(async (item) => {
          try {
            const url = await getDownloadURL(item);

            // Verify the file is accessible
            try {
              const response = await fetch(url, { method: "HEAD" });
              if (!response.ok) {
                // File not accessible, try to delete it
                try {
                  await deleteObject(item);
                } catch (deleteError) {}
                return null;
              }

              return {
                fileId: item.name,
                name: item.name.split("_").slice(1).join("_"),
                url: url,
                fullPath: item.fullPath,
                type: "image",
                createdAt: new Date(
                  parseInt(item.name.split("_")[0])
                ).toISOString(),
                isUploadedFile: true,
              };
            } catch (fetchError) {
              // File not accessible, try to delete it
              try {
                await deleteObject(item);
              } catch (deleteError) {}
              return null;
            }
          } catch (error) {
            // If we can't get URL, file probably doesn't exist
            try {
              await deleteObject(item);
            } catch (deleteError) {
              console.error("Could not delete reference:", deleteError);
            }
            return null;
          }
        })
      );

      // Filter out null values (deleted/inaccessible files)
      return validFiles.filter((file) => file !== null);
    } catch (error) {
      console.error("Error listing files:", error);
      return [];
    }
  } catch (error) {
    console.error("Error fetching media:", error);
    throw error;
  }
};

export const deleteMediaFile = async (fileId) => {
  try {
    const auth = getAuthInstance();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    const storage = getStorageInstance();

    // Get the correct file path
    // If fileId is the full path (logos/userId/filename), use it directly
    // Otherwise, construct the path
    const filePath = fileId.startsWith("logos/")
      ? fileId
      : `logos/${user.uid}/${fileId}`;

    const fileRef = ref(storage, filePath);

    try {
      await deleteObject(fileRef);
      return true;
    } catch (storageError) {
      console.error("Storage delete error details:", storageError);

      // Check if file exists before trying to delete
      try {
        await getDownloadURL(fileRef);
        // If we get here, file exists but we can't delete it
        throw new Error("Permission denied to delete file");
      } catch (urlError) {
        // If file doesn't exist, consider it successfully deleted
        if (urlError.code === "storage/object-not-found") {
          return true;
        }
        // Otherwise, propagate the original error
        throw storageError;
      }
    }
  } catch (error) {
    console.error("Error deleting media file:", error);
    throw error;
  }
};

export const getTemplates = async () => {
  try {
    const response = await get("/api/templates");
    return response;
  } catch (error) {
    console.error("Error fetching templates:", error);
    throw error;
  }
};

export const createSurveyFromTemplate = async (templateId) => {
  try {
    const response = await post("/api/templates/create-survey", { templateId });
    return response;
  } catch (error) {
    console.error("Error creating survey from template:", error);
    throw error;
  }
};

// Add this function near the top for testing
export const testCreateAndFetchSurvey = async () => {
  try {
    const newSurvey = await createSurvey();

    const surveys = await getSurveys();

    return { newSurvey, surveys };
  } catch (error) {
    console.error("Test failed:", error);
    throw error;
  }
};

export const recordSurveyView = async (surveyId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/surveys/${surveyId}/view`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error recording survey view:", error);
    return { success: false };
  }
};

export const updateSurveyBackground = async (surveyId, backgroundData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    // First get the current survey data
    const currentSurvey = await getSurvey(surveyId);

    // Merge the new background data with existing styles
    const updatedStyles = {
      ...currentSurvey.styles,
      backgroundImage: backgroundData.url,
    };

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId: surveyId,
        title: currentSurvey.title,
        questions: currentSurvey.questions,
        styles: updatedStyles,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating survey background:", error);
    throw error;
  }
};

// Add this new function to get total responses count across all workspaces
export const getWorkspacesResponsesCount = async () => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/workspaces/responses/count`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAuthToken()}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch responses count");
    }

    const data = await response.json();
    return data.totalResponses;
  } catch (error) {
    console.error("Error fetching workspace responses count:", error);
    throw error;
  }
};

// onboarding
export const createOnboarding = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/onboarding`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating onboarding:", error);
  }
};
export const getOnboarding = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/onboarding`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    const onboardingObject = data.onboarding || { isCompleted: true };
    sessionStorage.setItem("onboarding", JSON.stringify(onboardingObject));
    return onboardingObject;
  } catch (error) {
    console.error("Error getting onboarding:", error);
  }
};
export const updateOnboarding = async (
  currentStep,
  isCompleted = false,
  surveyId
) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/onboarding`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ currentStep, isCompleted, surveyId }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating onboarding:", error);
  }
};

export const renameSurvey = async ({ surveyId, newTitle }) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error("No authenticated user");

    const token = await user.getIdToken();

    if (!surveyId || !newTitle) {
      throw new Error("No valid surveyId or Title found in survey data");
    }

    const response = await fetch(`${API_BASE_URL}/api/survey/rename`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ surveyId, newTitle }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error renaming survey:", error);
    throw error;
  }
};

export const getSurveyAnalytics = async (surveyId) => {
  try {
    return get(`/api/survey/analytics/${surveyId}`);
  } catch (error) {
    console.error("Error in getSurveyWithResponses:", error);
    throw error;
  }
};
