import { useState, useEffect } from 'react';

export function DelayedMessage({ show, message, delay = 1000 }) {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        let timeoutId;
        
        if (show) {
            timeoutId = setTimeout(() => {
                setShowMessage(true);
            }, delay);
        } else {
            setShowMessage(false);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [show, delay]);

    if (!showMessage) {
        return null;
    }

    return (
        <div className="text-center text-gray-500 mt-4">
            {message}
        </div>
    );
} 