import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const hasReachedResponseLimit = (survey, subscription, surveys = []) => {
  if (!subscription) return false;

  const { limit, name } = subscription;

  // If no response limit is set (unlimited)
  if (limit === null || limit === undefined) return false;

  const surveyResponses = survey.responses?.length || 0;
  const totalResponses = surveys?.reduce(
    (sum, s) => sum + (s.responses?.length || 0),
    0
  );

  // Adjust the logic based on your subscription names
  switch (name) {
    case "Pro Plan":
      // Pro plan: Check per-survey limit
      return surveyResponses >= limit;
    case "Growth Plan":
      // Growth plan: Unlimited responses
      return false;
    case "Free Trial":
    default:
      // Free plan: Check total responses across all surveys
      return totalResponses >= limit;
  }
};

export const capitalizeFirstCharacter = (str) => {
  if (!str) return str; // Check for empty string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// cn (className) utility combines clsx and tailwind-merge
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Process feature text without memo
export const processFeatureText = (feature, limits) => {
  if (!limits) return feature;
  return feature.replace(/\${(\w+)}/g, (match, key) => {
    if (limits[key] === null) return 'Unlimited';
    if (key === 'responses' && limits[key]) {
      return limits[key].toLocaleString();
    }
    return limits[key]?.toString() || match;
  });
};