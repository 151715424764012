import React, {
  createContext,
  useState,
} from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SurveyDesigner from "./SurveyDesigner";
import SurveyList from "./SurveyList";
import NavBar from "./NavBar";
import SignIn from "./SignIn";
import LandingPage from "./LandingPage";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadingAnimation from "./components/LoadingAnimation";
import { getAuthInstance } from "./firebase";               
import ProfileSettings from "./pages/ProfileSettings";

export const UserContext = createContext(null);

function App() {
  const auth = getAuthInstance();
  const [user, loading, error] = useAuthState(auth);
  const [showSignIn, setShowSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const openSignIn = (signup = false) => {
    setIsSignUp(signup);
    setShowSignIn(true);
  };

  const closeSignIn = () => {
    setShowSignIn(false);
    setIsSignUp(false);
  };

  // Protected route handler
  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isSurveyPath = location.pathname.startsWith("/s/");

    if (!user && !isSurveyPath) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  if (loading) {
    return <LoadingAnimation />;
  }
                      
  return (
    <div className="w-full min-h-screen pt-14">
      <UserContext.Provider value={user}>
        <div className="min-h-screen bg-white">
          {user && !window.location.pathname.startsWith("/s/") && (
            <NavBar openSignIn={openSignIn} />
          )}
          <main className="w-full">
            <Routes>
              <Route
                path="/"
                element={
                  user ? (
                    <Navigate to="/surveys" />
                  ) : (
                    <LandingPage openSignIn={openSignIn} />
                  )
                }
              />
              <Route
                path="/surveys"
                element={
                  <ProtectedRoute>
                    <SurveyList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/survey/:surveyId"
                element={
                  <ProtectedRoute>
                    <SurveyDesigner />
                  </ProtectedRoute>
                }
              />
              {/* ... other routes ... */}
            </Routes>
          </main>
          {showSignIn && <SignIn onClose={closeSignIn} isSignUp={isSignUp} />}
          <ProfileSettings isOpen={false} onClose={() => {}} />
        </div>
      </UserContext.Provider>
    </div>
  );
}

export default App;
