import React, { useContext, useState } from "react";
import Button from "../Button/Button";
// import { useNavigate } from "react-router-dom";
import { updateOnboarding } from "../../serverComm";
import { UserContext } from '../../App';

const OnboardingWelcome = ({ setCurrentStep }) => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  // const navigate = useNavigate();

  const handleGetStarted = async () => {
    setIsLoading(true);
    const updateResponse = await updateOnboarding(1);
    setIsLoading(false);
    if (updateResponse.onboarding) {
      // navigate(`/onboarding?step=${updateResponse.onboarding.currentStep}`);
      setCurrentStep(1)
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center pt-16">
      <div className="flex flex-col items-center text-center">
        <h1 className="text-[43.64px] mb-1">
          Hi {user ? user.displayName : ""}
        </h1>
        <h2 className="text-[43.64px] mb-6">Welcome to PollBolt!</h2>
        <p className="max-w-xl text-[21px] mb-8 text-app-black-800">
          We're thrilled to have you here. PollBolt helps you create powerful,
          AI-powered surveys that turn feedback into actionable insights. Let's
          get you set up.
        </p>
        <Button
          onClick={handleGetStarted}
          size="lg"
          className="px-24 mb-16 rounded-xl"
          isLoading={isLoading}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default OnboardingWelcome;
