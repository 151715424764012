import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';
import { ModalContainerFull } from '../ModalContainer';
import Button from '../Button/Button';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import IntegrationDecorativeSide from './IntegrationDecorativeSide';

const GoogleAnalyticsModal = ({ isOpen, onClose, surveyId }) => {
    const [trackingId, setTrackingId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleConnect = async () => {
        try {
            setIsSubmitting(true);
            const auth = getAuth();
            const currentUser = auth.currentUser;
            const userIdToken = await currentUser.getIdToken();

            const response = await fetch(`/api/integrations/google-analytics/survey/${surveyId}/connect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userIdToken}`
                },
                body: JSON.stringify({
                    trackingId: trackingId.trim()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to connect Google Analytics');
            }

            toast.success('Connected to Google Analytics successfully');
            onClose();
        } catch (error) {
            toast.error(error.message || 'Failed to connect Google Analytics');
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ModalContainerFull
            isOpen={isOpen}
            onClose={onClose}
            showClose={true}
            size="5xl"
            className=""
        >
            <div className="w-full h-full min-h-[38rem] max-h-[90vh] flex overflow-y-auto">
                {/* Left Side */}
                <div className="w-1/2 flex items-center justify-center p-12">
                    <div className="flex flex-col gap-y-16 items-center text-center">
                        {/* Google Analytics Icon */}
                        <div className='flex flex-col justify-center items-center gap-y-4'>
                            <div className="w-16 h-16">
                                <img
                                    src="/images/google-analytics.svg"
                                    alt="Google Analytics"
                                    className="w-full h-full"
                                />
                            </div>

                            <h2 className="text-xl font-semibold text-app-black-800">
                                Connect your form to<br />Google Analytics
                            </h2>
                            <p className="text-sm text-app-gray-500">
                                Customer Interest Form
                            </p>
                        </div>

                        <div className="flex flex-col items-center w-full">
                            <h2 className="text-lg font-medium text-app-black-800 mb-2">
                                Set Up Your Google Analytics Key
                            </h2>
                            <p className="text-sm text-app-gray-500 mb-8">
                                Enter your tracking code
                            </p>

                            <div className="w-full max-w-md mb-8">
                                <input
                                    type="text"
                                    value={trackingId}
                                    onChange={(e) => setTrackingId(e.target.value)}
                                    placeholder="e.g. UA-XXXXX-1 or G-XXXXXXXXXX"
                                    className="w-full px-4 py-2.5 rounded-lg border border-app-gray-200 
                                    text-sm text-app-black placeholder:text-app-gray-400
                                    focus:outline-none focus:border-app-black transition-colors"
                                />
                            </div>

                            <Button
                                variant="outline"
                                size="md"
                                onClick={handleConnect}
                                disabled={!trackingId.trim() || isSubmitting}
                                className="px-12 bg-app-black text-white hover:bg-app-black/90"
                            >
                                {isSubmitting ? 'Connecting...' : 'Connect'}
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Right Side */}
                <IntegrationDecorativeSide 
                    bgColor="bg-app-pink-100"
                    bottomIcon="/images/google-analytics.svg"
                    showGoogleIcon={false}
                />
            </div>
        </ModalContainerFull>
    );
};

export default GoogleAnalyticsModal; 