import { CheckIcon, ArrowRightIcon, ImageIcon } from "lucide-react";
import React, { useState } from "react";
import Button from "../Button/Button";
import { useDropzone } from "react-dropzone";

const MakeItYour = ({ onSubmit }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      // Check file size (2MB limit)
      if (file.size > 2 * 1024 * 1024) {
        alert("File size must be less than 2MB");
        return;
      }
      setUploadedFile(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/svg+xml": [],
    },
    maxFiles: 1,
  });

  // Add this function to create an object URL for the uploaded file
  const getPreviewUrl = () => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile);
    }
    return null;
  };

  const handleSubmit = () => {
    onSubmit(uploadedFile);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/2 h-screen overflow-y-auto flex flex-col">
        <div className="h-10"></div>
        <div className="w-full flex-1 flex items-center">
          <div className="p-24 w-full">
            <h1 className="text-[43.64px] mb-4">Make it yours</h1>
            <p className="text-app-black-800 text-[21px] mb-8">
              Add Your Brand's Logo and make your survey recognizable and
              professional.
            </p>

            <div
              {...getRootProps()}
              className={`w-full max-w-[700px] h-[281px] p-4 mb-8 border-2 border-dashed border-app-gray-200 rounded-xl bg-app-gray-50 flex flex-col items-center justify-center cursor-pointer transition-colors ${
                isDragActive ? "border-app-cyan-500 bg-app-cyan-50" : ""
              }`}
            >
              <input {...getInputProps()} />
              {uploadedFile ? (
                <div className="text-center">
                  <CheckIcon
                    size={24}
                    className="text-app-gray-600 mx-auto mb-2"
                  />
                  <p className="text-app-gray-600">{uploadedFile.name}</p>
                  <p className="text-app-gray-400 text-sm">
                    {(uploadedFile.size / 1024 / 1024).toFixed(2)}MB
                  </p>
                </div>
              ) : (
                <>
                  <ImageIcon size={24} className="text-app-gray-600" />
                  <p className="text-app-gray-600 mt-4">
                    {isDragActive
                      ? "Drop the file here"
                      : "Click or drag and drop an image"}
                  </p>
                  <p className="text-app-gray-400 text-sm">
                    (SVG, PNG, JPG) - Max. 2MB
                  </p>
                </>
              )}
            </div>

            <Button
              className="mt-36 px-16"
              size="lg"
              endIcon={<ArrowRightIcon size={20} />}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div
        style={{ zIndex: 100 }}
        // className="w-full h-screen md:w-1/2 bg-app-cyan-100 flex items-center justify-center z-10 overflow-hidden"
        className="w-1/2 bg-app-cyan-100 z-10 h-screen relative overflow-hidden"
      >
        <img
          src="/images/miy-full.png"
          alt="Yoga"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        {/* <div className="bg-app-orange-400 w-[80%] max-w-[375px] h-[765px] max-h-[90%]  rounded-[50px] p-8 pt-16 flex flex-col items-center overflow-auto">
          <img
            src="/images/miyLogo.png"
            alt="Logo Preview"
            className="w-22 h-22 object-contain mb-8"
          />
          <div className="text-white text-center mt-20">
            <div className="text-[30px] font-semibold mb-16">
              Help us improve. Answer this quick questions.
            </div>
            <button className="bg-white text-app-black hover:bg-app-gray-100 px-6 py-2 rounded-xl">
              Tell us what you think
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MakeItYour;
