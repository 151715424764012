import React, { useState } from "react";
import Button from "../Button/Button";
import { CheckIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const OnboardingCompleted = ({ surveyId, surveyLink, handleCopyLink, onClose }) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    setIsCopied(true);
    handleCopyLink();
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <div className="flex flex-col items-center text-center max-w-md mx-auto pb-16">
      {/* Header section */}
      <div className="flex items-center justify-center bg-app-cyan-100 rounded-3xl w-24 h-24 mb-6">
        <img src="/images/wissle.svg" alt="Success" className="w-12" />
      </div>
      <h1 className="text-[43px] mb-4">Your form is ready!</h1>
      <p className="text-[16px] text-gray-600 mb-6">
        Congrats! Your form has been successfully created. You can start
        collecting responses right away or continue customizing your questions.
      </p>

      {/* Link section */}
      <div className="flex w-full items-center justify-between p-2 pl-3 gap-2 mb-8 border rounded-lg">
        <div className="truncate w-[70%] text-[16px]">{surveyLink}</div>
        <Button
          variant={isCopied ? "secondary" : "default"}
          // size="sm"
          onClick={handleCopy}
          disabled={isCopied}
        >
          {isCopied ? <CheckIcon className="w-4 h-4" /> : "Copy link"}
        </Button>
      </div>

      {/* "Where to go from here" section */}
      <h2 className="text-[21px] mb-6">Where to go from here</h2>
      <div className="flex justify-center gap-10 w-full">
        <div onClick={onClose} className="flex flex-col items-center cursor-pointer">
          <img
            src="/images/editimage.png"
            alt="Edit & Customize"
            className="w-[120px] mb-3"
          />
          <span className="text-[16px]">Edit & Customize</span>
        </div>
        <div onClick={() => navigate(`/survey/${surveyId}/integrate`)} className="flex flex-col items-center cursor-pointer">
          <img
            src="/images/integration0.png"
            alt="Set up integrations"
            className="w-[120px] mb-3"
          />
          <span className="text-[16px]">Set up integrations</span>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCompleted;
