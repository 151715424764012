import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuthInstance } from '../firebase';
import TabNavigation from '../components/settings/TabNavigation';
import ProfileTab from '../components/settings/ProfileTab';
import BillingTab from '../components/settings/BillingTab';
import NotificationsTab from '../components/settings/NotificationsTab';
import MediaLibraryTab from '../components/settings/MediaLibraryTab';
import { ModalContainer } from '../components/ModalContainer';

const ProfileSettings = ({ isOpen, onClose, initialTab = 'profile' }) => {
  const auth = getAuthInstance();
  const [user] = useAuthState(auth);
  const [activeTab, setActiveTab] = useState(initialTab);

  // Add effect to update activeTab when initialTab changes
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileTab user={user} />;
      case 'billing':
        return <BillingTab usingFor="BILLING" />;
      case 'notifications':
        return <NotificationsTab />;
      case 'media':
        return <MediaLibraryTab />;
      default:
        return null;
    }
  };

  return (
    <ModalContainer 
      isOpen={isOpen} 
      onClose={onClose}
      title="Settings"
      size="7xl"
    >
      <div className="h-full grid grid-cols-12">
        {/* Left sidebar - Fixed */}
        <div className="col-span-3 border-r">
          <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
        </div>

        {/* Right content - Scrollable */}
        <div className="col-span-9">
          <div className="min-h-0 max-h-[calc(100vh-8rem)] overflow-y-auto px-7 py-4">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ProfileSettings;