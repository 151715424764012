import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

export function IntercomChat({ user }) {

    useEffect(() => {
        // Initialize Intercom with user data if available
        if (user) {
            Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                user_id: user.uid,
                name: user.displayName || 'Anonymous',
                email: user.email,
                created_at: Math.floor(user.metadata.creationTime / 1000), // Convert to Unix timestamp
                // Add any additional user data here
            });
        } else {
            // Initialize Intercom without user data
            Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            });
        }

        // Cleanup on unmount
        return () => {
            if (window.Intercom) {
                window.Intercom('shutdown');
            }
        };
    }, [user]); // Re-run when user changes

    return null;
} 