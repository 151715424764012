export const defaultAiGeneratedSurveyBackground =
  "https://firebasestorage.googleapis.com/v0/b/surveyappreact.firebasestorage.app/o/default%2FdefaultBackground.png?alt=media&token=513b8c05-64b8-4544-85da-30fc80a0e2ab";

export const defaultAiGeneratedSurveyBackgroundColor = "#E1ECFF";

export const surveyOptions = [
  {
    id: "A",
    label: "Customer Feedback Survey",
    value: "Create a customer Feedback Survey for me.",
  },
  {
    id: "B",
    label: "Product Launch Feedback",
    value: "Create a product launch feedback survey for me.",
  },
  {
    id: "C",
    label: "Lead Generation Form",
    value: "Create a lead generation form for me.",
  },
  {
    id: "D",
    label: "Event Registration Form",
    value: "Create a event registration form for me.",
  },
  {
    id: "E",
    label: "Other",
    value: "Other",
  },
];

export const defaultSuggestions = [
  "Customer satisfaction survey to gauge overall experience",
  "Product feedback form to understand user opinion",
  "Event evaluation quiz to collect attendee insights",
];
