import React from "react";
import { Folder } from "lucide-react";

const WorkspaceSubmenu = ({
  workspaces,
  onSelect,
  activeWorkspace,
  onClose,
  buttonRect,
}) => {
  if (!buttonRect) return null;

  return (
    <div
      className="fixed w-[160px] bg-white rounded-xl shadow-lg border border-gray-100 p-1.5"
      style={{
        zIndex: 99999,
        left: `146px`,
        top: `120px`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {workspaces?.length === 0 ? (
        <div className="p-2 text-gray-500">No workspaces available</div>
      ) : (
        workspaces?.map((workspace) => (
          <button
            onClick={(e) => {
              e.stopPropagation();
              console.log("handle select", workspace?._id);
              onSelect(workspace?._id);
              onClose();
            }}
            key={workspace.id}
            className={`
                flex items-center w-full px-3 py-2 text-[14px] rounded-lg transition-all group
                ${
                  workspace?.id === activeWorkspace
                    ? "bg-gray-50 text-black"
                    : "text-gray-700 hover:bg-gray-50"
                }
              `}
          >
            <Folder className="shrink-0 w-4 h-4 mr-2.5 text-gray-400 group-hover:text-gray-600" />
            <span className="text-left font-medium">{workspace?.name}</span>
          </button>
        ))
      )}
    </div>
  );
};
export default WorkspaceSubmenu;
