import { Sparkles } from "lucide-react";
import React, { useCallback, useState } from "react";
import { generateAiQuestions, generateAiSuggestions } from "../../serverComm";
import Button from "../Button/Button";
import debounce from "lodash/debounce";
import { defaultSuggestions } from "../../assets/defaults";

const GenerateWithAiForm = ({
  appendAiGeneratedQuestions,
  generateTitleWithAi,
}) => {
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [suggestions, setSuggestions] = useState(defaultSuggestions);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const generateQuestionWithAi = async (description) => {
    const result = await generateAiQuestions({ description });
    if (appendAiGeneratedQuestions) {
      appendAiGeneratedQuestions(
        result.questions,
        generateTitleWithAi ? result.title : undefined
      );
    }
    setIsGenerating(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate description length
    if (!description || description.length < 10) {
      setError("Description must be at least 10 characters long");
      return;
    }

    // Clear any previous errors
    setError("");
    setIsGenerating(true);
    generateQuestionWithAi(description);
  };

  const handleGenerateAiSuggestions = useCallback(
    debounce(async (text) => {
      try {
        setIsSuggestionsLoading(true);
        const result = await generateAiSuggestions({ description: text });
        setSuggestions(result.suggestions);
        setIsSuggestionsLoading(false);
      } catch (error) {
        setIsSuggestionsLoading(false);
      }
    }, 2000),
    []
  );

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setError("");
    handleGenerateAiSuggestions(e.target.value);
  };

  return (
    <div className="space-y-4 p-6 pt-0">
      <div
        id="main-form"
        className="p-8 bg-white rounded-2xl max-h-[80vh] overflow-y-auto"
      >
        {isGenerating ? (
          <>
            <div className="flex flex-col items-center justify-center space-y-3 h-[61vh]">
              <Sparkles className="w-12 h-12 animate-pulse [filter:drop-shadow(0_0_10px_rgba(124,58,237,0.5))] text-purple-500" />
              <span className="text-mg text-gray-700 animate-pulse">
                Generating...
              </span>
            </div>
          </>
        ) : (
          <>
            <h2 className="font-medium mb-4">Describe your form</h2>
            <textarea
              className="w-full p-4 border bg-[#F9FAFB] border-gray-200 rounded-2xl min-h-[150px]"
              placeholder="For example, a feedback survey for the company's annual training session in San Francisco."
              value={description}
              onChange={handleDescriptionChange}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            <h3 className="font-medium mt-6 mb-4">Suggestions for you</h3>
            <div className="space-y-2">
              {suggestions.map((suggestion, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center space-x-2 text-sm text-gray-600"
                  >
                    {isSuggestionsLoading ? (
                      <Sparkles className="w-4 h-4 animate-pulse [filter:drop-shadow(0_0_10px_rgba(124,58,237,0.5))] text-purple-500" />
                    ) : (
                      <Sparkles className="w-4 h-4" />
                    )}
                    <span
                      className="cursor-pointer"
                      // Do not use "handleChangeDescription" here, because we dont want to trigger the debounce here.
                      onClick={() => setDescription(suggestion)}
                    >
                      {suggestion}
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {!isGenerating && (
        <div className="flex justify-end">
          <Button disabled={isGenerating} onClick={handleSubmit}>
            Generate
          </Button>
        </div>
      )}
    </div>
  );
};

export default GenerateWithAiForm;
