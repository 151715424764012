import { ArrowRightIcon, CheckIcon, EyeIcon } from "lucide-react";
import React, { useState } from "react";
import Button from "../Button/Button";
import { surveyOptions } from "../../assets/defaults";

const FirstProject = ({ setDescription, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!selectedOption) {
      setError("Please select an option to continue");
      return;
    }

    if (selectedOption.id !== "E") {
      setDescription(selectedOption.value);
    }
    onSubmit();
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/2 h-screen overflow-y-auto flex flex-col">
        <div className="h-10"></div>
        <div className="w-full flex-1 flex items-center">
          <div className="p-24">
            <h1 className="text-[43.64px] mb-4">What's Your First Project?</h1>
            <p className="text-app-black-800 text-[21px] mb-12">
              Tell us what you'd like to build. Are you gathering feedback,
              generating leads, or measuring satisfaction?
            </p>

            <div className="space-y-3">
              {surveyOptions.map((option) => (
                <div
                  key={option.id}
                  className="flex w-[70%] items-center gap-4 p-3 border border-app-gray-200 rounded-xl hover:border-app-gray-400 cursor-pointer"
                  onClick={() => {
                    setSelectedOption(option);
                    setError("");
                  }}
                >
                  <div
                    className={`text-[21.83px] border border-app-gray-200 w-[45px] h-[45px] flex items-center justify-center rounded-xl ${
                      selectedOption?.id === option.id
                        ? "bg-app-yellow-200"
                        : ""
                    }`}
                  >
                    {option.id}
                  </div>
                  <div className="text-[21.83px]">{option.label}</div>
                </div>
              ))}
            </div>
            {error && <p className="text-red-500 text-sm mt-4 mb-4">{error}</p>}
            <Button
              onClick={handleSubmit}
              className="mt-14"
              size="lg"
              endIcon={<ArrowRightIcon size={20} />}
            >
              Next: Generate With AI
            </Button>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div
        style={{ zIndex: 100 }}
        // className="w-full h-screen md:w-1/2 bg-app-pink-50 flex items-center justify-center z-10"
        className="w-1/2 bg-app-pink-50 z-10 h-screen relative overflow-hidden"
      >
        <img
          src="/images/firstproject-full.png"
          alt="Yoga"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        {/* <div className="w-[60%] h-[45%] p-4 bg-app-white rounded-3xl relative shadow-lg">
          <div className="bg-app-yellow-100 rounded-2xl p-6 h-[66%] relative">
            <div className="absolute top-4 right-4 bg-app-white h-[44px] w-[44px] flex items-center justify-center rounded-xl">
              <div className="flex flex-col gap-1">
                <div className="w-1 h-1 rounded-full bg-app-yellow-100"></div>
                <div className="w-1 h-1 rounded-full bg-app-yellow-100"></div>
                <div className="w-1 h-1 rounded-full bg-app-yellow-100"></div>
              </div>
            </div>

            <div className="space-y-4 mt-16 hidden md:block">
              <div className="bg-app-white h-3 w-full rounded-sm"></div>
              <div className="bg-app-white h-3 w-3/4 rounded-sm"></div>
            </div>
          </div>
          <div className="absolute bottom-20 left-6 bg-app-gray-200 h-[5px] w-[95px] hidden md:block"></div>
          <div className="absolute bottom-4 w-[92%] left-6 flex items-center justify-between">
            <span className="text-[45px] font-extrabold text-app-gray-200 hidden md:block">
              22
            </span>
            <div className="flex items-center gap-3">
              <EyeIcon className="text-app-gray-500 hidden md:block" />
              <div className="bg-app-gray-200 h-[11px] w-[97px] hidden md:block"></div>
            </div>
          </div>

          <div className="absolute w-[33%] -top-[35%] left-[12%]">
            <div className="relative w-full">
              <img
                src="/images/avatar1.jpg"
                alt="Avatar"
                className="w-full rounded-full border-4 border-app-pink-50 object-cover"
              />
              <div className="absolute right-1 top-1 bg-app-green-500 rounded-full p-1 border-4 border-app-pink-50">
                <CheckIcon className="text-white" size={30} />
              </div>
            </div>
          </div>

          <div className="absolute w-[20%] -right-[16%] top-[33%]">
            <div className="relative w-full">
              <img
                src="/images/avatar2.jpg"
                alt="Avatar"
                className="w-full  rounded-full border-4 border-app-pink-50 object-cover"
              />
              <div className="absolute right-1 top-1 bg-app-green-500 rounded-full p-1 border-2 border-app-pink-50">
                <CheckIcon className="text-white" size={15} />
              </div>
            </div>
          </div>

          <div className="absolute w-[25%] -bottom-[35%] -left-[10%]">
            <div className="relative w-full">
              <img
                src="/images/avatar3.jpg"
                alt="Avatar"
                className="w-full  rounded-full border-4 border-app-pink-50 object-cover"
              />
              <div className="absolute right-1 top-1 bg-app-green-500 rounded-full p-1 border-4 border-app-pink-50">
                <CheckIcon className="text-white" size={20} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FirstProject;
