import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Folder, Edit, Trash } from "lucide-react";
import ConfirmationModal from "../ui/ConfirmationModal";
import WorkspaceModal from "../WorkspaceModal";
const WorkspaceItem = ({
  id,
  name,
  isActive,
  onSelect,
  count,
  handleDeleteWorkspace = () => {},
  handleRenameWorkspace = () => {},
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditClick = (newName) => {
    handleRenameWorkspace(newName, id);
  };

  return (
    <>
      <div
        onClick={onSelect}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`
        flex items-center justify-between p-3 rounded-lg cursor-pointer
        ${isActive ? "bg-[#F9FAFB] text-white" : "hover:bg-[#F9FAFB]"}
      `}
      >
        <div className="flex items-center gap-2">
          <Folder className="w-4 h-4 text-app-black-800" />
          <span className="text-sm font-medium text-app-black-800">{name}</span>
        </div>
        {isHovered ? (
          <div className="flex gap-2">
            <Edit
              className="w-4 h-4 text-gray-400 cursor-pointer"
              onClick={() => {
                setShowEditModal(true);
              }}
            />
            <Trash
              className="w-4 h-4 text-gray-400 cursor-pointer"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        ) : (
          <span
            className={`
            text-xs font-medium
            ${isActive ? "text-white/70" : "text-gray-400"}
          `}
          >
            {count}
          </span>
        )}
        {isActive && !isHovered && (
          <span className={`text-xs font-medium text-gray-400`}>{count}</span>
        )}
      </div>
      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleDeleteWorkspace(id, name)}
        title="Delete WorkSpace"
        message="Are you sure you want to delete this workspace along with all the surveys in it? This action cannot be undone."
      />
      <WorkspaceModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSubmit={handleEditClick}
        isEdit={true}
        workspaceName={name}
      />
    </>
  );
};

export default WorkspaceItem;
