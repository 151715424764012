import React from "react";
import { cn } from "../../utils/helper";
import { Loader } from "lucide-react";

const Button = ({ 
  children, 
  variant = 'default', // 'default' | 'outline' | 'integrate' | 'connected'
  size = 'md',
  className,
  startIcon,
  endIcon,
  isLoading = false,
  ...props
}) => {
  const baseStyles =
    "inline-flex items-center justify-center rounded-lg font-medium transition-colors focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed";

  const variants = {
    default: "bg-app-black text-white hover:bg-app-black/90 ",
    outline: "border border-app-gray-200 bg-white text-app-black hover:bg-app-gray-50",
    integrate: "bg-app-green-700 text-white hover:bg-app-green-700/90 px-6",
    connected: "bg-app-green-100 text-app-black cursor-default px-6"
  };

  const sizes = {
    sm: "h-9 text-sm",
    md: "px-5 py-2 text-base",
    lg: "px-6 py-3 text-lg rounded-xl",
  };

  return (
    <button
      className={cn(baseStyles, variants[variant], sizes[size], className)}
      {...props}
    >
      {isLoading ? (
        <Loader className="w-6 h-6 animate-spin" />
      ) : (
        <>
          {startIcon && <span className="mr-2">{startIcon}</span>}
          {children}
          {endIcon && <span className="ml-2">{endIcon}</span>}
        </>
      )}
    </button>
  );
};

export default Button;
