import React from "react";
import { PenSquare } from "lucide-react";
import Button from "../Button/Button";

const WelcomeSection = ({
  onNewSurvey,
  isDisabled,
  onUpgradeClick,
}) => {
  return (
    <>
      <div className="mb-8">
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (isDisabled) {
              onUpgradeClick(e);
            } else {
              onNewSurvey();
            }
          }}
          startIcon={<PenSquare size={18} />}
          size="lg"
        >
          Create
        </Button>
      </div>
    </>
  );
};

export default WelcomeSection;
