import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  getSurveys,
  deleteSurvey,
  duplicateSurvey,
  getSurveyWithResponses,
  getAllWorkspaces,
  createWorkspace,
  moveSurvey,
  getSurveyByWorkSpace,
  deleteWorkspace,
  editWorkspace,
  getWorkspacesResponsesCount,
  renameSurvey,
} from "./serverComm";
import {
  Eye,
  Link as LinkIcon,
  Plus,
  Folder,
  Loader,
  PencilIcon,
} from "lucide-react";
import { getAuthInstance } from "./firebase";
import copy from "clipboard-copy";
import pollPrevBg from "./poll-prev-bg.svg";
import Templates from "./Templates";
import WorkspaceModal from "./components/WorkspaceModal";
import ProfileSettings from "./pages/ProfileSettings";

import ResponseLimitAlert from "./components/ResponseLimitAlert";
import WorkspaceItem from "./components/SurveyListComponents/WorkspaceItems";
import ResponsesCounter from "./components/SurveyListComponents/ResponseCounter";
import ResponseLimitModal from "./components/SurveyListComponents/ResponseLimitModal";
import WorkspaceSubmenu from "./components/SurveyListComponents/WorkspaceSubmenu";
import WelcomeSection from "./components/SurveyListComponents/WelcomeSection";
import { hasReachedResponseLimit } from "./utils/helper";
import { DelayedMessage } from "./components/DelayedMessage";
import SurveyRenameModal from "./components/SurveyRenameModal";

const backgroundColors = [
  "#FFE8CE", // Orange
  "#DCE1FF", // Blue
  "#FFD2D5", // Pink
  "#D3FBF1", // Mint
  "#FFFDD2", // Yellow
];

const UpgradeButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="w-full px-4 py-2.5 bg-app-cyan-100 hover:bg-app-cyan-100/70 rounded-lg transition-all flex items-center justify-between"
  >
    <span className="text-sm font-medium text-app-black-700">
      Increase response limit
    </span>
    <span className="text-black">→</span>
  </button>
);

const SurveyList = () => {
  const navigate = useNavigate();
  const auth = getAuthInstance();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [responsesUsed, setResponsesUsed] = useState(0);
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isProfileSettingsOpen, setIsProfileSettingsOpen] = useState(false);
  const [showWorkspaceSubmenu, setShowWorkspaceSubmenu] = useState(null);
  const [submenuButtonRect, setSubmenuButtonRect] = useState(null);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showNoSurveys, setShowNoSurveys] = useState(false);
  const [isSurveyRenameModalOpen, setIsSurveyRenameModalOpen] = useState(null); // null || {status: true, data: survey}

  const fetchSurveys = async () => {
    setShowNoSurveys(false);
    if (!auth.currentUser) {
      return;
    }

    if (!activeWorkspace) {
      setSurveys([]);
      return;
    }

    try {
      const fetchedSurveys = activeWorkspace
        ? await getSurveyByWorkSpace(activeWorkspace)
        : await getSurveys();

      if (!Array.isArray(fetchedSurveys)) {
        setSurveys([]);
        setShowNoSurveys(true);
        return;
      }

      // Fetch detailed survey data with responses for each survey
      const surveysWithResponses = await Promise.all(
        fetchedSurveys.map(async (survey) => {
          try {
            if (!survey || !survey.surveyId) return null;
            const detailedSurvey = await getSurveyWithResponses(
              survey.surveyId
            );
            return {
              ...survey,
              responses: detailedSurvey.responses || [],
            };
          } catch (error) {
            console.error(
              `Error fetching responses for survey ${survey?.surveyId}:`,
              error
            );
            return null;
          }
        })
      );

      // Filter out null values and invalid surveys
      const validSurveys = surveysWithResponses.filter(
        (survey) => survey && survey.surveyId && !survey.isDeleted
      );

      if (validSurveys.length === 0) {
        setShowNoSurveys(true);
      }

      setSurveys(validSurveys);
      return validSurveys; // Return the surveys for the parent function
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setError("Failed to load surveys");
      return [];
    }
  };

  const fetchCurrentSubscription = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      // Fix the API URL - make sure it matches your server URL
      const API_URL =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001"
          : "https://surv-server.vercel.app";

      const response = await fetch(
        `${API_URL}/api/stripe/current-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch subscription");
      }

      const data = await response.json();

      // Make sure we're setting the subscription with the correct structure
      if (data.subscription) {
        const currentSubscriptionData = {
          namme: data?.subscription?.name,
          limit: data?.subscription?.responseLimit,
          maxSurveys: data?.subscription?.maxSurveys,
        };
        setCurrentSubscription(currentSubscriptionData);
      }
    } catch (err) {
      console.error("Error fetching current subscription:", err);
      setCurrentSubscription(null);
    }
  };

  const fetchWorkspaces = async () => {
    try {
      const data = await getAllWorkspaces();
      setWorkspaces(data);
      if (data.length > 0) {
        setActiveWorkspace(data[0]._id);
      }
    } catch (error) {
      console.error("Error fetching workspaces:", error);
      setError("Failed to load workspaces");
    }
  };

  const fetchData = async () => {
    if (!auth.currentUser) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setShowNoSurveys(false);

      // Execute all promises and wait for their results
      const [subscriptionData, workspacesData, responsesCount] =
        await Promise.all([
          fetchCurrentSubscription(),
          fetchWorkspaces(),
          getWorkspacesResponsesCount().catch((error) => {
            console.error("Error fetching total responses:", error);
            return 0;
          }),
        ]);

      // Update states only after all data is fetched
      if (subscriptionData) {
        setCurrentSubscription(subscriptionData);
      }
      if (workspacesData) {
        setWorkspaces(workspacesData);
      }
      setResponsesUsed(responsesCount);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auth.currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenuId) {
        const isClickOutside =
          !event.target.closest(".settings-menu") &&
          !event.target.closest(".settings-button");
        if (isClickOutside) {
          setOpenMenuId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openMenuId]);

  useEffect(() => {
    const checkResponseLimits = () => {
      const hasLimitReached = surveys.some((survey) =>
        hasReachedResponseLimit(survey, currentSubscription, surveys)
      );

      if (hasLimitReached) {
        setShowLimitModal(true);
      }
    };

    checkResponseLimits();
  }, [surveys, currentSubscription]);

  useEffect(() => {
    fetchSurveys();
  }, [activeWorkspace]);

  const handleNewSurvey = async () => {
    if (!activeWorkspace) {
      toast.error("Please select a workspace to create a survey.");
      return;
    }
    if (currentSubscription) {
      const { maxSurveys } = currentSubscription;
      if (maxSurveys && !isNaN(maxSurveys) && surveys.length >= maxSurveys) {
        // Show a toast notification (you can replace this with your toast implementation)
        let message = `You cannot create more than ${maxSurveys} surveys on your current plan.`;
        toast(message, {
          icon: "⚠️",
          style: {
            border: "1px solid #FFA500",
            padding: "10px",
            color: "#FFA500",
          },
        });
        return;
      }
    }
    try {
      navigate(`/surveys/new?workspaceId=${activeWorkspace}`);
    } catch (error) {
      console.error("Error creating new survey:", error);
      setError("Failed to create survey");
    } finally {
      setLoading(false);
    }
  };

  const handleViewAnalytics = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId(null);
    navigate(`/survey/${surveyId}/analytics`, { replace: true });
  };

  const handleDeleteClick = async (e, survey) => {
    e.stopPropagation();

    if (!survey || !survey.surveyId) {
      console.error("Invalid survey data:", survey);
      return;
    }

    setSurveyToDelete(survey);
    setDeleteModalOpen(true);
    setOpenMenuId(null);
  };

  const handleConfirmDelete = async () => {
    if (!surveyToDelete || !surveyToDelete.surveyId) {
      console.error("No valid survey to delete");
      return;
    }

    try {
      setDeletingId(surveyToDelete.surveyId);

      // Call the delete API
      await deleteSurvey(surveyToDelete.surveyId);

      // Update local state only after successful deletion
      setSurveys((prevSurveys) =>
        prevSurveys.filter((s) => s.surveyId !== surveyToDelete.surveyId)
      );

      // Reset modal state
      setDeleteModalOpen(false);
      setSurveyToDelete(null);
    } catch (error) {
      console.error("Error deleting survey:", error);
      alert("Failed to delete survey. Please try again.");
    } finally {
      setDeletingId(null);
    }
  };

  const handleCopyLink = (e, surveyId) => {
    e.stopPropagation();
    const link = `${window.location.origin}/s/${surveyId}`;
    copy(link)
      .then(() => {
        setCopiedId(surveyId);
        setTimeout(() => setCopiedId(null), 3000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const handleRenameWorkspace = async (newName, id) => {
    try {
      await editWorkspace(newName, id);
      window.location.reload();
    } catch (error) {
      toast.error("Oops! Some went wrong.");
    }
  };

  const handleDeleteWorkspace = async (id) => {
    try {
      const deletedWorkspace = await deleteWorkspace(id);
      console.log("delete workspace", deletedWorkspace);
      window.location.reload();
    } catch (error) {
      toast.error("Oops! Some went wrong.");
    }
  };

  const handleSettingsClick = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId((current) => (current === surveyId ? null : surveyId));
  };

  const handleDuplicateSurvey = async (e, surveyId) => {
    e.stopPropagation();
    try {
      setIsDuplicating(true);
      setOpenMenuId(null); // Close menu immediately

      const duplicatedSurvey = await duplicateSurvey(surveyId);

      // Add the new survey to the list
      setSurveys((prevSurveys) => [
        {
          ...duplicatedSurvey,
          responses: [], // Initialize with empty responses
          viewCount: 0, // Initialize with 0 views
        },
        ...prevSurveys,
      ]);

      // Show success message (optional)
      // toast.success('Survey duplicated successfully');
    } catch (error) {
      console.error("Error duplicating survey:", error);
      alert("Failed to duplicate survey. Please try again.");
    } finally {
      setIsDuplicating(false);
    }
  };

  const getBackgroundColor = (index) => {
    return backgroundColors[index % backgroundColors.length];
  };

  const handleUpgradeClick = (e) => {
    e.preventDefault();
    setIsProfileSettingsOpen(true);
  };

  const handleCardClick = (surveyId) => {
    setOpenMenuId(null);
    const survey = surveys.find((s) => s.surveyId === surveyId);
    if (hasReachedResponseLimit(survey, currentSubscription, surveys)) {
      setShowLimitModal(true);
    } else {
      navigate(`/survey/${surveyId}`);
    }
  };

  const hasReachedTotalResponseLimit = () => {
    if (!currentSubscription) return false;
    const { limit, name } = currentSubscription;

    // If no response limit is set (unlimited)
    if (limit === null || limit === undefined) return false;

    const totalResponses = surveys.reduce(
      (sum, survey) => sum + (survey.responses?.length || 0),
      0
    );

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        // Pro plan: No total limit, only per-survey limits
        return false;
      case "Growth Plan":
        // Growth plan: Unlimited responses
        return false;
      case "Free Trial":
      default:
        // Free plan: Check total responses
        return totalResponses >= limit;
    }
  };

  const handleCreateWorkspace = async (name) => {
    try {
      // Call the API to create a new workspace
      const newWorkspace = await createWorkspace(name); // Assuming createWorkspace is the API function

      // Update the local state with the new workspace
      setWorkspaces((prev) => [
        ...prev,
        {
          id: newWorkspace.id, // Assuming the API returns the new workspace with an id
          name: newWorkspace.name,
          count: 0,
        },
      ]);
      window.location.reload();
    } catch (error) {
      console.error("Error creating workspace:", error);
    }
  };

  const handleMoveSurvey = async (
    surveyId,
    newWorkspaceId,
    sourceWorkspaceId
  ) => {
    if (newWorkspaceId !== undefined) {
      try {
        // Call the API to move the survey
        await moveSurvey(newWorkspaceId, surveyId, sourceWorkspaceId); // Call the moveSurvey function

        // setSurveys((prevSurveys) =>
        //   prevSurveys.map((survey) =>
        //     survey.surveyId === surveyId
        //       ? { ...survey, workspaceId: newWorkspaceId }
        //       : survey
        //   )
        // );

        // // Update workspace counts
        // setWorkspaces((prevWorkspaces) =>
        //   prevWorkspaces.map((workspace) => ({
        //     ...workspace,
        //     count:
        //       workspace._id === newWorkspaceId
        //         ? workspace.count + 1
        //         : workspace._id === activeWorkspace
        //         ? workspace.count - 1
        //         : workspace.count,
        //   }))
        // );
        window.location.reload();
      } catch (error) {
        console.error("Error moving survey:", error);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showWorkspaceSubmenu !== null) {
        const isClickInside = event.target.closest(".settings-menu");
        if (!isClickInside) {
          setShowWorkspaceSubmenu(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showWorkspaceSubmenu]);

  const handleRenameSurvey = async (surveyId, newTitle) => {
    // Add your rename logic here
    try {
      await renameSurvey({ surveyId, newTitle });
      setIsSurveyRenameModalOpen(null);
      setSurveys((prevSurveys) =>
        prevSurveys.map((survey) =>
          survey.surveyId === surveyId ? { ...survey, title: newTitle } : survey
        )
      );
    } catch (error) {
      toast.error("Error renaming survey");
    }
  };

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }
  return (
    <div className="flex min-h-screen bg-white">
      {/* Sidebar */}
      <div
        className={`
        fixed left-0 top-0 h-screen bg-white border-r border-gray-200
        transition-all duration-300 z-30 pt-14
        ${isSidebarOpen ? "w-64" : "w-0 -translate-x-full"}
      `}
      >
        <div className="p-4">
          {/* Workspace Header */}
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-sm font-semibold text-app-gray-600">
              WORKSPACES
            </h2>
            <button
              className="w-7 h-7 flex items-center justify-center rounded-md hover:bg-gray-100"
              onClick={() => setIsWorkspaceModalOpen(true)}
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>

          {/* Workspace List */}
          <div className="space-y-1">
            {workspaces.map((workspace) => (
              <WorkspaceItem
                key={workspace._id}
                name={workspace.name}
                id={workspace._id}
                count={workspace?.surveys?.length}
                isActive={workspace?._id === activeWorkspace}
                onSelect={() => {
                  setActiveWorkspace(workspace._id);
                }}
                handleDeleteWorkspace={handleDeleteWorkspace}
                handleRenameWorkspace={handleRenameWorkspace}
              />
            ))}
          </div>

          {/* Workspace Settings */}
          <div className="absolute bottom-8 left-0 right-0 px-4">
            <div className="space-y-2">
              <ResponsesCounter
                used={responsesUsed}
                subscription={currentSubscription}
              />
              <UpgradeButton onClick={handleUpgradeClick} />
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`
        flex-1 transition-all duration-300
        ${isSidebarOpen ? "ml-64" : "ml-0"}
      `}
      >
        {loading && (
          <div className="flex items-center justify-center w-full h-full">
            <Loader className="w-6 h-6 animate-spin" />
          </div>
        )}

        <div className="w-full sm:w-[80%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
          <WelcomeSection
            userName={auth.currentUser?.displayName}
            onNewSurvey={handleNewSurvey}
            onOpenTemplates={() => setIsTemplateModalOpen(true)}
            isDisabled={hasReachedTotalResponseLimit()}
            onUpgradeClick={(e) => {
              e.preventDefault();
              setShowLimitModal(true);
            }}
            surveys={surveys}
            currentSubscription={currentSubscription}
          />

          {/* Title based on selected workspace */}
          <div className="mb-4 sm:mb-6">
            <h2 className="text-app-black text-lg sm:text-[21px] text-left font-['HK_Nova'] font-md leading-[21px]">
              {activeWorkspace
                ? workspaces.find((ws) => ws._id === activeWorkspace)?.name
                : "All Forms"}
            </h2>
          </div>

          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader className="w-6 h-6 animate-spin" />
            </div>
          ) : (
            <>
              {surveys.length > 0 ? (
                <>
                  {surveys.some((survey) =>
                    hasReachedResponseLimit(
                      survey,
                      currentSubscription,
                      surveys
                    )
                  ) && (
                    <ResponseLimitAlert
                      subscription={currentSubscription}
                      onUpgradeClick={() => setShowLimitModal(true)}
                    />
                  )}

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {surveys.map((survey, index) => {
                      const isLimitReached = hasReachedResponseLimit(
                        survey,
                        currentSubscription
                      );

                      return (
                        <div
                          key={survey.surveyId}
                          onClick={() => handleCardClick(survey.surveyId)} //hover:-translate-y-1
                          className={`relative bg-white border border-[#e4e4e4] rounded-[15px] 
                            transition-all duration-200 hover:shadow-lg cursor-pointer 
                            h-[280px] sm:h-[320px] ${
                              isLimitReached ? "opacity-75" : ""
                            }`}
                        >
                          {isLimitReached && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black/5 rounded-[15px]">
                              <div
                                className="bg-white/95 px-4 py-2 rounded-lg shadow-lg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowLimitModal(true);
                                }}
                              >
                                <span className="text-red-600 font-medium">
                                  Response Limit Reached
                                </span>
                              </div>
                            </div>
                          )}
                          <div
                            className="absolute inset-x-2.5 sm:inset-x-3.5 top-2.5 sm:top-3.5 h-[180px] sm:h-[200px] rounded-[10px] overflow-visible border border-[#e4e4e4]"
                            style={{
                              backgroundColor: getBackgroundColor(index),
                            }}
                          >
                            <div className="relative w-full h-full">
                              <img
                                src={pollPrevBg}
                                alt=""
                                className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
                                style={{ mixBlendMode: "soft-light" }}
                              />

                              <div className="absolute top-3 sm:top-4 right-3 sm:right-4 z-20">
                                <div className="relative flex justify-end">
                                  <button
                                    onClick={(e) =>
                                      handleSettingsClick(e, survey.surveyId)
                                    }
                                    className="settings-button w-[38px] h-[38px] flex items-center justify-center bg-app-white hover:bg-app-white/70 rounded-[10px] transition-colors flex-shrink-0"
                                  >
                                    <svg
                                      className="w-5 h-5 text-black flex-shrink-0"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      stroke="none"
                                    >
                                      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                    </svg>
                                  </button>

                                  {openMenuId === survey.surveyId && (
                                    <div
                                      onClick={(e) => e.stopPropagation()}
                                      className="settings-menu absolute w-[160px] bg-white/95 rounded-xl shadow-lg border border-gray-100 p-1.5 backdrop-blur-sm"
                                      style={{
                                        top: "100%",
                                        right: "0px",
                                        marginTop: "8px",
                                        zIndex: 9000,
                                      }}
                                    >
                                      <div className="py-0.5">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleViewAnalytics(
                                              e,
                                              survey.surveyId
                                            );
                                            setOpenMenuId(null);
                                          }}
                                          className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                        >
                                          <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                            <svg
                                              className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                            >
                                              <path d="M18 20V10M12 20V4M6 20v-6" />
                                            </svg>
                                          </div>
                                          <span className="font-medium">
                                            Analytics
                                          </span>
                                        </button>

                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDuplicateSurvey(
                                              e,
                                              survey.surveyId
                                            );
                                            setOpenMenuId(null);
                                          }}
                                          className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                        >
                                          <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                            <svg
                                              className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                            >
                                              <rect
                                                x="9"
                                                y="9"
                                                width="13"
                                                height="13"
                                                rx="2"
                                                ry="2"
                                              />
                                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                            </svg>
                                          </div>
                                          <span className="font-medium">
                                            Duplicate
                                          </span>
                                        </button>

                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(e, survey);
                                            setOpenMenuId(null);
                                          }}
                                          className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                        >
                                          <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                            <svg
                                              className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                            >
                                              <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                            </svg>
                                          </div>
                                          <span className="font-medium">
                                            Delete
                                          </span>
                                        </button>

                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent click from closing the submenu
                                            const rect =
                                              e.currentTarget.getBoundingClientRect();
                                            setSubmenuButtonRect(rect); // Set the button rectangle
                                            setShowWorkspaceSubmenu((current) =>
                                              current === survey.surveyId
                                                ? null
                                                : survey.surveyId
                                            );
                                          }}
                                          className="relative flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                        >
                                          <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                            <Folder className="w-full h-full text-gray-400 group-hover:text-gray-600" />
                                          </div>
                                          <span className="font-medium">
                                            Move to
                                          </span>
                                          <svg
                                            className="w-4 h-4 ml-auto text-gray-400 flex-shrink-0"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          >
                                            <path d="M9 18l6-6-6-6" />
                                          </svg>

                                          {showWorkspaceSubmenu ===
                                            survey.surveyId && (
                                            <WorkspaceSubmenu
                                              workspaces={workspaces}
                                              activeWorkspace={
                                                survey.workspaceId ||
                                                activeWorkspace
                                              }
                                              onSelect={async (workspaceId) => {
                                                try {
                                                  await handleMoveSurvey(
                                                    survey.surveyId,
                                                    workspaceId,
                                                    survey.workspaceId
                                                  );
                                                  setShowWorkspaceSubmenu(null); // Close submenu after successful selection
                                                } catch (error) {
                                                  console.error(
                                                    "Error moving survey:",
                                                    error
                                                  );
                                                  // Optionally show an error message to the user
                                                }
                                              }}
                                              onClose={() => {
                                                setShowWorkspaceSubmenu(null);
                                                setOpenMenuId(null);
                                                setSubmenuButtonRect(null);
                                              }}
                                              buttonRect={submenuButtonRect}
                                            />
                                          )}
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setIsSurveyRenameModalOpen({
                                              status: true,
                                              data: survey,
                                            });
                                          }}
                                          className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                        >
                                          <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                            <PencilIcon className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors" />
                                          </div>
                                          <span className="font-medium">
                                            Rename
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="absolute left-[20px] sm:left-[24px] bottom-[16px] sm:bottom-[20px]">
                                <h2 className="text-[16px] sm:text-[18px] font-['HK_Nova'] font-medium leading-[20px] sm:leading-[22px] tracking-[-0.93px] text-black mb-3 max-w-[260px] sm:max-w-[280px]">
                                  {survey.title}
                                </h2>
                                <div className="flex items-center gap-2">
                                  <span>Status</span>
                                  <div
                                    className={`
                                    flex items-center gap-2 px-2 py-0 rounded-full bg-app-white                                    
                                  `}
                                  >
                                    <div
                                      className={`
                                      w-1.5 h-1.5 rounded-full
                                      ${
                                        survey.isOpen
                                          ? "bg-[#027A48]"
                                          : "bg-[#C01048]"
                                      }
                                    `}
                                    />
                                    <span className="text-sm font-medium">
                                      {survey.isOpen ? "Open" : "Closed"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="absolute inset-x-[24px] sm:inset-x-[32px] bottom-[20px] sm:bottom-[24px] flex items-end justify-between">
                            <div>
                              <div className="text-sm font-medium text-[#7b7b7d] tracking-[-0.48px]">
                                Total responses
                              </div>
                              <div className="text-3xl font-bold text-black tracking-[-1.08px]">
                                {survey.responses?.length || 0}
                              </div>
                            </div>

                            <div className="flex items-center gap-3 pb-2">
                              <div className="flex items-center gap-2 text-app-gray-500">
                                <Eye className="w-4 h-4" />
                                <span className="text-sm font-medium tracking-[-0.48px]">
                                  {survey.viewCount || 0}
                                </span>
                              </div>
                              <button
                                onClick={(e) =>
                                  handleCopyLink(e, survey.surveyId)
                                }
                                className="flex items-center text-sm font-medium text-app-gray-500 tracking-[-0.48px] hover:text-gray-900 transition-colors"
                              >
                                <LinkIcon className="w-3 h-3 mr-1.5" />
                                {copiedId === survey.surveyId
                                  ? "Copied!"
                                  : "Share"}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <DelayedMessage
                  show={showNoSurveys}
                  message="No surveys found."
                  delay={0}
                />
              )}
              {deleteModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                    <h3 className="text-lg font-semibold mb-4">
                      Confirm Deletion
                    </h3>
                    <p className="mb-4">
                      Are you sure you want to delete this survey? This action
                      cannot be undone.
                    </p>
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => setDeleteModalOpen(false)}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleConfirmDelete}
                        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Templates
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
      />

      <WorkspaceModal
        isOpen={isWorkspaceModalOpen}
        onClose={() => setIsWorkspaceModalOpen(false)}
        onSubmit={handleCreateWorkspace}
      />

      <ProfileSettings
        isOpen={isProfileSettingsOpen}
        onClose={() => setIsProfileSettingsOpen(false)}
        initialTab="billing"
      />

      {/* Add the ResponseLimitModal */}
      <ResponseLimitModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
        subscription={currentSubscription}
        setIsProfileSettingsOpen={setIsProfileSettingsOpen}
      />
      <SurveyRenameModal
        isOpen={isSurveyRenameModalOpen?.status}
        onClose={() => setIsSurveyRenameModalOpen(null)}
        onSubmit={handleRenameSurvey}
        surveyData={isSurveyRenameModalOpen?.data}
      />
    </div>
  );
};

export default SurveyList;
