import { ArrowRightIcon } from "lucide-react";
import React from "react";
import Button from "../Button/Button";

const IntegrationProject = ({ onSubmit, loading }) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/2 h-screen overflow-y-auto flex flex-col">
        <div className="h-10"></div>
        <div className="w-full flex-1 flex items-center">
          <div className="p-24">
            <h1 className="text-[43.64px] mb-4">
              Powerful Integrations for Deeper Insights
            </h1>
            <p className="text-app-black-800 text-[21px] mb-8">
              Connect PollBolt with Google Analytics, Slack, and more to track
              performance, automate workflows, and make data-driven
              decisions—all in one place.
            </p>

            <Button
              className="mt-48 px-10"
              size="lg"
              endIcon={<ArrowRightIcon size={20} />}
              onClick={handleSubmit}
              isLoading={loading}
              disabled={loading}
            >
              Finalize In Editor
            </Button>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div
        style={{ zIndex: 100 }}
        className="w-1/2 bg-app-pink-100 z-10 h-screen relative overflow-hidden"
      >
        <img
          src="/images/integration-full.png"
          alt="Yoga"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        {/* <div className="absolute bottom-[8%] -left-[38%] w-[50%] h-[55%] rounded-[70px] overflow-hidden">
          <img
            src="/images/yogaimage.jpeg"
            alt="Yoga"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
        <div className="absolute -top-[10%] left-[16%] w-[35%] h-[70%] rounded-[70px] overflow-hidden bg-gradient-to-b from-app-white to-app-yellow-200 px-10 flex flex-col justify-end pb-36">
          <div className="text-4xl text-app-olive-100">What's your name?</div>
          <div className="text-md text-app-olive-200 pt-8">Diana</div>
        </div>
        <div className="absolute -bottom-[36%] left-[16%] w-[35%] h-[70%] rounded-[70px] overflow-hidden bg-gradient-to-b from-app-white to-app-yellow-200 px-10 flex flex-col justify-start pt-36">
          <div className="text-4xl text-app-olive-100">What's your email?</div>
          <div className="text-md text-app-olive-200 pt-8">Diana@gmail.com</div>
        </div>
        <div className="absolute top-[10%] -right-[5%] flex flex-wrap gap-4 w-[50%]">
          {[
            "integration1",
            "integration2",
            "integration3",
            "integration4",
            "integration5",
          ].map((item, index) => (
            <div
              key={index}
              className="bg-app-white rounded-[40px] w-[45%] aspect-square flex items-center justify-center"
            >
              <img src={`/images/${item}.png`} alt="Integration" />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default IntegrationProject;
