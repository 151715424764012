import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeSurvey: null,
  hasChanges: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    setActiveSurvey: (state, action) => {
      state.activeSurvey = action.payload;
    },
    setHasChanges: (state, action) => {
      state.hasChanges = action.payload;
    },
  },
});

export const { setActiveSurvey, setHasChanges } = appSlice.actions;

export default appSlice.reducer;
