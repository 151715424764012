import React, { useState, useEffect } from 'react';
import { X, Plus, Check } from 'lucide-react';
import { ModalContainerFull } from '../ModalContainer';
import Button from '../Button/Button';
import { authenticateGoogleSheets } from '../../utils/googleSheetsAuth';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import GoogleSheetsSetup from './GoogleSheetsSetup';
import { extractSheetId } from '../../utils/googleSheets';
import IntegrationDecorativeSide from './IntegrationDecorativeSide';
import { Loader } from '../Loader';

const ConnectedAccount = ({ email }) => (
    <div className="flex items-center gap-3 p-1.5 hover:bg-app-gray-50 border-app-gray-200 border rounded-3xl cursor-pointer">
        <div className="w-8 h-8 rounded-full overflow-hidden">
            <img
                src={`https://ui-avatars.com/api/?name=${email}&background=c2e2da&color=2a4c43`}
                alt={email}
                className="w-full h-full object-cover"
            />
        </div>
        <p className="text-sm font-medium text-app-black-700">{email}</p>
    </div>
);

const GoogleIntegrationModal = ({ 
    isOpen, 
    onClose, 
    initialState = 'connect', 
    onSuccess,
    surveyId
}) => {
    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [connectedAccounts, setConnectedAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    // Reset all states when modal closes
    useEffect(() => {
        if (!isOpen) {
            setIsLoading(false);
            setIsRemoving(false);
            setIsFetching(false);
            setState(initialState);
            setSelectedAccount(null);
        } else {
            // Fetch accounts whenever modal opens
            fetchConnectedAccounts();
        }
    }, [isOpen, initialState]);

    // Add handleClose function to wrap onClose
    const handleClose = () => {
        setIsLoading(false);
        setIsRemoving(false);
        setIsFetching(false);
        onClose();
    };

    const fetchConnectedAccounts = async () => {
        try {
            setIsFetching(true);
            const auth = getAuth();
            const idToken = await auth.currentUser.getIdToken();

            // Just get all Google Sheets integrations
            const response = await fetch('/api/integrations/google-sheets', {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch connected accounts');
            }

            const data = await response.json();
            
            // Set connected accounts
            setConnectedAccounts(data.integrations || []);
            
            // If we have accounts, go to connected state
            setState(data.integrations?.length > 0 ? 'connected' : 'connect');

        } catch (error) {
            console.error('Error fetching connected accounts:', error);
            toast.error('Failed to fetch connected accounts');
            setState('connect');
        } finally {
            setIsFetching(false);
        }
    };

    const handleRemoveAccount = async (email) => {
        // Implement remove account logic
        console.log('Remove account:', email);
    };

    const handleAddAccount = async () => {
        try {
            setIsLoading(true);
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (!currentUser) {
                toast.error('Please login first');
                return;
            }

            const isGoogleUser = currentUser?.providerData?.some(
                provider => provider.providerId === 'google.com'
            );

            if (!isGoogleUser) {
                toast.error('Please login with Google to use this feature');
                return;
            }

            const sheetsResult = await authenticateGoogleSheets(auth);
            
            if (sheetsResult.success) {
                const userIdToken = await currentUser.getIdToken();

                const response = await fetch('/api/integrations/google-sheets/connect', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userIdToken}`
                    },
                    body: JSON.stringify({
                        sheetsAccessToken: sheetsResult.sheetsAccessToken,
                        sheetsEmail: currentUser.email,
                        refreshToken: sheetsResult.refreshToken
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to connect Google Sheets');
                }

                await fetchConnectedAccounts();
                toast.success('Connected to Google Sheets successfully');
            }
        } catch (error) {
            console.error('Error connecting Google Sheets:', error);
            
            // Handle specific error for popup closed
            if (error.code === 'auth/popup-closed-by-user') {
                toast.error('Connection cancelled. Please try again.');
            } else {
                toast.error(error.message || 'Failed to connect Google Sheets');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleAccountSelect = (email) => {
        setSelectedAccount(email);
        setState('setup');
    };

    const handleSetupNext = async (setupData) => {
        try {
            setIsLoading(true);
            const auth = getAuth();
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`/api/integrations/google-sheets/survey/${surveyId}/connect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    ...setupData,
                    integrationEmail: selectedAccount
                })
            });

            if (!response.ok) {
                throw new Error('Failed to connect sheet');
            }

            const data = await response.json();
            
            // Refresh connected accounts
            await fetchConnectedAccounts();
            
            toast.success('Google Sheet connected successfully');
            onSuccess?.(data);
            onClose();
        } catch (error) {
            console.error('Error connecting sheet:', error);
            toast.error(error.message || 'Failed to connect sheet');
        } finally {
            setIsLoading(false);
        }
    };

    const renderContent = () => {
        switch (state) {
            case 'connected':
                return (
                    <>
                        <div className="w-full flex flex-col items-center">
                            <p className="text-lg font-semibold text-app-black-800 mb-4 leading-tight">
                                Choose an account from the list below to<br />proceed, or connect a new one.
                            </p>

                            <div className="">
                                <p className="text-sm text-app-gray-600 mb-2">
                                    {connectedAccounts.length > 1
                                        ? 'continue with one of your accounts:'
                                        : 'continue with your account:'}
                                </p>
                                <div className="space-y-1 max-h-36 overflow-y-auto">
                                    {connectedAccounts.map((account) => (
                                        <div onClick={() => handleAccountSelect(account.email)}>
                                            <ConnectedAccount
                                                key={account.email}
                                                email={account.email}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-sm text-app-gray-600">
                                if you need to connect to another account
                            </p>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={handleAddAccount}
                                disabled={isLoading}
                                className="text-app-gray-600 px-3"
                            >
                                <Plus className="w-4 h-4 mr-2" />
                                Add account
                            </Button>
                        </div>
                    </>
                );
            case 'setup':
                return (
                    <GoogleSheetsSetup
                        onBack={() => setState('connected')}
                        onNext={handleSetupNext}
                    />
                );
            default:
                return (
                    <>
                        <p className="text-base text-app-gray-800">
                            It seems you dont have a google account<br />connected to pollbolt
                        </p>
                        <div className="flex flex-col items-center">
                            <p className="text-sm text-app-gray-600 mb-3">
                                continue with your account:
                            </p>
                            <Button
                                variant="outline"
                                size="md"
                                onClick={handleAddAccount}
                                disabled={isLoading}
                                className="bg-[#F8F8F8] hover:bg-gray-100 text-app-black-800 shadow-sm"
                            >
                                {isLoading ? (
                                    <span className="flex items-center">
                                        <span className="animate-spin mr-2">⏳</span>
                                        Connecting...
                                    </span>
                                ) : (
                                    <>
                                        <Plus className="w-4 h-4 mr-2" />
                                        Add account
                                    </>
                                )}
                            </Button>
                        </div>
                    </>
                );
        }
    };

    return (
        <ModalContainerFull
            isOpen={isOpen}
            onClose={handleClose}
            showClose={true}
            size="5xl"
            className=""
        >
            <div className="w-full h-full min-h-[38rem] max-h-[90vh] flex overflow-y-auto">
                <Loader loading={isLoading} />
                {/* Left Side */}
                <div className="w-1/2 flex items-center justify-center p-12">
                    <div className="flex flex-col gap-y-16 items-center text-center">
                        {/* Google Sheets Icon */}
                        {state !== 'setup' && (
                            <div className='flex flex-col justify-center items-center gap-y-4'>
                                <div className="w-16 h-16">
                                    <img
                                        src="/images/google-sheets.svg"
                                        alt="Google Sheets"
                                        className="w-full h-full"
                                    />
                                </div>

                                <h2 className="text-xl font-medium text-app-black-800">
                                    Connect your form to<br />Google Sheets
                                </h2>

                                {state === 'connected' && (
                                    <div className="flex items-center gap-2 px-3 py-2.5 bg-app-cyan-100 rounded-lg">
                                        <div className="w-5 h-5 text-app-black-800 p-1 bg-app-white rounded-full flex justify-center items-center">
                                            <Check size={16} />
                                        </div>
                                        <p className="text-sm font-semibold text-app-green-700">
                                            You're already connected to Google Sheets
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                        {renderContent()}
                    </div>
                </div>

                {/* Right Side - Decorative */}
                <IntegrationDecorativeSide 
                    bgColor="bg-app-green-100"
                    bottomIcon="/images/google-sheets.svg"
                    showGoogleIcon={true}
                />
            </div>
        </ModalContainerFull>
    );
};

export default GoogleIntegrationModal; 