import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import { createPortal } from "react-dom";

const SurveyRenameModal = ({ isOpen, onClose, onSubmit, surveyData = "" }) => {
  console.log("surveyData", surveyData);
  const [surveyTitle, setSurveyTitle] = useState(surveyData?.title || "");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (surveyTitle.trim()) {
      onSubmit(surveyData.surveyId, surveyTitle.trim());
      onClose();
    }
  };

  useEffect(() => {
    setSurveyTitle(surveyData?.title || "");
  }, [surveyData]);

  if (!isOpen) return null;

  return createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl p-6 w-full max-w-md relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X size={20} />
        </button>

        <h2 className="text-xl font-semibold mb-6 text-black">Rename</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              defaultValue={surveyData?.title}
              value={surveyTitle}
              onChange={(e) => {
                setSurveyTitle(e.target.value);
              }}
              className="text-black w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors"
              placeholder="Enter workspace name"
              autoFocus
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!surveyTitle.trim()}
              className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default SurveyRenameModal;
