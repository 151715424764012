import React, { useState } from "react";
import FirstProject from "./FirstProject";
import DescribeProject from "./DescribeProject";
import MakeItYour from "./MakeItYour";
import IntegrationProject from "./IntegrationProject";
import { v4 as uuidv4 } from "uuid";
import { createOnboardingSurvey, updateOnboarding } from "../../serverComm";
import { uploadLogo } from "../../utils/mediaUpload";
import {
  defaultAiGeneratedSurveyBackground,
  defaultAiGeneratedSurveyBackgroundColor,
} from "../../assets/defaults";

// step 1 - firstProject
// step 2 - describeProject
// step 3 - makeItYour
// step 4 - integrations

const OnboardingSurvey = ({ currentStep, setCurrentStep }) => {
  const [description, setDescription] = useState("");
  const [aiGeneratedQuestions, setAiGeneratedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyImage, setSurveyImage] = useState("");
  const handleSubmitFirstProject = () => {
    setCurrentStep(2);
  };

  const handleSubmitDescribeProject = (questions) => {
    setAiGeneratedQuestions(questions);
    setCurrentStep(4);
  };

  const handleSubmitMakeItYour = (image) => {
    setSurveyImage(image);
    setCurrentStep(5);
  };

  const handleSubmitIntegrations = async () => {
    setLoading(true);

    // preparing questions and styles
    const surveyQuestions = aiGeneratedQuestions.map((ques) => ({
      id: uuidv4(),
      ...ques,
    }));
    let logo = {};
    if (surveyImage) {
      logo = await uploadLogo(surveyImage);
    }

    const payload = {
      questions: surveyQuestions,
      styles: {
        logo: {
          url: logo?.url || "",
        },
        backgroundAlignment: "right",
        backgroundImage: defaultAiGeneratedSurveyBackground,
        backgroundColor: defaultAiGeneratedSurveyBackgroundColor,
      },
    };
    // save survey and redirect to survey page
    const saveResponse = await createOnboardingSurvey(payload);
    if (saveResponse) {
      // updating onboarding step and marking completed
      await updateOnboarding(2, true, saveResponse.surveyId);

      // intentionally reloading window to avoid issues with cached onboarding data.
      window.location.href = `/survey/${saveResponse.surveyId}?onboarding=completed`;
    }
    setLoading(false);
  };

  return (
    <>
      {currentStep === 1 && (
        <FirstProject
          onSubmit={handleSubmitFirstProject}
          setDescription={setDescription}
        />
      )}
      {(currentStep === 2 || currentStep === 3) && (
        <DescribeProject
          onSubmit={handleSubmitDescribeProject}
          description={description}
          setDescription={setDescription}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 4 && <MakeItYour onSubmit={handleSubmitMakeItYour} />}
      {currentStep === 5 && (
        <IntegrationProject
          onSubmit={handleSubmitIntegrations}
          loading={loading}
        />
      )}
    </>
  );
};

export default OnboardingSurvey;
