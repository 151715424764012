import React from "react";
import { capitalizeFirstCharacter } from "../utils/helper";
import Button from "./Button/Button";

const SubscriptionStatus = ({
  subscription,
  handleSubscribe,
  isAnnual,
  subscriptionPlans,
}) => {
  const getPlanIcon = (planName) => {
    switch (planName?.toLowerCase()) {
      case "growth":
        return (
          <img
            src={`images/pollBoltIconCyan.svg`}
            alt="Growth Plan"
            className="w-full h-full object-contain"
          />
        );
      case "pro":
        return (
          <img
            src={`images/pollBoltIconYellow.svg`}
            alt="Pro Plan"
            className="w-full h-full object-contain"
          />
        );
      default: // Free plan
        return (
          <img
            src={`images/pollBoltIconWhite.svg`}
            alt="Free Plan"
            className="w-full h-full object-contain"
          />
        );
    }
  };

  const getSubscriptionEndText = () => {
    if (!subscription?.currentPeriodEnd) return null;

    const endDate = new Date(subscription.currentPeriodEnd);
    return `Current period ends on ${endDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })}`;
  };

  const handleUpgrade = () => {
    const nextPlan = subscriptionPlans.find(
      (plan) => plan?.name?.toLowerCase() === "growth"
    );
    if (nextPlan) {
      handleSubscribe(
        nextPlan.id,
        isAnnual
          ? nextPlan.pricingOptions.annual
          : nextPlan.pricingOptions.monthly
      );
    }
  };

  return (
    <div className="flex items-center gap-3">
      <div className="w-20">{getPlanIcon(subscription?.planName)}</div>
      <div className="flex items-start space-x-4">
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-bold text-black">
              {capitalizeFirstCharacter(subscription?.planName)} Plan
            </h3>
          </div>
          <p className="text-app-gray-600 text-sm font-medium mt-1">
            You are currently on the{" "}
            {capitalizeFirstCharacter(subscription?.planName)} plan
            {capitalizeFirstCharacter(subscription?.planName) === "Free"
              ? " with basic features"
              : ""}
            .
          </p>
          {subscription?.planName?.toLowerCase() !== "free" && (
            <p className="text-app-gray-400 text-xs mt-1">
              {getSubscriptionEndText()}
            </p>
          )}
        </div>
      </div>

      {subscription?.planName?.toLowerCase() === "pro" && (
        <div className="ml-auto mr-10">
          <Button variant="default" size="md" onClick={handleUpgrade}>
            Upgrade
          </Button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionStatus;
