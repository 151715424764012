import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../components/Button/Button';
import { cn } from '../utils/helper';
import { getAuth } from 'firebase/auth';
import { Link, Trash2 } from 'lucide-react';
import GoogleIntegrationModal from '../components/integrations/GoogleIntegrationModal';
import GoogleAnalyticsModal from '../components/integrations/GoogleAnalyticsModal';
import MetaPixelModal from '../components/integrations/MetaPixelModal';
import { toast } from 'react-hot-toast';
import { Loader } from '../components/Loader';

// Updated integrations data structure
const INTEGRATIONS = [
  {
    name: 'Google analytics',
    description: 'Track survey performance and user insights.',
    logo: '/images/google-analytics.svg',
    status: 'available'
  },
  {
    name: 'Meta Pixel',
    description: 'Measure conversions and retarget survey participants.',
    logo: '/images/meta.svg',
    status: 'available'
  },
  {
    name: 'Google Sheets',
    description: 'Export survey responses directly to Sheets',
    logo: '/images/google-sheets.svg',
    status: 'available'
  },
  {
    name: 'Webhooks',
    description: 'Send real-time survey data to external platforms',
    logo: '/images/webhooks.svg',
    status: 'available'
  },
  {
    name: 'Zapier',
    description: 'Automate workflows by connecting thousands of apps',
    logo: '/images/zapier.svg',
    status: 'coming_soon'
  },
  {
    name: 'Mailchimp',
    description: 'Add survey respondents to your email lists',
    logo: '/images/mailchimp.svg',
    status: 'coming_soon'
  },
  {
    name: 'Monday.com',
    description: 'Sync survey data and optimize team workflows',
    logo: '/images/monday.svg',
    status: 'coming_soon'
  },
  {
    name: 'Klaviyo',
    description: 'Add respondents to targeted email campaigns',
    logo: '/images/klaviyo.svg',
    status: 'coming_soon'
  },
  {
    name: 'Slack',
    description: 'Get instant notifications for new survey responses',
    logo: '/images/slack.svg',
    status: 'coming_soon'
  },
  {
    name: 'Airtable',
    description: 'Store, organize, and analyze survey responses',
    logo: '/images/airtable.svg',
    status: 'coming_soon'
  }
];

const SurveyIntegrate = () => {
  const { surveyId } = useParams();
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);
  const [showMetaModal, setShowMetaModal] = useState(false);
  const [integrations, setIntegrations] = useState(INTEGRATIONS);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchIntegrationStatuses();
  }, [surveyId]);

  const fetchIntegrationStatuses = async () => {
    try {
      setIsLoading(true);
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      // Use the new endpoint path
      const response = await fetch(`/api/integrations/survey/${surveyId}/status`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch integration statuses');
      }

      const data = await response.json();

      // Update integrations state with fetched statuses
      setIntegrations(prev => prev.map(integration => {
        if (integration.name === 'Google Sheets') {
          return {
            ...integration,
            status: data.googleSheets?.enabled ? 'connected' : 'available'
          };
        }
        if (integration.name === 'Google analytics') {
          return {
            ...integration,
            status: data.googleAnalytics?.enabled ? 'connected' : 'available'
          };
        }
        if (integration.name === 'Meta Pixel') {
          return {
            ...integration,
            status: data.metaPixel?.enabled ? 'connected' : 'available'
          };
        }
        return integration;
      }));

    } catch (error) {
      console.error('Error fetching integration statuses:', error);
      setIntegrations(prev => prev.map(integration => ({
        ...integration,
        status: integration.status === 'coming_soon' ? 'coming_soon' : 'available'
      })));
    } finally {
      setIsLoading(false);
    }
  };

  const handleIntegrationSuccess = async () => {
    // Refresh integration statuses after successful connection
    await fetchIntegrationStatuses();
  };

  const handleIntegrationClick = (integration) => {
    // Don't open modal if already connected
    if (integration.status === 'connected') {
      toast.success(`Already connected to ${integration.name}. Delete the connection to reconnect.`);
      return;
    }

    // Only allow opening modals for available integrations
    if (integration.status === 'available') {
      if (integration.name === 'Google Sheets') {
        setShowGoogleModal(true);
      } else if (integration.name === 'Google analytics') {
        setShowAnalyticsModal(true);
      } else if (integration.name === 'Meta Pixel') {
        setShowMetaModal(true);
      }
    }
  };

  const handleDeleteIntegration = async (integration) => {
    try {
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();
      setIsLoading(true); // Show loading state

      let endpoint = '';
      if (integration.name === 'Google Sheets') {
        endpoint = `/api/integrations/google-sheets/survey/${surveyId}/disconnect`;
      } else if (integration.name === 'Google analytics') {
        endpoint = `/api/integrations/google-analytics/survey/${surveyId}/disconnect`;
      } else if (integration.name === 'Meta Pixel') {
        endpoint = `/api/integrations/meta-pixel/survey/${surveyId}/disconnect`;
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to disconnect integration');
      }

      toast.success(`Disconnected ${integration.name} successfully`);
      await fetchIntegrationStatuses(); // Refresh the statuses
    } catch (error) {
      console.error('Error disconnecting integration:', error);
      toast.error(error.message || 'Failed to disconnect integration');
    } finally {
      setIsLoading(false); // Hide loading state
    }
  };

  return (
    <>
      <div className='bg-app-gray-50'>
        <div className="max-w-6xl mx-auto pt-20 px-6 py-8 animate-fadeIn">
          {/* Header */}
          <div className="mb-12">
            <h1 className="text-3xl font-semibold text-app-black mb-2">Integrations Hub</h1>
            <p className="text-sm text-app-gray-500">Connect PollBolt with powerful tools</p>
          </div>

          <Loader loading={isLoading} />
          {/* Integrations Grid */}
          <div className="grid grid-cols-2 gap-6">
            {
              integrations.map((integration) => (
                <div
                  key={integration.name}
                  className={cn(
                    "flex items-center justify-between gap-x-4 py-10 px-8 bg-white rounded-xl border",
                    integration.status === 'coming_soon'
                      ? "border-app-gray-100"
                      : "border-app-gray-200"
                  )}
                >
                  {/* Left side - Logo and Info */}
                  <div className="flex items-center gap-4">
                    <div className={cn(
                      "p-4 flex-shrink-0 border rounded-md",
                      integration.status === 'coming_soon'
                        ? "border-app-gray-100"
                        : "border-app-gray-200"
                    )}>
                      <div className="w-10 h-10 flex items-center justify-center">
                        <img
                          src={integration.logo}
                          alt={integration.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-app-black mb-1">
                          {integration.name}
                        </h3>
                      <p className="text-sm text-app-gray-500">
                        {integration.description}
                      </p>
                    </div>
                  </div>

                  {/* Right side - Updated Button section */}
                  <div className="flex items-center gap-2">
                    {integration.status === 'connected' ? (
                      <>
                        <Button
                          variant="connected"
                          size="sm"
                          onClick={() => handleIntegrationClick(integration)}
                          startIcon={<Link className="w-4 h-4" />}
                          className="cursor-not-allowed"
                        >
                          Connected
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleDeleteIntegration(integration)}
                          className="p-2 text-app-gray-500 hover:text-red-500 hover:border-red-500"
                        >
                          <Trash2 className="w-4 h-4" />
                        </Button>
                      </>
                    ) : integration.status === 'available' ? (
                      <Button
                        variant="integrate"
                        size="sm"
                        onClick={() => handleIntegrationClick(integration)}
                      >
                        Connect
                      </Button>
                    ) : (
                      <span className="flex-shrink-0 text-xs py-2 px-4 font-medium text-app-gray-500 bg-app-gray-300 rounded-md">
                        Coming soon
                      </span>
                    )}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      {/* Google Integration Modal */}
      <GoogleIntegrationModal
        isOpen={showGoogleModal}
        onClose={() => setShowGoogleModal(false)}
        surveyId={surveyId}
        onSuccess={handleIntegrationSuccess}
      />

      {/* Google Analytics Modal */}
      <GoogleAnalyticsModal
        isOpen={showAnalyticsModal}
        onClose={() => {
          setShowAnalyticsModal(false);
          fetchIntegrationStatuses(); // Refresh statuses on close
        }}
        surveyId={surveyId}
      />

      {/* Meta Pixel Modal */}
      <MetaPixelModal
        isOpen={showMetaModal}
        onClose={() => {
          setShowMetaModal(false);
          fetchIntegrationStatuses(); // Refresh statuses on close
        }}
        surveyId={surveyId}
      />
    </>
  );
};

export default SurveyIntegrate; 