import React, { useState } from 'react';
import Button from '../Button/Button';
import { ArrowRight } from 'lucide-react';
import { Loader } from '../Loader';

const SheetSetupForm = ({ type, onBack, onNext }) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validateSheetUrl = (url) => {
        // Check if it's a valid Google Sheets URL
        const isValidUrl = url.match(/https:\/\/docs\.google\.com\/spreadsheets\/d\/[-\w]{25,}/);
        if (!isValidUrl) {
            setError('Please enter a valid Google Sheets URL');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async () => {
        try {
            if (type === 'existing' && !validateSheetUrl(value)) {
                return;
            }
            setIsLoading(true);
            await onNext(value);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const config = {
        create: {
            title: 'Name the new spreadsheet',
            placeholder: 'E.g. New customer leads',
            buttonText: 'Create Sheet'
        },
        existing: {
            title: "Paste your spreadsheet's\nURL here:",
            placeholder: 'https://docs.google.com/spreadsheets/d/...',
            buttonText: 'Connect to Spreadsheet'
        }
    }[type];

    const isValidInput = type === 'create' 
        ? value.trim().length > 0 
        : value.trim().length > 0 && !error;

    return (
        <div className="flex flex-col items-center w-full">
            <Loader loading={isLoading} />
            
            <h2 className="text-xl font-medium text-app-black-800 mb-8 whitespace-pre-line">
                {config.title}
            </h2>

            <div className="w-full max-w-md mb-8">
                <input
                    type="text"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if (type === 'existing') {
                            setError('');
                        }
                    }}
                    placeholder={config.placeholder}
                    className={`w-full p-4 rounded-lg border 
                    text-sm text-app-black placeholder:text-app-gray-400
                    focus:outline-none focus:border-app-black transition-colors
                    ${error ? 'border-red-500' : 'border-app-gray-200'}`}
                />
                {error && (
                    <p className="mt-2 text-sm text-red-500">{error}</p>
                )}
                {type === 'existing' && !error && (
                    <p className="mt-2 text-sm text-app-gray-500">
                        Make sure you have editor access to this sheet
                    </p>
                )}
            </div>

            <div className="flex justify-center w-full gap-4">
                <Button
                    variant="outline"
                    size="md"
                    onClick={onBack}
                    disabled={isLoading}
                    className="px-8 text-app-gray-600 hover:bg-app-gray-50"
                >
                    Back
                </Button>
                <Button
                    variant="outline"
                    size="md"
                    onClick={handleSubmit}
                    disabled={!isValidInput || isLoading}
                    className="px-8 bg-app-black text-white hover:bg-app-black/90"
                >
                    {isLoading ? 'Connecting...' : config.buttonText}
                </Button>
            </div>
        </div>
    );
};

const GoogleSheetsSetup = ({ onBack, onNext }) => {
    const [selectedOption, setSelectedOption] = useState('A');
    const [step, setStep] = useState('select');
    const [isLoading, setIsLoading] = useState(false);

    const handleNext = (option) => {
        if (option === 'A') {
            setStep('create');
        } else {
            setStep('existing');
        }
    };

    const handleFormSubmit = async (value) => {
        try {
            setIsLoading(true);
            if (step === 'create') {
                await onNext({
                    type: 'create',
                    sheetName: value
                });
            } else {
                await onNext({
                    type: 'existing',
                    sheetUrl: value
                });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (step === 'create' || step === 'existing') {
        return (
            <SheetSetupForm
                type={step}
                onBack={() => setStep('select')}
                onNext={handleFormSubmit}
            />
        );
    }

    const options = [
        { id: 'A', label: 'Create a new sheet' },
        { id: 'B', label: 'Use existing' }
    ];

    return (
        <div className="flex flex-col items-center w-full">
            <h2 className="text-xl font-medium text-app-black-800 mb-8">
                Where do you want to send<br />your responses?
            </h2>

            <div className="w-full max-w-md space-y-3 mb-8">
                {options.map((option) => (
                    <button
                        key={option.id}
                        onClick={() => setSelectedOption(option.id)}
                        className={`w-full p-4 text-left rounded-lg border transition-all ${
                            selectedOption === option.id
                                ? 'border-app-gray-200'
                                : 'border-app-gray-200 hover:border-app-gray-300'
                        }`}
                    >
                        <div className="flex items-center gap-3">
                            <span className={`flex justify-center items-center border border-app-gray-200 rounded-md p-2 text-sm font-medium w-8 h-8 ${
                                selectedOption === option.id
                                    ? 'text-app-black bg-app-cyan-100'
                                    : 'text-app-gray-600'
                            }`}>
                                {option.id}
                            </span>
                            <span className={`text-sm font-medium ${
                                selectedOption === option.id
                                    ? 'text-app-black'
                                    : 'text-app-gray-600'
                            }`}>
                                {option.label}
                            </span>
                        </div>
                    </button>
                ))}
            </div>

            <div className="flex justify-center w-full">
                <Button
                    variant="outline"
                    size="md"
                    onClick={() => handleNext(selectedOption)}
                    disabled={!selectedOption}
                    className="px-8 bg-app-black text-white hover:bg-app-black/90 flex items-center gap-2"
                >
                    <span>Next</span>
                    <ArrowRight size={16} />
                </Button>
            </div>
        </div>
    );
};

export default GoogleSheetsSetup; 