import { useEffect } from 'react';

// Standard Meta Pixel events
const STANDARD_META_EVENTS = [
    'PageView',
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent'
];

export const useAnalytics = (survey) => {
    useEffect(() => {
        if (!survey) return;

        // Initialize Google Analytics if enabled
        if (survey.analytics?.googleAnalytics?.enabled && survey.analytics.googleAnalytics.trackingId) {
            const gaScript = document.createElement('script');
            gaScript.async = true;
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${survey.analytics.googleAnalytics.trackingId}`;
            document.head.appendChild(gaScript);

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', survey.analytics.googleAnalytics.trackingId);
        }

        // Initialize Meta Pixel if enabled
        if (survey.analytics?.metaPixel?.enabled && survey.analytics.metaPixel.pixelId) {
            // Create fbq function if it doesn't exist
            window.fbq = window.fbq || function() {
                window.fbq.callMethod ? 
                window.fbq.callMethod.apply(window.fbq, arguments) : 
                window.fbq.queue.push(arguments)
            };
            window.fbq.push = window.fbq;
            window.fbq.loaded = true;
            window.fbq.version = '2.0';
            window.fbq.queue = [];
            
            // Load Meta Pixel script
            const fbScript = document.createElement('script');
            fbScript.async = true;
            fbScript.src = 'https://connect.facebook.net/en_US/fbevents.js';
            document.head.appendChild(fbScript);
            
            window.fbq('init', survey.analytics.metaPixel.pixelId);
            window.fbq('track', 'PageView');
        }

        // Cleanup function
        return () => {
            // Remove Google Analytics
            const gaScript = document.querySelector(`script[src*="googletagmanager.com/gtag/js"]`);
            if (gaScript) {
                gaScript.remove();
            }
            // Safely remove GA properties
            try {
                window.dataLayer = undefined;
                window.gtag = function() {};  // Replace with no-op function
            } catch (e) {
                console.warn('Could not clean up Google Analytics completely', e);
            }

            // Remove Meta Pixel
            const fbScript = document.querySelector(`script[src*="connect.facebook.net/en_US/fbevents.js"]`);
            if (fbScript) {
                fbScript.remove();
            }
            // Safely remove FB properties
            try {
                window.fbq = function() {};  // Replace with no-op function
            } catch (e) {
                console.warn('Could not clean up Meta Pixel completely', e);
            }
        };
    }, [survey]);

    // Helper functions to track events
    const trackEvent = (eventName, params = {}) => {
        // Track in Google Analytics
        if (window.gtag && survey?.analytics?.googleAnalytics?.enabled) {
            window.gtag('event', eventName, params);
        }

        // Track in Meta Pixel
        if (window.fbq && survey?.analytics?.metaPixel?.enabled) {
            // Use trackCustom for non-standard events
            if (STANDARD_META_EVENTS.includes(eventName)) {
                window.fbq('track', eventName, params);
            } else {
                // Convert to PascalCase for Meta Pixel custom events
                const formattedEventName = eventName
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join('');
                window.fbq('trackCustom', formattedEventName, params);
            }
        }
    };

    return { trackEvent };
}; 