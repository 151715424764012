import { Loader as LucideLoader } from "lucide-react";

export function Loader({ loading }) {
    if (!loading) return <></>;
    return (
        <div className=" fixed inset-0 bg-white/70 z-50" >
            <div className="flex items-center justify-center w-full h-full">
                <LucideLoader className="w-12 h-12 animate-spin" />
            </div>
        </div>
    );

}