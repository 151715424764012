import React from "react";
const InvalidForm = ({ setShowInvalidForm }) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
      <div className="bg-white rounded-3xl max-w-md w-full p-8">
        <div className="text-center space-y-4">
          <h2 className="text-[28px] font-semibold">Invalid Survey Form</h2>

          <div className="space-y-4 text-gray-600">
            <p>This Form is invalid. Survey Owner reached its plan limit</p>
            <p>Kindly contact the Survey Owner</p>
          </div>

          <div className="flex gap-3 pt-2">
            <button
              className="flex-1 px-4 py-3 border border-gray-200 rounded-full hover:bg-gray-50 font-medium"
              onClick={() => setShowInvalidForm(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidForm;
