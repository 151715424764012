import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Specific scopes for Google Sheets
const SHEETS_SCOPES = [
  'https://www.googleapis.com/auth/spreadsheets',        // Read/write sheets
  'https://www.googleapis.com/auth/drive.file'          // Access to files created by the app
];

export const authenticateGoogleSheets = async (auth) => {
  try {
    const currentUser = auth.currentUser;
    
    // Check if user is logged in with Google
    const isGoogleUser = currentUser?.providerData?.some(
      provider => provider.providerId === 'google.com'
    );

    if (!isGoogleUser) {
      throw new Error('Please login with Google to use this feature');
    }

    const provider = new GoogleAuthProvider();
    
    // Add Sheets scopes
    provider.addScope('https://www.googleapis.com/auth/spreadsheets');
    provider.addScope('https://www.googleapis.com/auth/drive.file');
    
    // Use same account, don't show picker
    provider.setCustomParameters({
      login_hint: currentUser.email, // Force same Google account
      prompt: 'consent'
    });

    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    
    return {
      success: true,
      sheetsAccessToken: credential.accessToken,
      sheetsEmail: currentUser.email, // Use logged in user's email
      refreshToken: credential.refreshToken
    };
  } catch (error) {
    console.error('Google Sheets Authentication Error:', error);
    return {
      success: false,
      error: error.message
    };
  }
}; 