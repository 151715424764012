import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import OnboardingWelcome from "../components/OnboardingSurveyComponents/OnboardingWelcome";
import OnboardingSurvey from "../components/OnboardingSurveyComponents/OnboardingSurvey";

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(
    parseInt(searchParams.get("step") || "0")
  );
  console.log("currentStep", currentStep);
  const maxSteps = 5;
  const progress = Math.min(100, (currentStep / maxSteps) * 100);

  return (
    <div className="min-h-screen">
      <div
        className="absolute top-0 left-0 w-full bg-app-gray-100 h-2"
        style={{ zIndex: 101 }}
      >
        <div
          className="bg-app-yellow-200 h-2 transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>
      {currentStep === 0 && <OnboardingWelcome setCurrentStep={setCurrentStep} />}
      {[1, 2, 3, 4, 5].includes(currentStep) && (
        <OnboardingSurvey
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
};

export default Onboarding;
