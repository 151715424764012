import React, { useState } from "react";
import SelectElementForm from "./SelectElementForm";
import ImportQuestionForm from "./ImportQuestionForm";
import GenerateWithAiForm from "./GenerateWithAiForm";
import { Dialog } from "@headlessui/react";
import { X } from "lucide-react";

const FormSelectorDialog = ({
  onClose,
  isShown,
  preSelectedTab,
  handleAddQuestion,
  appendAiGeneratedQuestions,
  generateTitleWithAi,
}) => {
  const [activeTab, setActiveTab] = useState(preSelectedTab || "select");
  return (
    <Dialog as="div" className="relative z-50" open={isShown} onClose={onClose}>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div
          id="main-form-container"
          className="bg-[#F9FAFB] rounded-2xl  w-full max-w-2xl max-h-[95vh] mx-4"
        >
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <div className="space-x-6 flex-1 flex justify-center items-center">
              <button
                className={`px-2 pt-4 text-center relative ${
                  activeTab === "select"
                    ? "text-black border-t-4 border-black"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("select")}
              >
                Select Elements
              </button>

              <button
                className={`px-2 pt-4 text-center relative ${
                  activeTab === "import"
                    ? "text-black border-t-4 border-black"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("import")}
              >
                Import Questions
              </button>
              <button
                className={`px-2 pt-4 text-center relative ${
                  activeTab === "ai"
                    ? "text-black border-t-4 border-black"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("ai")}
              >
                Create With AI
              </button>
            </div>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 p-2 pr-4"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          {activeTab === "select" && (
            <SelectElementForm handleAddQuestion={handleAddQuestion} />
          )}
          {activeTab === "import" && (
            <ImportQuestionForm
              appendAiGeneratedQuestions={appendAiGeneratedQuestions}
            />
          )}
          {activeTab === "ai" && (
            <GenerateWithAiForm
              appendAiGeneratedQuestions={appendAiGeneratedQuestions}
              generateTitleWithAi={generateTitleWithAi}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default FormSelectorDialog;
